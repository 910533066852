import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { NotFound } from "../../ui/organisms/notFound";
import { findCommercials, findTeamLeaders, findUsersAvatarsList, setCreateFromProject } from "../users/slice";
import { ProjectsFilters } from "./filters";
import { ProjectsList } from "./list";
import { findAllProjects, setDeleteProjectStatus, setProjectFilterPage, setSelectedProject } from "./slice";

export function Projects() {
    const dispatch = useAppDispatch()
    const navigation = useNavigate()

    const projectsState = useAppSelector(state => state.projects)
    const usersState = useAppSelector(state => state.users)
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        dispatch(findAllProjects(projectsState.filters))
        dispatch(findTeamLeaders())
        dispatch(findCommercials())
        // dispatch(setFindUserByIdResponse(undefined))
        // dispatch(setValidateNewUserStatus('idle'))
        // dispatch(setValidateUpdateUserStatus('idle'))
        setIsFirstLoad(false);
    }, [])

    useEffect(() => {
        if (usersState.findTeamLeadersStatus === 'successfully' && usersState.findCommercialsStatus === 'successfully') {
            const teamLeaderAvatars = usersState.findTeamLeadersResponse?.data.map(teamLeader => ({ userId: teamLeader.id, objectId: teamLeader.avatarObjectId }))
            const commercialAvatars = usersState.findCommercialsResponse?.data.map(commercial => ({ userId: commercial.id, objectId: commercial.avatarObjectId }))

            dispatch(findUsersAvatarsList((teamLeaderAvatars || []).concat(commercialAvatars || [])))
        }
    }, [usersState.findTeamLeadersStatus, usersState.findCommercialsStatus])

    useEffect(() => {
        if (!isFirstLoad) {
            dispatch(setProjectFilterPage(0));
            dispatch(findAllProjects({ ...projectsState.filters, page: 0 }));
        }
    }, [projectsState.filters.order, projectsState.filters.sort, projectsState.filters.name, projectsState.filters.sector, projectsState.filters.category, projectsState.filters.state, projectsState.filters.teamLeaderId, projectsState.filters.commercial])

    useEffect(() => {
        if (!isFirstLoad)
            dispatch(findAllProjects(projectsState.filters))
    }, [projectsState.filters.page])

    useEffect(() => {
        if (projectsState.selectedProject !== undefined) {
            navigation('/edit-project/' + projectsState.selectedProject + '/specifics')
            dispatch(setSelectedProject(undefined))
        }
    }, [projectsState.selectedProject])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (projectsState.deleteProjectStatus === 'failed' || projectsState.deleteProjectStatus === 'successfully') {
            if (projectsState.deleteProjectStatus === 'successfully') {
                dispatch(findAllProjects(projectsState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteProjectStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [projectsState.deleteProjectStatus]);

    return (
        <Layout
            menuItem={MenuItems.PROJECTS}
            breadcrumbItems={['Progetti']}
            headerLabel="Progetti"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end">
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        label="Nuovo progetto"
                        variant={"solid"}
                        color={"blue"}
                        onClick={() => {
                            dispatch(setCreateFromProject(undefined))
                            navigation('/new-project')
                        }}
                    />
                </div>
            }
        >
            <div className="p-6 h-full max-h-full">
                <div className="absolute flex flex-col gap-4 right-6">
                    <Banner
                        label={"Progetto eliminato."}
                        visible={projectsState.deleteProjectStatus === 'successfully'}
                        closeAction={function (): void {
                            dispatch(setDeleteProjectStatus('idle'))
                        }}
                        type={"success"}
                    />
                    <Banner
                        label={"Si è verificato un errore durante l'eliminazione del progetto."}
                        visible={projectsState.deleteProjectStatus === 'failed'}
                        closeAction={function (): void {
                            dispatch(setDeleteProjectStatus('idle'))
                        }}
                        type={"error"}
                    />
                </div>
                <div className="flex h-full flex-col bg-white rounded-2xl shadow-sm">
                    <div className="p-4">
                        <span className="text-heading-xs font-semibold text-neutral-800">Lista progetti</span>
                    </div>
                    <ProjectsFilters />
                    {
                        projectsState.findAllStatus === 'loading' ||
                            usersState.findTeamLeadersStatus === 'loading' ||
                            usersState.findCollaboratorsStatus === 'loading' ?
                            <div className="w-full h-[50%] flex items-center justify-center">
                                <Spinner />
                            </div> :
                            projectsState.findAllResponse?.data.length === 0 || projectsState.findAllResponse?.data === null ?
                                <div style={{ height: 'calc(100% - 64px - 60px)' }}>
                                    <NotFound />
                                </div> :
                                <ProjectsList />
                    }
                </div>
            </div>
        </Layout>
    )
}