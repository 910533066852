import { formatDate } from "date-fns";
import { ReactElement } from "react";
import { useAppDispatch } from "../../../../lib/redux/hooks";
import { buildAddress } from "../../../database/companies/cell";
import { CompanyDTO } from "../../../database/companies/dto";
import { findEmployees, setSelectedCompany, setSelectedCompanyNameForEmployees, setTabValueDetailsCompany } from "../../../database/companies/slice";
import { contactsStatePillMap, TargetListContactInfo } from "../../dto";
import Pills from "../../../../ui/molecules/pills";
import { GlobeIcon } from "../../../../ui/icons/globe";
import { colors } from "../../../../ui/colors";

interface Props {
  company: CompanyDTO;
  column: string;
  contactInfo: TargetListContactInfo
}
export function CompanyColumnMap(props: Props) {
  const dispatch = useAppDispatch();

  const ColumnMap = new Map<string, ReactElement>([
    [
      "Nome azienda",
      <td
        onClick={() => {
          dispatch(setTabValueDetailsCompany(0))
          dispatch(setSelectedCompany(props.company.id))
          dispatch(findEmployees(props.company.employeesId));
          dispatch(
            setSelectedCompanyNameForEmployees(props.company.name)
          );
        }}
        className="cursor-pointer max-w-[150px] text-text-sm crop-text font-semibold text-brandPrimary-600"
      >
        {props.company.name}
      </td>,
    ],
    [
      "Data di contatto",
      <td className="max-w-[150px] text-text-sm crop-text text-neutral-600">
        {props.contactInfo && props.contactInfo.lastContactDate ? formatDate(new Date(props.contactInfo.lastContactDate!), 'dd/MM/yyyy hh:mm') : "-"}
      </td>,
    ],
    [
      "Fatturato",
      <td className="max-w-[100px] text-text-sm crop-text text-neutral-600">
        {props.company.revenue ?? "-"}
      </td>,
    ],
    [
      "Telefono",
      <td>
        {props.company.phone ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"tel:" + props.company.phone[0]}
          >
            <div className="text-text-sm font-semibold text-neutral-600 max-w-[100px] crop-text">
              {props.company.phone[0] + (props.company.phone.length > 1 ? (', ..., + ' + (props.company.phone.length - 1)) : '')}
            </div>
          </a>
        ) : (
          "-"
        )}
      </td>,
    ],
    ['Esito',
      <td className="">
        {
          props.contactInfo ?
            <Pills
              size={"sm"}
              label={contactsStatePillMap.get(props.contactInfo.contactState)?.label!}
              outline={false}
              emphasis={false}
              color={contactsStatePillMap.get(props.contactInfo.contactState)?.color!}
            /> : '-'
        }
      </td>
    ],
    [
      "Note",
      <td className="max-w-[100px] text-text-sm crop-text text-neutral-600">
        {props.contactInfo && props.contactInfo.notes ? props.contactInfo.notes.length : "-"}
      </td>,
    ],
    [
      "Email",
      <td>
        {props.company.email ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"mailto:" + props.company.email}
          >
            <div className="text-text-sm font-semibold text-neutral-600 max-w-[200px] crop-text">
              {props.company.email}
            </div>
          </a>
        ) : (
          "-"
        )}
      </td>
    ],
    [
      "Dipendenti",
      <td className="px-[4px] min-w-[100px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.employeesNumber ?? "-"}
      </td>,
    ],
    [
      "P. IVA",
      <td className="px-[4px] min-w-[100px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.vat ?? "-"}
      </td>,
    ],
    [
      "Sede azienda",
      <td className="max-w-[200px] crop-text text-text-sm font-semibold text-neutral-600">
        {buildAddress(props.company.address, props.company.city, props.company.province, props.company.countryRegion, props.company.state, props.company.zipCode)}
      </td>,
    ],
    [
      "Sito web",
      <td>
        <a
          rel="noreferrer"
          target="_blank"
          href={props.company.website ?? undefined}
          className="flex items-center justify-center"
        >
          <GlobeIcon
            color={
              props.company.website
                ? colors.neutral[400]
                : colors.neutral[200]
            }
            size={20}
          />
        </a>
      </td>
    ],
    [
      "Cod. ATECO",
      <td className="px-[4px] min-w-[100px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.atecos ? props.company.atecos[0]?.atecoCode : "-"}
      </td>,
    ],
    [
      "Settore",
      <td className="px-[4px] min-w-[150px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.atecos ? props.company.atecos[0]?.sector : "-"}
      </td>,
    ],
    [
      "CAP",
      <td className="px-[4px] min-w-[100px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.zipCode ?? "-"}
      </td>,
    ],
    [
      "Categoria",
      <td className="px-[4px] min-w-[150px] crop-text text-text-md font-semibold text-neutral-600">
        {props.company.atecos ? props.company.atecos[0]?.category : "-"}
      </td>,
    ],
  ]);

  return <>{ColumnMap.get(props.column)}</>;
}
