import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { roleDepartment } from "../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { NewParticularity } from "../../particularities/newParticularity";
import { setShowNewParticularityModal } from "../../particularities/slice";
import {
  setEditTargetListRequestAtecos,
  setEditTargetListRequestCommercials,
  setEditTargetListRequestDepartments,
  setEditTargetListRequestEmployeesNumber,
  setEditTargetListRequestName,
  setEditTargetListRequestParticularity,
  setEditTargetListRequestRevenue,
  setEditTargetListRequestRoles,
  setFindTargetListStatus,
} from "../../targetList/slice";
import SelectParticularities from "./selectParticularities";
import { AtecosSegment } from "./atecos copy";

export function Form() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListState = useAppSelector((state) => state.targetList);
  const preferencesState = useAppSelector((state) => state.preferences);
  const dispatch = useAppDispatch();

  const [signRevenue, setSignRevenue] = useState<
    "=" | "<" | ">" | "><" | undefined
  >(undefined);
  const [firstInputRevenue, setFirstInputRevenue] = useState<
    number | undefined
  >(undefined);
  const [secondInputRevenue, setSecondInputRevenue] = useState<
    number | undefined
  >(undefined);

  const [signEmployeesNumber, setSignEmployeesNumber] = useState<
    "=" | "<" | ">" | "><" | undefined
  >(undefined);
  const [firstInputEmployeesNumber, setFirstInputEmployeesNumber] = useState<
    number | undefined
  >(undefined);
  const [secondInputEmployeesNumber, setSecondInputEmployeesNumber] = useState<
    number | undefined
  >(undefined);

  const signMap = new Map<("=" | "<" | ">" | "><"), string>([
    ["=", "Uguale a"],
    ["<", "Minore di"],
    [">", "Maggiore di"],
    ["><", "Compreso tra"],
  ]);

  useEffect(() => {
    if (signRevenue === undefined) {
      setFirstInputRevenue(undefined);
      setSecondInputRevenue(undefined);
    } else if (signRevenue !== "><") setSecondInputRevenue(undefined);
    dispatch(
      setEditTargetListRequestRevenue(
        signRevenue + ":" + firstInputRevenue + ":" + secondInputRevenue
      )
    );
  }, [signRevenue, firstInputRevenue, secondInputRevenue]);

  useEffect(() => {
    if (signEmployeesNumber === undefined) {
      setFirstInputEmployeesNumber(undefined);
      setSecondInputEmployeesNumber(undefined);
    } else if (signEmployeesNumber !== "><")
      setSecondInputEmployeesNumber(undefined);

    dispatch(
      setEditTargetListRequestEmployeesNumber(
        signEmployeesNumber +
        ":" +
        firstInputEmployeesNumber +
        ":" +
        secondInputEmployeesNumber
      )
    );
  }, [
    signEmployeesNumber,
    firstInputEmployeesNumber,
    secondInputEmployeesNumber,
  ]);

  const disabled = !(!targetListState.findTargetListResponse?.companyContacts && !targetListState.findTargetListResponse?.personContacts)

  useEffect(() => {
    if (targetListState.findTargetListStatus === 'successfully' && targetListState.findTargetListResponse !== undefined) {
      dispatch(setEditTargetListRequestName(targetListState.findTargetListResponse.name))
      dispatch(setEditTargetListRequestDepartments(targetListState.findTargetListResponse.departments))
      dispatch(setEditTargetListRequestRoles(targetListState.findTargetListResponse.roles))
      dispatch(setEditTargetListRequestAtecos(targetListState.findTargetListResponse.atecos))
      dispatch(setEditTargetListRequestCommercials(targetListState.findTargetListResponse.commercials.map(commercial => commercial.id)))
      dispatch(setEditTargetListRequestParticularity(targetListState.findTargetListResponse.particularity?.id))
      dispatch(setEditTargetListRequestRevenue(targetListState.findTargetListResponse.revenue))
      setSignRevenue(targetListState.findTargetListResponse.revenue.split(':')[0] as "=" | "<" | ">" | "><" | undefined)
      setFirstInputRevenue(Number(targetListState.findTargetListResponse.revenue.split(':')[1]))
      setSecondInputRevenue(Number(targetListState.findTargetListResponse.revenue.split(':')[2]))
      dispatch(setEditTargetListRequestEmployeesNumber(targetListState.findTargetListResponse.employeesNumber))
      setSignEmployeesNumber(targetListState.findTargetListResponse.employeesNumber.split(':')[0] as "=" | "<" | ">" | "><" | undefined)
      setFirstInputEmployeesNumber(Number(targetListState.findTargetListResponse.employeesNumber.split(':')[1]))
      setSecondInputEmployeesNumber(Number(targetListState.findTargetListResponse.employeesNumber.split(':')[2]))
      dispatch(setFindTargetListStatus('idle'))
    }
  }, [targetListState.findTargetListStatus])

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex gap-4">
        <Input
          label="Nome del Target"
          placeholder="Nome lista target"
          disabled={disabled}
          error={targetListState.validateEditTargetList.errors.name}
          supportingText={
            targetListState.validateEditTargetList.errors.name
              ? "Inserire il nome della lista target."
              : ""
          }
          value={targetListState.editTargetListRequest.name}
          defaultValue={targetListState.editTargetListRequest.name}
          onChangeText={(e) => dispatch(setEditTargetListRequestName(e))}
        />
        <SelectCustom
          isMulti
          disabled={disabled}
          placeholder={"Indifferente"}
          options={groupBy(roleDepartment, "department").map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Reparti"
          defaultValue={targetListState.editTargetListRequest.departments}
          onChange={(e) => dispatch(setEditTargetListRequestDepartments(e))}
        />
        <SelectCustom
          isMulti
          placeholder={"Indifferente"}
          disabled={disabled}
          options={groupBy(
            roleDepartment.filter((row) =>
              targetListState.editTargetListRequest?.departments.includes(
                row.department
              )
            ),
            "role"
          ).map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Ruoli"
          defaultValue={targetListState.editTargetListRequest?.roles}
          onChange={(e) => dispatch(setEditTargetListRequestRoles(e))}
        />
      </div>
      <AtecosSegment />
      <div className="flex items-start gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isMulti
            disabled={disabled}
            placeholder={"Seleziona i commerciali"}
            error={targetListState.validateEditTargetList.errors.commercials}
            errorLabel="Selezionare almeno un commerciale."
            options={
              projectsState.findProjectResponse?.commercials.map(
                (commercial) => ({
                  label: commercial.name + " " + commercial.surname,
                  value: commercial.id,
                })
              )!
            }
            label="Assegna ai commerciali"
            defaultValue={targetListState.editTargetListRequest.commercialIds}
            onChange={(e) => dispatch(setEditTargetListRequestCommercials(e))}
          />
        </div>
        <div className="flex gap-4 justify-between items-end" style={{ width: "calc((100% - 32px) / 3 * 2)" }}>
          <div
            style={{ width: "calc((100% - 16px) / 2)" }}
          >
            <SelectParticularities />
          </div>
          <span className="text-text-sm w-f text-neutral-600">
            La particolarità che stai cercando non è presente in elenco?
            <br />
            <span
              onClick={() => dispatch(setShowNewParticularityModal(true))}
              className="cursor-pointer font-semibold text-brandPrimary-600"
            >
              Creane una nuova!
            </span>
          </span>
        </div>
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            onChange={(e) =>
              setSignRevenue(e as "=" | "<" | ">" | "><" | undefined)
            }
            disabled={disabled}
            placeholder={"Indifferente"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Fatturato"
            defaultValue={
              signRevenue
            }
          />
        </div>
        {signRevenue !== undefined && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={firstInputRevenue}
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={(e) => {
                setFirstInputRevenue(e.floatValue);
              }}
              decimalScale={2}
              disabled={disabled}
              fixedDecimalScale
              label={signMap.get(
                signRevenue
              )}
              error={targetListState.validateEditTargetList.errors.revenueFirst}
              supportingText={
                targetListState.validateEditTargetList.errors.revenueFirst
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {signRevenue === "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={
                secondInputRevenue
              }
              thousandSeparator="."
              onValueChange={(e) => {
                setSecondInputRevenue(e.floatValue);
              }}
              decimalSeparator=","
              decimalScale={2}
              disabled={disabled}
              fixedDecimalScale
              label={signMap.get(signRevenue)}
              error={targetListState.validateEditTargetList.errors.revenueSecond}
              supportingText={
                targetListState.validateEditTargetList.errors.revenueSecond
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            disabled={disabled}
            placeholder={"Indifferente"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Numero di dipendenti"
            onChange={e => setSignEmployeesNumber(e as "=" | "<" | ">" | "><" | undefined)}
            defaultValue={
              signEmployeesNumber
            }
          />
        </div>
        {signEmployeesNumber !== undefined && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={
                firstInputEmployeesNumber
              }
              onValueChange={(e) => {
                setFirstInputEmployeesNumber(e.floatValue);
              }}
              thousandSeparator="."
              disabled={disabled}
              decimalSeparator=","
              label={signMap.get(signEmployeesNumber)}
              error={
                targetListState.validateEditTargetList.errors.employeesFirst
              }
              supportingText={
                targetListState.validateEditTargetList.errors.employeesFirst
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {signEmployeesNumber === "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={secondInputEmployeesNumber}
              thousandSeparator="."
              decimalSeparator=","
              disabled={disabled}
              label={signMap.get(signEmployeesNumber)}
              onValueChange={(e) => {
                setSecondInputEmployeesNumber(e.floatValue);
              }}
              error={
                targetListState.validateEditTargetList.errors.employeesSecond
              }
              supportingText={
                targetListState.validateEditTargetList.errors.employeesSecond
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
      <NewParticularity />
    </div>
  );
}
