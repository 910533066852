import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { UploadIcon } from "../../../../../ui/icons/upload";
import Button from "../../../../../ui/molecules/button";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { addContacts } from "../../../../targetList/slice";
import { ImportFromDatabaseList } from "./contactsList";
import { findAllPeopleFromTarget } from "../../../../projects/slice";

interface Props {
  target: { id: string; name: string } | undefined;
}
export function ImportFromPeople(props: Props) {
  const targetListState = useAppSelector((state) => state.targetList);
  const projectsState = useAppSelector((state) => state.projects);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      findAllPeopleFromTarget({
        order: true,
        sort: "name",
        itemsPerPage: 15,
        page: page,
        targetList: [{ label: props.target?.name!, value: props.target?.id! }],
        name: [],
        atecos: [],
        sector: [],
        category: [],
        department: [],
        role: [],
        countryRegion: [],
        city: [],
        zipCode: [],
        project: [],
        outcome: [],
      })
    );
  }, [page]);

  return (
    <div className="flex border-t" style={{ height: 'calc(100% - 64px - 57.33px)' }}>
      <div className="w-full">
        {projectsState.findAllPeopleFromTargetStatus === "loading" ? (
          <div className="w-full h-[45%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-full max-h-full">
            <div className="bg-white h-16 flex items-center justify-between px-4">
              <span className="text-heading-xs font-semibold text-neutral-800">
                {"Lista target > Persone"}
              </span>
              <div className="flex items-center gap-2">
                {selectedIds.length > 0 && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    label={"Importa " + selectedIds.length + " selezionati"}
                    color={"blue"}
                    icon={<UploadIcon color={""} size={0} />}
                    onClick={() => {
                      dispatch(
                        addContacts({
                          targetListId:
                            targetListState.findTargetListResponse?.id!,
                          type: "persons",
                          contactsId: selectedIds,
                        })
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {projectsState.findAllPeopleFromTargetResponse?.data.length === 0 ||
              projectsState.findAllPeopleFromTargetResponse?.data === null ? (
              <div style={{ height: 'calc(100%)' }}>
                <NotFound />
              </div>
            ) : (
              <ImportFromDatabaseList
                selectedIds={selectedIds}
                setSelectedIds={(values) => setSelectedIds(values)}
                setPage={function (page: number): void {
                  setPage(page);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
