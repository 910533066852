import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { BuildingIcon } from "../../../../ui/icons/building"
import { SelectCustomAsync } from "../../../../ui/molecules/asyncSelect"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { NewCompaniesService } from "../service"
import { setFindAllCompaniesFiltersName } from "../slice"

export function CompanyFilter() {
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    const filterCompanies = async (inputValue: string) => {
        return await NewCompaniesService().findAllCompanies({
            order: false,
            itemsPerPage: 30,
            page: 0,
            name: [{ label: inputValue, value: '' }],
            atecos: [],
            sector: [],
            category: [],
            department: [],
            role: [],
            zipCode: [],
            countryRegion: [],
            city: [],
            targetList: [],
            project: [],
            outcome: []
        }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ value: string; label: string; }[]>((resolve) => {
            resolve(filterCompanies(inputValue));
        });

    return (
        <AccordionFilter
            key={'person-filter-company'}
            label={"Azienda"}
            icon={<BuildingIcon color={""} size={0} />}
            showClear={companiesState.filters.name.length > 0}
            options={companiesState.filters.name}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersName([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersName(companiesState.filters.name.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustomAsync
                isMulti
                onChange={e => dispatch(setFindAllCompaniesFiltersName(e))}
                defaultValue={companiesState.filters.name}
                noOptionsMessage="Non sono presenti aziende."
                loadOptions={promiseOptions}
                defaultOptions
                isClearable={false}
                placeholder={"Cerca per ragione sociale"}
            />
        </AccordionFilter>
    )
}