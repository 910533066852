import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { setNewPersonResponse, setNewPersonStatus } from "../people/slice";
import { setTabValue } from "../slice";
import { Filters } from "./filters";
import { CompaniesHorizontalFilters } from "./filters/horizontalFilters";
import { CompaniesList } from "./list";
import {
  countCompanies,
  findAllCompanies,
  findCompany,
  findEmployees,
  setDeleteCompanyStatus,
  setEditCompanyStatus,
  setFindAllCompaniesFiltersPage,
  setFindEmployeesResponse,
  setSelectedCompany,
} from "./slice";

export function Companies() {
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);
  const peopleState = useAppSelector((state) => state.people);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);

  useEffect(() => {
    dispatch(findAllCompanies({ ...companiesState.filters, targetList: [] }));
    setIsFirstLoadCompanies(false);
  }, []);

  useEffect(() => {
    if (companiesState.findAllStatus === 'successfully') {
      dispatch(countCompanies())
    }
  }, [companiesState.findAllStatus])

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(findAllCompanies({ ...companiesState.filters, page: 0 }));
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.filter,
    companiesState.filters.active,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.smartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.project,
    companiesState.filters.outcome,
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(findAllCompanies(companiesState.filters));
  }, [companiesState.filters.page]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.deleteCompanyStatus === "failed" ||
      companiesState.deleteCompanyStatus === "successfully"
    ) {
      if (companiesState.deleteCompanyStatus === "successfully") {
        dispatch(findAllCompanies(companiesState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.deleteCompanyStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      companiesState.editCompanyStatus === "failed" ||
      companiesState.editCompanyStatus === "successfully"
    ) {
      if (companiesState.editCompanyStatus === "successfully") {
        dispatch(setSelectedCompany(undefined));
        dispatch(findAllCompanies(companiesState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [companiesState.editCompanyStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      peopleState.newPersonStatus === "failed" ||
      peopleState.newPersonStatus === "successfully"
    ) {
      if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
        dispatch(findAllCompanies(companiesState.filters));
        dispatch(findCompany(companiesState.findCompanyResponse?.id!))
        dispatch(setSelectedCompany(companiesState.findCompanyResponse?.id))
      }
      timeoutId = setTimeout(() => {
        dispatch(setNewPersonStatus("idle"));
        dispatch(setNewPersonResponse(undefined));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [peopleState.newPersonStatus]);

  useEffect(() => {
    if (
      companiesState.importCSVStatus === "failed" ||
      companiesState.importCSVStatus === "successfully"
    ) {
      navigate("/database");
      dispatch(setTabValue(1));
    }
  }, [companiesState.importCSVStatus]);

  useEffect(() => {
    if (companiesState.findCompanyStatus === 'successfully') {
      dispatch(findEmployees(companiesState.findCompanyResponse?.employeesId!))
    }
  }, [companiesState.findCompanyStatus])

  return (
    <div className="max-h-full flex" style={{ height: 'calc(100% - 63.8px)' }}>
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={"Azienda eliminata."}
          visible={companiesState.deleteCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setDeleteCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'eliminazione dell'azienda."
          }
          visible={companiesState.deleteCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setDeleteCompanyStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Contatto aggiunto correttamente"}
          visible={peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists}
          closeAction={function (): void {
            dispatch(setNewPersonStatus("idle"));
            dispatch(setNewPersonResponse(undefined));
          }}
          type={"success"}
        />
        <Banner
          label={"Il contatto che stai provando ad inserire esiste ed è associato ad un'azienda"}
          visible={peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true}
          closeAction={function (): void {
            dispatch(setNewPersonStatus("idle"));
            dispatch(setNewPersonResponse(undefined));
          }}
          type={"warning"}
        />
        <Banner
          label={"Si è verificato un errore durante l'inserimento del contatto"}
          visible={peopleState.newPersonStatus === "failed"}
          closeAction={function (): void {
            dispatch(setNewPersonStatus("idle"));
            dispatch(setNewPersonResponse(undefined));
          }}
          type={"error"}
        />
        <Banner
          label={"Azienda aggiornata."}
          visible={companiesState.editCompanyStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica dell'azienda."}
          visible={companiesState.editCompanyStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditCompanyStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="flex" style={{ width: 'calc(100%)' }}>
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {companiesState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full max-h-full">
              <CompaniesHorizontalFilters />
              <CompaniesList />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
