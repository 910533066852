import clsx from "clsx";
import { generatePagination } from "../../../lib/utils";
import { colors } from "../../colors";
import { ChevronIcon } from "../../icons/chevron";
import { PaginationProps } from "./dto";

export function Pagination(props: PaginationProps) {
  return (
    <div className="flex 
    items-center justify-between  px-[16px] py-[4px]">
      <span className="text-label-sm text-neutral-500">
        Trovati:{" "}
        <span className="text-label-md font-bold text-neutral-800">
          {props.found}
        </span>
      </span>
      <div className="flex h-[40px] p-[8px] gap-[8px] items-center">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (props.currentPage > 0) {
              props.setPage(props.currentPage - 1);
            }
          }}
        >
          <ChevronIcon
            size={20}
            color={colors.brandPrimary[800]}
            direction="l"
          />
        </div>
        {generatePagination(props.currentPage, props.pages).map(
          (page, index) => (
            <div
              key={"page-" + index}
              onClick={() => {
                if (typeof page === "number") {
                  props.setPage(page - 1);
                }
              }}
              className={clsx("w-[36px] text-text-md text-center", {
                "text-neutral-700":
                  typeof page === "number" && page - 1 !== props.currentPage,
                "text-brandPrimary-500":
                  typeof page === "number" && page - 1 === props.currentPage,
                "cursor-pointer": typeof page !== "string",
              })}
            >
              {page}
            </div>
          )
        )}
        <div
          className="cursor-pointer"
          onClick={() => {
            if (props.currentPage < props.pages - 1) {
              props.setPage(props.currentPage + 1);
            }
          }}
        >
          <ChevronIcon
            size={20}
            color={colors.brandPrimary[800]}
            direction="r"
          />
        </div>
      </div>
    </div>
  );
}
