import { useDebounce } from "../../lib/hooks/debounce";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { groupBy } from "../../lib/utils";
import { SearchIcon } from "../../ui/icons/search";
import Input from "../../ui/molecules/input";
import { SelectCustom } from "../../ui/molecules/select";
import { ProjectStateEnum } from "./dto";
import { setProjectFilterCategory, setProjectFilterCommercial, setProjectFilterName, setProjectFilterSector, setProjectFilterState, setProjectFilterTeamLeader } from "./slice";

// const options = [

// ]

export function ProjectsFilters() {
    const projectsState = useAppSelector(state => state.projects)
    const atecosState = useAppSelector(state => state.preferences)
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const debounce = useDebounce((value) => dispatch(setProjectFilterName(value)), 1000)

    return (
        <div
            className="px-4 bg-white border-t border-t-brandPrimary-100"
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '8px',
                width: '100%',
                height: '64px',
                // flexShrink: 0
            }}
        >
            <Input
                startIcon={<SearchIcon color={""} size={0} />}
                placeholder="Cerca nei progetti"
                onChangeText={(value) =>
                    debounce(value)
                }
                defaultValue={projectsState.filters.name}
            />
            <SelectCustom
                isClearable
                placeholder="Team Leaders"
                options={usersState.findTeamLeadersResponse?.data.map(data => ({ label: data.name + ' ' + data.surname, value: data.id }))!}
                onChange={(value) => dispatch(setProjectFilterTeamLeader(value))}
                defaultValue={projectsState.filters.teamLeaderId}
            />
            <SelectCustom
                isClearable
                placeholder="Commerciali"
                options={usersState.findCommercialsResponse?.data.map(data => ({ label: data.name + ' ' + data.surname, value: data.id }))!}
                onChange={(value) => dispatch(setProjectFilterCommercial(value))}
                defaultValue={projectsState.filters.commercial}
            />
            <SelectCustom
                isClearable
                placeholder="Settore"
                options={groupBy(atecosState.findAllAtecosResponse, "sector").map(sector => ({ label: sector.key, value: sector.key }))}
                onChange={(value) => dispatch(setProjectFilterSector(value))}
                defaultValue={projectsState.filters.sector}
            />
            <SelectCustom
                isClearable
                placeholder="Categoria"
                options={groupBy(atecosState.findAllAtecosResponse, "category").map(sector => ({ label: sector.key, value: sector.key }))}
                onChange={(value) => dispatch(setProjectFilterCategory(value))}
                defaultValue={projectsState.filters.category}
            />
            <SelectCustom
                isClearable
                placeholder="Stato"
                options={[
                    { label: "Da iniziare", value: ProjectStateEnum.TOSTART },
                    { label: "In corso", value: ProjectStateEnum.INPROGRESS },
                    { label: "Sospeso", value: ProjectStateEnum.SUSPENDED },
                    { label: "Concluso", value: ProjectStateEnum.CONCLUDED },
                ]}
                onChange={(value) => dispatch(setProjectFilterState(value))}
                defaultValue={projectsState.filters.state}
            />
        </div>
    )
}