import { useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { CheckCircleIcon } from "../../ui/icons/checkCircle";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Pills from "../../ui/molecules/pills";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { ParticularityStatusEnum, ParticularityStatusMap } from "./dto";
import {
  deleteParticularity,
  findAllParticularities,
  setDeleteParticularityStatus,
  setFindAllParticularitiesOrder,
  setFindAllParticularitiesPage,
  setFindAllParticularitiesSort,
  setSelectedParticularity,
} from "./slice";

export function ParticularitiesList() {
  const particularitiesState = useAppSelector((state) => state.particularities);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      particularitiesState.deleteParticularityStatus === "failed" ||
      particularitiesState.deleteParticularityStatus === "successfully"
    ) {
      setIdToDelete(undefined);
      if (particularitiesState.deleteParticularityStatus === "successfully") {
        dispatch(
          findAllParticularities({
            itemsPerPage: 15,
            order: true,
            page: 0,
            sort: "name",
            name: ''
          })
        );
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteParticularityStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [particularitiesState.deleteParticularityStatus]);

  return (
    <div className="w-[100%] flex flex-col" style={{ height: 'calc(100% - 128px)' }}>
      <div style={{ height: 'calc(100% - 48px)' }} className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase sticky top-0 z-10 text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Nome
                  <div
                    onClick={() => {
                      dispatch(setFindAllParticularitiesSort("name"));
                      dispatch(
                        setFindAllParticularitiesOrder(
                          !particularitiesState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Stato
                  <div
                    onClick={() => {
                      dispatch(setFindAllParticularitiesSort("status"));
                      dispatch(
                        setFindAllParticularitiesOrder(
                          !particularitiesState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left pr-4 pl-[4px]">
                <div className="flex gap-[4px] px-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {particularitiesState.findAllResponse?.data.map((particularity) => (
              <tr
                onClick={() => {
                  dispatch(
                    setSelectedParticularity({
                      id: particularity.id,
                      name: particularity.name,
                    })
                  );
                }}
                className="bg-white cursor-pointer hover:bg-brandPrimary-200 h-[56px] border-b-[1px] border-b-neutral-100"
                key={particularity.id}
              >
                <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                  {particularity.name}
                </td>
                <td className="px-[4px]">
                  <Pills
                    size={"xs"}
                    label={
                      ParticularityStatusMap.get(particularity.status)?.label!
                    }
                    outline={true}
                    emphasis={false}
                    color={
                      ParticularityStatusMap.get(particularity.status)?.color!
                    }
                  />
                </td>
                <td
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  className="w-[20px] cursor-pointer">
                  {(keycloak.hasRealmRole("admin") ||
                    particularity.creatorUserId === keycloak.subject) && (
                      <Dropdown
                        items={[
                          keycloak.hasRealmRole("admin") ||
                            particularity.status !== ParticularityStatusEnum.ACTIVE
                            ? {
                              label: "Modifica",
                              icon: <EditIcon color={""} size={0} />,
                              onClick: () => {
                                dispatch(
                                  setSelectedParticularity({
                                    id: particularity.id,
                                    name: particularity.name,
                                  })
                                );
                              },
                            }
                            : null,
                          keycloak.hasRealmRole("admin") ||
                            particularity.status !== ParticularityStatusEnum.ACTIVE
                            ? {
                              label: "Cambia stato",
                              icon: <CheckCircleIcon color={""} size={0} />,
                              onClick: () => {
                                dispatch(
                                  setSelectedParticularity({
                                    id: particularity.id,
                                    name: particularity.name,
                                  })
                                );
                              },
                            }
                            : null,
                          keycloak.hasRealmRole("admin")
                            ? {
                              label: "Cancella",
                              icon: <TrashIcon color={""} size={0} />,
                              onClick: () => setIdToDelete(particularity.id),
                            }
                            : null,
                        ]}
                      />
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        found={particularitiesState.findAllResponse?.total || 0}
        pages={particularitiesState.findAllResponse?.totalPage || 1}
        currentPage={particularitiesState.filters.page}
        setPage={(page) => dispatch(setFindAllParticularitiesPage(page))}
      />
      <ConfirmModal
        title={"Elimina particolarità"}
        label={"Sei sicuro di voler eliminare questa particolarità?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteParticularity(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
