import { ReactElement, useEffect } from "react";
import { ColumnsOptions } from "./columns";
import { DefaultOption } from "./default";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { setOptionCompanies, setOptionPeople } from "../../../slice";

export function OptionsTargetCompanies() {
  const targetListsState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();

  const optionMap = new Map<"columns" | undefined, ReactElement>([
    [undefined, <DefaultOption />],
    ["columns", <ColumnsOptions />],
  ]);

  useEffect(() => {
    dispatch(setOptionCompanies(undefined));
  }, [targetListsState.optionsCompaniesActive]);

  return (
    <div className="h-full flex-shrink-0 relative bg-white border-l-[1px] border-neutral-100">
      {optionMap.get(targetListsState.optionCompanies)}
    </div>
  );
}
