import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { NotFound } from "../../ui/organisms/notFound";
import { EditParticularity } from "./editParticularity";
import { ParticularitiesFilters } from "./filters";
import { ParticularitiesList } from "./list";
import { NewParticularity } from "./newParticularity";
import {
  findAllParticularities,
  setCreateParticularityStatus,
  setDeleteParticularityStatus,
  setEditParticularityStatus,
  setFindAllParticularitiesPage,
  setShowNewParticularityModal,
} from "./slice";

export function Particularities() {
  const dispatch = useAppDispatch();

  const particularitiesState = useAppSelector((state) => state.particularities);

  useEffect(() => {
    dispatch(
      findAllParticularities({
        itemsPerPage: 15,
        order: true,
        name: '',
        page: 0,
        sort: "name",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setFindAllParticularitiesPage(0));
    dispatch(
      findAllParticularities({ ...particularitiesState.filters, page: 0 })
    );
  }, [
    particularitiesState.filters.status,
    particularitiesState.filters.order,
    particularitiesState.filters.sort,
  ]);

  useEffect(() => {
    dispatch(findAllParticularities(particularitiesState.filters));
  }, [particularitiesState.filters.page]);

  return (
    <Layout
      headerLabel={"Particolarità"}
      breadcrumbItems={["Particolarità"]}
      menuItem={MenuItems.PARTICULARITIES}
      headerChildren={
        <div className="flex w-full items-center justify-end h-full">
          <Button
            size={"sm"}
            label="Aggiungi particolarità"
            iconPosition={"off"}
            variant={"solid"}
            color={"blue"}
            onClick={() => dispatch(setShowNewParticularityModal(true))}
          />
        </div>
      }
    >
      <div className="p-6 h-full max-h-full">
        <div className="fixed flex flex-col gap-4 top-6 z-10 right-6">
          <Banner
            type="success"
            visible={
              particularitiesState.createParticularityStatus === "successfully"
            }
            label={"Particolarità creata correttamente."}
            closeAction={function (): void {
              dispatch(setCreateParticularityStatus("idle"));
            }}
          />
          <Banner
            type="error"
            visible={particularitiesState.createParticularityStatus === "failed"}
            label={
              "Si è verificato un errore durante la creazione della particolarità."
            }
            closeAction={function (): void {
              dispatch(setCreateParticularityStatus("idle"));
            }}
          />
          <Banner
            type="success"
            visible={
              particularitiesState.editParticularityStatus === "successfully"
            }
            label={"Particolarità modificata correttamente."}
            closeAction={function (): void {
              dispatch(setEditParticularityStatus("idle"));
            }}
          />
          <Banner
            type="error"
            visible={particularitiesState.editParticularityStatus === "failed"}
            label={
              "Si è verificato un errore durante la modifica della particolarità."
            }
            closeAction={function (): void {
              dispatch(setEditParticularityStatus("idle"));
            }}
          />
          <Banner
            type="success"
            visible={
              particularitiesState.deleteParticularityStatus === "successfully"
            }
            label={"Particolarità eliminata correttamente."}
            closeAction={function (): void {
              dispatch(setDeleteParticularityStatus("idle"));
            }}
          />
          <Banner
            type="error"
            visible={particularitiesState.deleteParticularityStatus === "failed"}
            label={
              "Si è verificato un errore durante l'eliminazione della particolarità."
            }
            closeAction={function (): void {
              dispatch(setDeleteParticularityStatus("idle"));
            }}
          />
        </div>
        <div className="flex h-full max-h-full flex-col bg-white rounded-xl shadow-sm">
          <div className="p-4">
            <span className="text-heading-xs font-semibold text-neutral-800">
              Overview
            </span>
          </div>
          <ParticularitiesFilters />
          {particularitiesState.findAllStatus === "loading" ||
            particularitiesState.deleteParticularityStatus === "loading" ? (
            <div className="w-full h-[50%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : particularitiesState.findAllResponse?.data.length === 0 ||
            particularitiesState.findAllResponse?.data === null ? (
            <div style={{ height: 'calc(100% - 64px - 60px)' }}>
              <NotFound />
            </div>
          ) : (
            <ParticularitiesList />
          )}
        </div>
      </div>
      <NewParticularity />
      <EditParticularity />
    </Layout>
  );
}
