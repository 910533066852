import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { TabMenu } from "../../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../../ui/organisms/tabs/tabPages";
import {
  newTargetList,
  setNewTargetListAtecos,
  setValidateNewTargetListCategories,
  setValidateNewTargetListCommercials,
  setValidateNewTargetListDepartments,
  setValidateNewTargetListEmployeesFirst,
  setValidateNewTargetListEmployeesSecond,
  setValidateNewTargetListEmployeesSign,
  setValidateNewTargetListName,
  setValidateNewTargetListRevenueFirst,
  setValidateNewTargetListRevenueSecond,
  setValidateNewTargetListRevenueSign,
  setValidateNewTargetListRoles,
  setValidateNewTargetListSectors,
  setValidateNewTargetListStatus,
  validateNewTargetList,
} from "../../../targetList/slice";
import { setValidateNewProjectCommercialIds, setValidateNewProjectCustomerId, setValidateNewProjectName, setValidateNewProjectTeamLeaderId } from "../../slice";
import { Form } from "./form";

export function AddTargetList() {
  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    if (targetListState.validateNewTargetList.status === "successfully") {
      dispatch(newTargetList(targetListState.newTargetListRequest));
      dispatch(setValidateNewTargetListStatus("idle"));
    }
  }, [targetListState.validateNewTargetList.status]);

  useEffect(() => {
    dispatch(setValidateNewTargetListStatus('idle'))
    dispatch(setValidateNewTargetListCategories(false))
    dispatch(setValidateNewTargetListCommercials(false))
    dispatch(setValidateNewTargetListDepartments(false))
    dispatch(setValidateNewTargetListEmployeesFirst(false))
    dispatch(setValidateNewTargetListEmployeesSecond(false))
    dispatch(setValidateNewTargetListEmployeesSign(false))
    dispatch(setValidateNewTargetListName(false))
    dispatch(setValidateNewTargetListRevenueFirst(false))
    dispatch(setValidateNewTargetListRevenueSecond(false))
    dispatch(setValidateNewTargetListRevenueSign(false))
    dispatch(setValidateNewTargetListRoles(false))
    dispatch(setValidateNewTargetListSectors(false))
  }, [])

  return (
    <div className="p-6 h-full max-h-full">
      <div className="flex flex-col h-full max-h-full bg-white rounded-2xl shadow-sm">
        <div className="px-4 flex justify-between items-center">
          <div className="flex items-center gap-6">
            <span className="text-heading-xs font-semibold text-neutral-800">
              Nuova lista target
            </span>
            <TabMenu
              direction={direction}
              setDirection={(value) => setDirection(value)}
              setValue={(e) => {
                setTabValue(e);
              }}
              value={tabValue}
              items={[
                {
                  icon: <></>,
                  label: "Specifiche lista",
                },
                {
                  icon: <></>,
                  label: "Persone (0)",
                  disabled: true,
                },
                {
                  icon: <></>,
                  label: "Aziende (0)",
                  disabled: true,
                },
              ]}
            />
          </div>
          {targetListState.newTargetListStatus === "loading" ||
            targetListState.validateNewTargetList.status === "loading" ? (
            <Spinner />
          ) : (
            <Button
              size={"sm"}
              iconPosition={"left"}
              variant={"outline"}
              color={"blue"}
              label="Salva lista"
              onClick={() =>
                dispatch(
                  validateNewTargetList(targetListState.newTargetListRequest)
                )
              }
            />
          )}
        </div>
        <div style={{ height: 'calc(100% - 64px)' }}>
          <TabPages
            direction={direction}
            setDirection={(value) => setDirection(value)}
            pages={[
              <Form key={0} />,
              <div key={1} className="h-full"></div>,
              <div key={2} className="h-full"></div>,
            ]}
            value={tabValue}
            setValue={(e) => {
              setTabValue(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}
