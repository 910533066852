import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import TextArea from "../../../ui/molecules/textArea";
import { Banner } from "../../../ui/organisms/banner";
import { ProjectStateEnum } from "../dto";
import { setEditProjectDescription, setEditProjectStatus, setNewProjectCommercialIds, setNewProjectCustomerId, setNewProjectDescription, setNewProjectName, setNewProjectStatus, setNewProjectTeamLeaderId } from "../slice";

export function Form() {
    const preferencesState = useAppSelector(state => state.preferences)
    const projectsState = useAppSelector(state => state.projects)
    const usersState = useAppSelector(state => state.users)

    const dispatch = useAppDispatch()

    return (
        <div className="gap-[24px] h-full max-h-full flex flex-col w-[100%] p-[24px]">
            <Banner
                type="error"
                visible={projectsState.editProjectStatus === 'failed'}
                label={"Si è verificato un errore durante la modifica del progetto"}
                closeAction={function (): void {
                    dispatch(setEditProjectStatus('idle'))
                }}
            />
            <div className="p-[24px] h-full overflow-auto gap-[24px] flex flex-col bg-white shadow-sm rounded-[12px]">
                <div className="flex gap-6 items-flex-start">
                    <Input
                        label="Nome del progetto"
                        disabled
                        value={projectsState.findProjectResponse?.name}
                        onChangeText={e => dispatch(setNewProjectName(e))}
                        error={projectsState.validateNewProject.errors.name}
                        supportingText={projectsState.validateNewProject.errors.name ? "Inserire il nome del progetto." : ''}
                    />
                    <SelectCustom
                        isMulti
                        disabled
                        defaultValue={projectsState.findProjectResponse?.commercials.map(commercial => commercial.id)}
                        placeholder={"Seleziona i commericali"}
                        onChange={(e) => dispatch(setNewProjectCommercialIds(e))}
                        options={usersState.findCommercialsResponse?.data.map(commercial => ({ label: commercial.name + ' ' + commercial.surname, value: commercial.id }))!}
                        label="Commerciali"
                        error={projectsState.validateNewProject.errors.commercialIds}
                        errorLabel={projectsState.validateNewProject.errors.commercialIds ? "Selezionare almeno un commerciale." : ''}
                    />
                    <SelectCustom
                        disabled
                        placeholder={"Seleziona il team leader"}
                        options={usersState.findTeamLeadersResponse?.data.map(teamLeader => ({ label: teamLeader.name + ' ' + teamLeader.surname, value: teamLeader.id }))!}
                        defaultValue={projectsState.findProjectResponse?.teamLeader.id}
                        onChange={e => dispatch(setNewProjectTeamLeaderId(e))}
                        label="Team Leader"
                        error={projectsState.validateNewProject.errors.teamLeaderId}
                        errorLabel={projectsState.validateNewProject.errors.teamLeaderId ? "Selezionare il team leader." : ''}
                    />
                </div>
                <div className="h-[1px] w-full bg-neutral-200" />
                <div className="flex gap-6 items-start">
                    <SelectCustom
                        disabled
                        placeholder={"Seleziona il cliente"}
                        defaultValue={projectsState.findProjectResponse?.customer.id}
                        onChange={(e) => dispatch(setNewProjectCustomerId(e))}
                        options={usersState.findClientsResponse?.data.map(client => ({ label: client.companyName ?? '', value: client.id }))!}
                        label="Ragione Sociale"
                        error={projectsState.validateNewProject.errors.customerId}
                        errorLabel={projectsState.validateNewProject.errors.customerId ? "Selezionare il cliente." : ''}
                    />
                    <Input label="P. IVA" disabled value={projectsState.findProjectResponse?.customer.companyVat ?? ''} />
                    <Input label="Referente" disabled value={projectsState.findProjectResponse ? (projectsState.findProjectResponse.customer.name + ' ' + projectsState.findProjectResponse.customer.surname) : ''} />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom placeholder={""} disabled defaultValue={projectsState.findProjectResponse?.customer.companyAtecoCode} options={preferencesState.findAllAtecosResponse.map(ateco => ({ value: ateco.atecoCode, label: ateco.atecoCode + ' - ' + ateco.atecoDescription }))} label="Cod. ATECO" />
                    <SelectCustom placeholder={""} disabled defaultValue={projectsState.findProjectResponse?.customer.companySector} options={groupBy(preferencesState.findAllAtecosResponse, 'sector').map(sector => ({ label: sector.key, value: sector.key }))} label="Settore" />
                    <NumericFormat
                        prefix="€ "
                        value={projectsState.findProjectResponse?.customer.companyRevenue ?? 0}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        customInput={Input}
                        id={"new-user-request-company-revenue"}
                        key={"new-user-request-company-revenue"}
                        placeholder={"Fatturato"}
                        label="Fatturato"
                        disabled
                    />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom placeholder={""} defaultValue={ProjectStateEnum.TOSTART} options={[{ label: "Da iniziare", value: ProjectStateEnum.TOSTART }]} disabled label="Stato" />
                    <Input disabled label="Data inizio" value={projectsState.findProjectResponse?.startDate ? format(new Date(projectsState.findProjectResponse?.startDate), 'dd/MM/yyyy') : ''} />
                    <Input disabled label="Data fine" placeholder="Data fine" value={projectsState.findProjectResponse?.endDate ? format(new Date(projectsState.findProjectResponse?.endDate), 'dd/MM/yyyy') : ''} />
                </div>
                <TextArea label="Descrizione" defaultValue={projectsState.editProjectRequest.description ?? projectsState.findProjectResponse?.description} onChangeText={e => dispatch(setEditProjectDescription(e))} />
            </div>
        </div>
    )
}