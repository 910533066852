import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { PlusIcon } from "../../../ui/icons/plus";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import {
  findAllCompanies,
  setFindAllCompaniesFiltersPage,
  setFindAllCompaniesFiltersTargetList,
} from "../../database/companies/slice";
import {
  findAllPeople,
  setFindAllPeopleFiltersPage,
  setFindAllPeopleFiltersTargetList,
} from "../../database/people/slice";
import { TargetListPageEnum } from "../dto";
import {
  editTargetList,
  findTargetListById,
  getCounters,
  setAddCompaniesBulkStatus,
  setAddContactsStatus,
  setAddPeopleBulkStatus,
  setChangeContactStateStatus,
  setEditTargetListAtecosOptions,
  setEditTargetListStatus,
  setEditTargetListTabValue,
  setFindTargetListStatus,
  setRemoveContactsStatus,
  setTargetListPage,
  setValidateEditTargetListCategories,
  setValidateEditTargetListCommercials,
  setValidateEditTargetListDepartments,
  setValidateEditTargetListEmployeesFirst,
  setValidateEditTargetListEmployeesSecond,
  setValidateEditTargetListEmployeesSign,
  setValidateEditTargetListName,
  setValidateEditTargetListRevenueFirst,
  setValidateEditTargetListRevenueSecond,
  setValidateEditTargetListRevenueSign,
  setValidateEditTargetListRoles,
  setValidateEditTargetListSectors,
  setValidateEditTargetListStatus,
  validateEditTargetList
} from "../slice";
import { TargetListCompanies } from "./companies";
import { Form } from "./form";
import { TargetListPeople } from "./people";

export function EditTargetList() {
  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);
  const companiesState = useAppSelector((state) => state.companies);
  const peopleState = useAppSelector((state) => state.people);

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);
  const [isFirstLoadPeople, setIsFirstLoadPeople] = useState(true);

  useEffect(() => {
    dispatch(findTargetListById(targetListState.selectedTargetList!));
    dispatch(setEditTargetListAtecosOptions([]))
  }, []);

  useEffect(() => {
    const contacts = targetListState.editTargetListTabValue === 1 ? targetListState.getTargetListCounterResponse?.persons : targetListState.findTargetListResponse?.companyContacts
    dispatch(
      setTargetListPage(
        Object.keys(
          contacts ?? {}
        ).length === 0
          ? TargetListPageEnum.ADD
          : TargetListPageEnum.LIST
      )
    );
  }, [targetListState.editTargetListTabValue]);

  // useEffect(() => {
  //   if (targetListState.findTargetListStatus === 'successfully') {

  //   }
  // }, [targetListState.editTargetListTabValue, targetListState.findTargetListStatus]);

  useEffect(() => {
    if (targetListState.validateEditTargetList.status === "successfully") {
      dispatch(editTargetList({ id: targetListState.findTargetListResponse?.id!, data: targetListState.editTargetListRequest }));
      dispatch(setValidateEditTargetListStatus("idle"));
      dispatch(setEditTargetListAtecosOptions([]))
    }
  }, [targetListState.validateEditTargetList.status]);

  useEffect(() => {
    dispatch(setValidateEditTargetListStatus('idle'))
    dispatch(setValidateEditTargetListCategories(false))
    dispatch(setValidateEditTargetListCommercials(false))
    dispatch(setValidateEditTargetListDepartments(false))
    dispatch(setValidateEditTargetListEmployeesFirst(false))
    dispatch(setValidateEditTargetListEmployeesSecond(false))
    dispatch(setValidateEditTargetListEmployeesSign(false))
    dispatch(setValidateEditTargetListName(false))
    dispatch(setValidateEditTargetListRevenueFirst(false))
    dispatch(setValidateEditTargetListRevenueSecond(false))
    dispatch(setValidateEditTargetListRevenueSign(false))
    dispatch(setValidateEditTargetListRoles(false))
    dispatch(setValidateEditTargetListSectors(false))
  }, [])

  useEffect(() => {
    if (!isFirstLoadCompanies) {
      dispatch(setFindAllCompaniesFiltersPage(0));
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          page: 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  value: targetListState.findTargetListResponse?.id!,
                  label: targetListState.findTargetListResponse?.name!,
                },
              ]
              : [],
        })
      );
    }
  }, [
    companiesState.filters.order,
    companiesState.filters.sort,
    companiesState.filters.name,
    companiesState.filters.atecos,
    companiesState.filters.category,
    companiesState.filters.city,
    companiesState.filters.countryRegion,
    companiesState.filters.department,
    companiesState.filters.employees,
    companiesState.filters.filter,
    companiesState.filters.active,
    companiesState.filters.privacy,
    companiesState.filters.revenue,
    companiesState.filters.role,
    companiesState.filters.sector,
    companiesState.filters.smartphone,
    companiesState.filters.state,
    companiesState.filters.zipCode,
    companiesState.filters.targetList,
    companiesState.filters.outcome,
    companiesState.filters.project,
  ]);

  useEffect(() => {
    if (!isFirstLoadPeople) {
      dispatch(setFindAllPeopleFiltersPage(0));
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          page: 0,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  value: targetListState.findTargetListResponse?.id!,
                  label: targetListState.findTargetListResponse?.name!,
                },
              ]
              : [],
        })
      );
    }
  }, [
    peopleState.filters.order,
    peopleState.filters.sort,
    peopleState.filters.name,
    peopleState.filters.atecos,
    peopleState.filters.category,
    peopleState.filters.city,
    peopleState.filters.countryRegion,
    peopleState.filters.department,
    peopleState.filters.employees,
    peopleState.filters.privacy,
    peopleState.filters.revenue,
    peopleState.filters.role,
    peopleState.filters.sector,
    peopleState.filters.smartphone,
    peopleState.filters.state,
    peopleState.filters.zipCode,
    peopleState.filters.targetList,
    peopleState.filters.personName,
    peopleState.filters.personEmail,
    peopleState.filters.hasCompany,
    peopleState.filters.active,
    peopleState.filters.project,
    peopleState.filters.outcome,
  ]);

  useEffect(() => {
    if (!isFirstLoadCompanies)
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 0,
        })
      );
  }, [companiesState.filters.page]);

  useEffect(() => {
    if (!isFirstLoadPeople)
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
        })
      );
  }, [peopleState.filters.page]);

  useEffect(() => {
    if (targetListState.findTargetListStatus === "successfully") {
      dispatch(setFindTargetListStatus("idle"));
      const contacts = targetListState.editTargetListTabValue === 1 ? targetListState.getTargetListCounterResponse?.persons : targetListState.findTargetListResponse?.companyContacts
      dispatch(
        setTargetListPage(
          Object.keys(
            contacts ?? {}
          ).length === 0
            ? TargetListPageEnum.ADD
            : TargetListPageEnum.LIST
        )
      );
      dispatch(getCounters(targetListState.findTargetListResponse?.id!))
      dispatch(
        findAllCompanies({
          ...companiesState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? companiesState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
              : [],
        })
      );
      dispatch(
        setFindAllPeopleFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
              {
                label: targetListState.findTargetListResponse?.name!,
                value: targetListState.findTargetListResponse?.id!,
              },
            ]
            : []
        )
      );
      dispatch(
        setFindAllCompaniesFiltersTargetList(
          targetListState.targetListPage !== TargetListPageEnum.DB
            ? [
              {
                label: targetListState.findTargetListResponse?.name!,
                value: targetListState.findTargetListResponse?.id!,
              },
            ]
            : []
        )
      );
      dispatch(
        findAllPeople({
          ...peopleState.filters,
          itemsPerPage:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? peopleState.filters.itemsPerPage
              : 0,
          targetList:
            targetListState.targetListPage !== TargetListPageEnum.DB
              ? [
                {
                  label: targetListState.findTargetListResponse?.name!,
                  value: targetListState.findTargetListResponse?.id!,
                },
              ]
              : [],
        })
      );
      setIsFirstLoadCompanies(false);
      setIsFirstLoadPeople(false);
    }
  }, [targetListState.findTargetListStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.addContactsStatus === "failed" ||
      targetListState.addContactsStatus === "successfully"
    ) {
      if (targetListState.addContactsStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setAddContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.addContactsStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.addPeopleBulkStatus === "failed" ||
      targetListState.addPeopleBulkStatus === "successfully"
    ) {
      if (targetListState.addPeopleBulkStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setAddPeopleBulkStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.addPeopleBulkStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.addCompaniesBulkStatus === "failed" ||
      targetListState.addCompaniesBulkStatus === "successfully"
    ) {
      if (targetListState.addCompaniesBulkStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setAddCompaniesBulkStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.addCompaniesBulkStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.editTargetListStatus === "failed" ||
      targetListState.editTargetListStatus === "successfully"
    ) {
      if (targetListState.editTargetListStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
      }
      timeoutId = setTimeout(() => {
        dispatch(setEditTargetListStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.editTargetListStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.removeContactsStatus === "failed" ||
      targetListState.removeContactsStatus === "successfully"
    ) {
      if (targetListState.removeContactsStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setTargetListPage(TargetListPageEnum.LIST));
      }
      timeoutId = setTimeout(() => {
        dispatch(setRemoveContactsStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.removeContactsStatus]);

  return (
    <div className="h-full max-h-full p-6">
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={"Esito aggiornato."}
          visible={targetListState.changeContactStateStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setChangeContactStateStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica dell'esito."}
          visible={targetListState.changeContactStateStatus === "failed"}
          closeAction={function (): void {
            dispatch(setChangeContactStateStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={
            (targetListState.editTargetListTabValue === 1 ? "Persone " : "Aziende") + " aggiunte alla lista."
          }
          visible={targetListState.addContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setAddContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Lista target modificata correttamente."}
          visible={targetListState.editTargetListStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditTargetListStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica della lista target"}
          visible={targetListState.editTargetListStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditTargetListStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiunta delle " +
            (targetListState.editTargetListTabValue === 1 ? "persone " : "aziende") +
            " alla lista target."
          }
          visible={targetListState.addContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setAddContactsStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Persone aggiunte alla lista."}
          visible={targetListState.addPeopleBulkStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setAddPeopleBulkStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiunta delle persone alla lista target."
          }
          visible={targetListState.addPeopleBulkStatus === "failed"}
          closeAction={function (): void {
            dispatch(setAddPeopleBulkStatus("idle"));
          }}
          type={"error"}
        />
        <Banner
          label={"Aziende aggiunte alla lista."}
          visible={targetListState.addCompaniesBulkStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setAddCompaniesBulkStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'aggiunta delle aziende alla lista target."
          }
          visible={targetListState.addCompaniesBulkStatus === "failed"}
          closeAction={function (): void {
            dispatch(setAddCompaniesBulkStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      {targetListState.findTargetListStatus === "loading" || targetListState.getTargetListCounterStatus === 'loading' ? (
        <Spinner />
      ) : (
        <div className="flex flex-col bg-white rounded-2xl shadow-sm h-full max-h-full">
          <div className="p-4 pb-0 flex justify-between items-center sticky">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-6">
                <span className="text-heading-xs font-semibold text-neutral-800">
                  {targetListState.findTargetListResponse?.name}
                </span>
                <TabMenu
                  direction={direction}
                  setDirection={(value) => setDirection(value)}
                  setValue={(e) => {
                    dispatch(setEditTargetListTabValue(e));
                    if (e === 1 || e === 2) {
                      dispatch(setTargetListPage(TargetListPageEnum.LIST));
                    }
                  }}
                  value={targetListState.editTargetListTabValue}
                  items={[
                    {
                      icon: <></>,
                      label: "Specifiche lista",
                    },
                    {
                      icon: <></>,
                      label:
                        "Persone (" +
                        // (targetListState.getTargetListCounterStatus === 'loading' ?
                        //   <Spinner size={10} />
                        //   :
                        targetListState.getTargetListCounterResponse?.persons.contacts
                        // ) 
                        +
                        ")",
                    },
                    {
                      icon: <></>,
                      label:
                        "Aziende (" +
                        // (targetListState.getTargetListCounterStatus === 'loading' ?
                        //   <Spinner size={10} />
                        //   :
                        targetListState.getTargetListCounterResponse?.companies.contacts
                        // )
                        +
                        ")",
                    },
                  ]}
                />
              </div>
              {
                targetListState.editTargetListTabValue === 0 && (!targetListState.findTargetListResponse?.companyContacts && !targetListState.findTargetListResponse?.personContacts) &&
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  label="Aggiorna"
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    dispatch(
                      validateEditTargetList(targetListState.editTargetListRequest)
                    );
                  }}
                />
              }
              {targetListState.editTargetListTabValue !== 0 &&
                targetListState.targetListPage !== TargetListPageEnum.CSV &&
                targetListState.targetListPage !== TargetListPageEnum.DB &&
                targetListState.targetListPage !== TargetListPageEnum.TL &&
                targetListState.targetListPage !== TargetListPageEnum.ADD && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<PlusIcon color={""} size={0} />}
                    label={
                      targetListState.editTargetListTabValue === 2 ? "Aggiungi aziende" : "Aggiungi persone"
                    }
                    onClick={() =>
                      dispatch(setTargetListPage(TargetListPageEnum.ADD))
                    }
                  />
                )}
            </div>
          </div>
          <div style={{ height: 'calc(100% - 79px)' }} className="border-t border-t-neutral-200">
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <div key={0} className="max-h-full h-full overflow-auto">
                  <Form />
                </div>,
                <div key={1} className="h-full">
                  <TargetListPeople />
                </div>,
                <div key={2} className="h-full">
                  <TargetListCompanies />
                </div>,
              ]}
              value={targetListState.editTargetListTabValue}
              setValue={(e) => {
                dispatch(setEditTargetListTabValue(e));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
