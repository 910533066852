import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { BuildingIcon } from "../../ui/icons/building";
import { DownloadIcon } from "../../ui/icons/download";
import { FiltersLinesIcon } from "../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../ui/icons/moreHorizontal";
import { TrashIcon } from "../../ui/icons/trash";
import { UsersIcon } from "../../ui/icons/users";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../ui/organisms/tabs/tabPages";
import { Companies } from "./companies";
import { CompanyEmployees } from "./companies/companyEmployees";
import { EditCompany } from "./companies/editCompany";
import { ImportModalCompanies } from "./companies/import/modal";
import { OptionsCompanies } from "./companies/options/options";
import {
  deleteCompany,
  getCSVCompanies,
  setFindAllCompaniesFiltersTargetList,
  setFindEmployeesResponse,
  setFindEmployeesStatus,
  setSelectedCompany,
  setSelectedIdsCompanies,
  setShowPopupCompanies
} from "./companies/slice";
import { People } from "./people";
import { AssociateCompanyModal } from "./people/associateCompany";
import { EditPerson } from "./people/editPerson";
import { ImportModalPeople } from "./people/import/modal";
import { OptionsPeople } from "./people/options/options";
import {
  deletePerson,
  getCSVPeople,
  setFindAllPeopleFiltersTargetList,
  setSelectedIdsPeople,
  setSelectedPerson,
  setShowPopupPeople
} from "./people/slice";
import { setFiltersActive, setOptionsActive, setTabValue } from "./slice";

export function Database() {
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const companiesState = useAppSelector((state) => state.companies);
  const [direction, setDirection] = useState<"left" | "right">("left");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setFindAllCompaniesFiltersTargetList([]));
    dispatch(setFindAllPeopleFiltersTargetList([]));
  }, []);

  useEffect(() => {
    dispatch(setFindEmployeesResponse([]));
    dispatch(setFindEmployeesStatus("idle"));
  }, [databaseState.tabValue]);

  return (
    <Layout
      menuItem={MenuItems.DATABASE}
      breadcrumbItems={["Database"]}
      headerLabel="Database"
      headerChildren={
        <div className="flex items-center h-full justify-end">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"solid"}
            color={"blue"}
            onClick={() => {
              if (databaseState.tabValue === 0) {
                navigate("/import-people");
              }
              if (databaseState.tabValue === 1) {
                navigate("/import-companies");
              }
            }}
            label="Importa contatti"
          />
        </div>
      }
    >
      <div className="flex max-w-full w-full max-h-full h-full overflow-hidden p-6">
        <div className="flex max-w-full w-full h-full rounded-xl flex-col relative rounded-t-xl bg-white shadow-sm ">
          <div className="px-6 flex max-w-full w-full items-center justify-between border-b border-b-neutral-200">
            <div className="flex items-center ">
              <span className="text-heading-xs font-semibold text-neutral-800">
                Overview
              </span>
              <TabMenu
                direction={direction}
                setDirection={(value) => setDirection(value)}
                setValue={(e) => {
                  dispatch(setTabValue(e));
                  dispatch(setSelectedCompany(undefined));
                  dispatch(setSelectedPerson(undefined));
                }}
                value={databaseState.tabValue}
                items={[
                  {
                    icon: <UsersIcon color={""} size={0} />,
                    label: "Persone",
                  },
                  {
                    icon: <BuildingIcon color={""} size={0} />,
                    label: "Aziende",
                  },
                ]}
              />
            </div>
            <div className="flex gap-2">
              {peopleState.selectedIds.length > 0 && databaseState.tabValue === 0 && (
                <>
                  <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<DownloadIcon color={""} size={0} />}
                    onClick={() => dispatch(getCSVPeople(peopleState.selectedIds))}

                  />
                  <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<TrashIcon color={""} size={0} />}
                    onClick={() => dispatch(setShowPopupPeople(true))}
                  />
                </>
              )}
              {companiesState.selectedIds.length > 0 && databaseState.tabValue === 1 && (
                <>
                  <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<DownloadIcon color={""} size={0} />}
                    onClick={() => dispatch(getCSVCompanies(companiesState.selectedIds))}

                  />
                  <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<TrashIcon color={""} size={0} />}
                    onClick={() => dispatch(setShowPopupCompanies(true))}
                  />
                </>
              )}
              <Button
                size={"sm"}
                iconPosition={"only"}
                variant={databaseState.optionsActive ? "outline" : "ghost"}
                color={databaseState.optionsActive ? "blue" : "gray"}
                onClick={() =>
                  dispatch(setOptionsActive(!databaseState.optionsActive))
                }
                icon={<MoreHorizontalIcon color={""} size={0} />}
              />
              <Button
                size={"sm"}
                iconPosition={"off"}
                label="Filtri / Espandi"
                variant={databaseState.filtersActive ? "outline" : "ghost"}
                color={databaseState.filtersActive ? "blue" : "gray"}
                onClick={() =>
                  dispatch(setFiltersActive(!databaseState.filtersActive))
                }
                icon={<FiltersLinesIcon color={""} size={0} />}
              />
            </div>
          </div>
          <div className="max-h-full max-w-full h-full rounded-xl">
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <People key={0} />,
                <Companies key={1} />
                // <div key={2} className="h-full w-full"></div>
              ]}
              value={databaseState.tabValue}
              setValue={(e) => {
                dispatch(setTabValue(e));
                dispatch(setSelectedCompany(undefined));
                dispatch(setSelectedPerson(undefined));
              }}
            />
          </div>
        </div>
        <ConfirmModal
          loading={peopleState.deletePersonStatus === "loading"}
          title={
            peopleState.selectedIds.length > 1 ?
              "Elimina persone" :
              "Elimina persone"
          }
          label={
            peopleState.selectedIds.length > 1 ?
              "Sei sicuro di voler eliminare queste persone?" :
              "Sei sicuro di voler eliminare questa persona?"
          }
          confirmAction={function (): void {
            dispatch(
              deletePerson(peopleState.selectedIds)
            );
          }}
          onClose={function (): void {
            dispatch(setSelectedIdsPeople([]));
            dispatch(setShowPopupPeople(false));
          }}
          visible={peopleState.showPopup}
        />
        <ConfirmModal
          loading={companiesState.deleteCompanyStatus === "loading"}
          title={
            companiesState.selectedIds.length > 1 ?
              "Elimina aziende" :
              "Elimina azienda"
          }
          label={
            companiesState.selectedIds.length > 1 ?
              "Sei sicuro di voler eliminare queste aziende?" :
              "Sei sicuro di voler eliminare questa azienda?"
          }
          confirmAction={function (): void {
            dispatch(
              deleteCompany(companiesState.selectedIds)
            );
          }}
          onClose={function (): void {
            dispatch(setSelectedIdsCompanies([]));
            dispatch(setShowPopupCompanies(false));
          }}
          visible={companiesState.showPopup}
        />
        <ImportModalCompanies />
        <ImportModalPeople />
        <AssociateCompanyModal />
        <div
          style={{ height: "calc(100% - 144px)" }}
          className={clsx(
            "absolute right-0 h-full bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
            {
              visible:
                databaseState.optionsActive && databaseState.tabValue === 0,
              hidden:
                !databaseState.optionsActive || databaseState.tabValue !== 0,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <OptionsPeople />
          </div>
        </div>
        <div
          style={{ height: "calc(100% - 144px)" }}
          className={clsx(
            "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
            {
              hidden: peopleState.selectedPerson === undefined,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <EditPerson />
          </div>
        </div>
        <div
          style={{ height: "calc(100% - 144px)" }}
          className={clsx(
            "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
            {
              visible:
                databaseState.optionsActive && databaseState.tabValue === 1,
              hidden:
                !databaseState.optionsActive || databaseState.tabValue !== 1,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <OptionsCompanies />
          </div>
        </div>
        <div
          style={{ height: "calc(100% - 144px)" }}
          className={clsx(
            "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
            {
              hidden: companiesState.selectedCompany === undefined,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <EditCompany />
          </div>
        </div>
      </div>
    </Layout>
  );
}
