import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { SelectCustom } from "../../ui/molecules/select";
import { ParticularityStatusEnum, ParticularityStatusMap } from "./dto";
import { setFindAllParticularitiesStatus } from "./slice";

export function ParticularitiesFilters() {
  const particularitiesState = useAppSelector((state) => state.particularities);
  const dispatch = useAppDispatch();

  return (
    <div
      className="px-4 bg-white border-t border-t-brandPrimary-100"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: "100%",
        height: "64px",
        flexShrink: 0
      }}
    >
      <div className="w-60">
        <SelectCustom
          isClearable
          placeholder={"Stato"}
          defaultValue={particularitiesState.filters.status}
          onChange={(e) => dispatch(setFindAllParticularitiesStatus(e))}
          options={[
            {
              label: ParticularityStatusMap.get(ParticularityStatusEnum.ACTIVE)
                ?.label!,
              value: ParticularityStatusEnum.ACTIVE,
            },
            {
              label: ParticularityStatusMap.get(
                ParticularityStatusEnum.CANCELED
              )?.label!,
              value: ParticularityStatusEnum.CANCELED,
            },
            {
              label: ParticularityStatusMap.get(ParticularityStatusEnum.IDLE)
                ?.label!,
              value: ParticularityStatusEnum.IDLE,
            },
          ]}
        />
      </div>
    </div>
  );
}
