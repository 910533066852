import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { UserPlusIcon } from "../../../ui/icons/userPlus";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { setNewPersonRequestCompanyName, setNewPersonRequestCompanyPhone, setNewPersonRequestCompanyVat } from "../people/slice";
import { AccordionEmployee } from "./accordionEmployee";
import { findEmployees } from "./slice";

interface Props {
  goToForm: Function
}

export function CompanyEmployees(props: Props) {
  const companiesState = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch()

  if (!companiesState.findCompanyResponse) {
    return (<></>)
  }
  return (
    <div className="h-full">
      {
        companiesState.findCompanyStatus === "loading" ||
          companiesState.findEmployeesStatus === 'loading' ?
          (
            <Spinner />
          ) : (
            <>
              {
                companiesState.findCompanyResponse?.employeesId === null ||
                  companiesState.findCompanyResponse?.employeesId.length === 0 ?
                  <div className="py-4 text-center text-text-sm text-neutral-600 font-semibold">
                    Non è stato trovato alcun dipendente per questa azienda.
                  </div> :
                  <div className="">
                    <div className="h-full">
                      {companiesState.findEmployeesResponse.map((employee) => (
                        <AccordionEmployee employee={employee} />
                      ))}
                    </div>
                  </div>
              }
              <div className="flex flex-col justify-end items-end gap-1 pb-4">
                <div className="h-[1px] w-full bg-neutral-200" />
                <Button
                  size={"sm"}
                  icon={<UserPlusIcon color={""} size={0} />}
                  iconPosition={"left"}
                  variant={"link"}
                  color={"blue"}
                  onClick={() => {
                    props.goToForm()
                    dispatch(findEmployees(companiesState.findCompanyResponse?.employeesId!));
                    dispatch(setNewPersonRequestCompanyName(companiesState.findCompanyResponse?.name))
                    dispatch(setNewPersonRequestCompanyPhone(companiesState.findCompanyResponse?.phone))
                    dispatch(setNewPersonRequestCompanyVat(companiesState.findCompanyResponse?.vat))
                  }}
                  label="Aggiungi nuovo dipendente"
                />
              </div>
            </>
          )}
    </div>
  );
}
