import { ReactElement } from "react";
import { useAppDispatch } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../ui/icons/circle";
import { LinkedinIcon } from "../../../../ui/icons/linkedin";
import { PersonDTO } from "../../../database/people/dto";
import { setSelectedPerson, setTabValueDetailsPerson } from "../../../database/people/slice";
import { formatDate } from "date-fns";
import Pills from "../../../../ui/molecules/pills";
import { contactsStatePillMap, ContactStateEnum, TargetListContactInfo } from "../../dto";

interface Props {
  person: PersonDTO;
  column: string;
  contactInfo: TargetListContactInfo
}
export function PeopleColumnMap(props: Props) {
  const dispatch = useAppDispatch();

  const ColumnMap = new Map<string, ReactElement>([
    [
      "Nome referente",
      <td
        onClick={() => {
          dispatch(setSelectedPerson(props.person.id))
          dispatch(setTabValueDetailsPerson(0))
        }}
        className="cursor-pointer max-w-[150px] crop-text text-text-sm font-semibold text-brandPrimary-600"
      >
        {(props.person.name ?? "-")}
      </td>,
    ],
    [
      "Cognome referente",
      <td
        onClick={() => {
          dispatch(setSelectedPerson(props.person.id))
          dispatch(setTabValueDetailsPerson(0))
        }}
        className="cursor-pointer max-w-[150px] crop-text text-text-sm font-semibold text-brandPrimary-600"
      >
        {(props.person.surname ?? "-")}
      </td>,
    ],
    [
      "Linkedin",
      <td>
        <a
          rel="noreferrer"
          target="_blank"
          href={props.person.linkedinProfile ?? undefined}
          className="flex items-center justify-center"
        >
          <LinkedinIcon
            color={
              props.person.linkedinProfile
                ? colors.neutral[400]
                : colors.neutral[200]
            }
            size={20}
          />
        </a>
      </td>,
    ],
    [
      "Ruolo",
      <td className="max-w-[150px] text-text-sm crop-text font-semibold text-neutral-600">
        {props.person.role ?? "-"}
      </td>,
    ],
    [
      "Data di contatto",
      <td className="max-w-[150px] text-text-sm crop-text text-neutral-600">
        {props.contactInfo && props.contactInfo.lastContactDate ? formatDate(new Date(props.contactInfo.lastContactDate), 'dd/MM/yyyy hh:mm') : "-"}
      </td>,
    ],
    [
      'Esito',
      <td>
        {
          props.contactInfo && props.contactInfo.contactState ?
            <Pills
              size={"sm"}
              label={contactsStatePillMap.get(props.contactInfo.contactState)?.label!}
              outline={false}
              emphasis={false}
              color={contactsStatePillMap.get(props.contactInfo.contactState)?.color ?? 'blue'}
            />
            : '-'
        }
      </td>
    ],
    [
      'Note',
      <td className="max-w-[150px] text-text-sm crop-text text-neutral-600">
        {props.contactInfo && props.contactInfo.notes ? props.contactInfo.notes.length : "-"}
      </td>,
    ],
    [
      "Smartphone",
      <td>
        {props.person.phone ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"tel:" + props.person.phone}
          >
            <div className="text-text-sm font-semibold text-neutral-600 max-w-[100px] crop-text">
              {props.person.phone}
            </div>
          </a>
        ) : (
          "-"
        )}
      </td>,
    ],
    [
      "Email",
      <td>
        {props.person.email ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"mailto:" + props.person.email}
          >
            <div className="text-text-sm font-semibold text-neutral-600 max-w-[200px] crop-text">
              {props.person.email}
            </div>
          </a>
        ) : (
          "-"
        )}
      </td>,
    ],
    [
      "Azienda",
      <td
        onClick={() => {
          dispatch(setTabValueDetailsPerson(1))
          dispatch(setSelectedPerson(props.person.id))
        }}
        className="max-w-[200px] cursor-pointer crop-text text-text-sm font-semibold text-brandPrimary-600"
      >
        {props.person.companyName ?? "-"}
      </td>,
    ],
    [
      "Reparto",
      <td className="max-w-[150px] text-text-sm font-semibold text-neutral-600">
        {props.person.department ?? "-"}
      </td>,
    ],
    [
      "Privacy",
      <td className="h-full">
        <div className="flex justify-center items-center">
          {props.person.privacy ? (
            <CheckCircleIcon color={colors.neutral[400]} size={20} />
          ) : (
            <CircleIcon color={colors.neutral[400]} size={20} />
          )}
        </div>
      </td>,
    ],
  ]);

  return <>{ColumnMap.get(props.column)}</>;
}
