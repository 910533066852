import { ReactElement } from "react";
import { useAppSelector } from "../../../../lib/redux/hooks";
import { TargetListPageEnum } from "../../../targetList/dto";
import { ImportFromCSV } from "./csv";
import { ImportModalPeople } from "./csv/modal";
import { ImportFromDatabase } from "./database";
import { ImportType } from "./importType";
import { TargetListPeopleList } from "./list/index";
import { ImportFromTargetList } from "./targetList";

export function TargetListPeople() {
  const targetListsState = useAppSelector((state) => state.targetList);

  const pageMap = new Map<TargetListPageEnum, ReactElement>([
    [TargetListPageEnum.ADD, <ImportType />],
    [TargetListPageEnum.CSV, <ImportFromCSV />],
    [TargetListPageEnum.DB, <ImportFromDatabase />],
    [TargetListPageEnum.TL, <ImportFromTargetList />],
    [TargetListPageEnum.LIST, <TargetListPeopleList />],
  ]);

  return (
    <div className="h-full max-h-full">
      {pageMap.get(targetListsState.targetListPage)}
      <ImportModalPeople />
    </div>
  );
}
