import clsx from "clsx"
import { ReactElement } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { SortIcon } from "../../../ui/icons/sort"
import { setFindAllPeopleFiltersOrder, setFindAllPeopleFiltersSort } from "./slice"

interface Props {
    column: string
    index: number
}
export function Head(props: Props) {
    const { column, index } = props

    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    const cellMap = new Map<string, ReactElement>([
        ['Contatto', <div className={clsx("flex gap-[4px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('name')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Telefono', <div className={clsx("flex gap-[4px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('phone')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Email', <div className={clsx("flex gap-[4px] max-w-[200px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('email')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Azienda', <div className={clsx("flex gap-[4px]", {
        })}>
            {column}
        </div>],
        ['Linkedin', <div className={clsx("flex gap-[4px] justify-center", {
        })}>
            {column}
        </div>],
        ['Ruolo', <div className={clsx("flex gap-[4px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('role')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Reparto', <div className={clsx("flex gap-[4px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('department')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Stato', <div className={clsx("flex gap-[4px]", {
        })}>
            <div
                onClick={() => { dispatch(setFindAllPeopleFiltersSort('state')); dispatch(setFindAllPeopleFiltersOrder(!peopleState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Privacy', <div className={clsx("flex gap-[4px] justify-center", {
        })}>
            {column}
        </div>],
    ])

    return (
        <th className={clsx("text-left", {
        })}>
            {cellMap.get(column)}
        </th>
    )
}