import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { XCircleIcon } from "../../../../ui/icons/xCircle";
import Button from "../../../../ui/molecules/button";
import { setImportCSVSResponse, setImportCSVStatus } from "../slice";
import { ContactMissed } from "./contactMissed";

export function ImportModalCompanies() {
  const companiesState = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() => {
        dispatch(setImportCSVStatus("idle"));
        dispatch(setImportCSVSResponse(undefined));
      }}
      className={clsx(
        "transition-all absolute bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block:
            companiesState.importCSVStatus === "successfully" ||
            companiesState.importCSVStatus === "failed",
          hidden: !(
            companiesState.importCSVStatus === "successfully" ||
            companiesState.importCSVStatus === "failed"
          ),
        }
      )}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="transition-all flex flex-col items-center gap-5 px-[40px] py-6 bg-white rounded-[30px] opacity-100 shadow-sm min-w-[400px]"
      >
        {
          companiesState.importCSVStatus === "successfully" && companiesState.importCSVResponse?.missed.length && companiesState.importCSVResponse?.missed.length >= 0 ?
            <ContactMissed /> :
            <>
              {companiesState.importCSVStatus === "successfully" ? (
                <CheckCircleIcon color={colors.green[500]} size={70} />
              ) : (
                <XCircleIcon color={colors.red[500]} size={70} />
              )}
              <span className="text-text-2xl font-semibold text-neutral-800">
                {companiesState.importCSVStatus === "successfully"
                  ? companiesState.importCSVResponse?.ids.length +
                  " contatti importati con successo"
                  : "Impossibile importare i contatti"}
              </span>
              {companiesState.importCSVResponse !== undefined &&
                companiesState.importCSVResponse.missed.length > 0 && (
                  <div className="flex flex-col gap-3 bg-neutral-50 p-6 rounded-[16px]">
                    <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full">
                      Attenzione
                    </span>
                    <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full">
                      {companiesState.importCSVResponse.missed.length} contatti{" "}
                      <span className="font-normal">
                        nelle seguenti righe presentano errori e non sono stati
                        importati:
                      </span>
                    </span>
                    <ul className="overflow-auto max-h-40 w-full flex flex-col gap-1">
                      {companiesState.importCSVResponse.missed.map((line) => (
                        <li key={line} className="flex flex-col gap-[4px]">
                          <span className="text-text-md font-medium text-neutral-700 block pl-[8px]">
                            {line}
                          </span>
                          <div className="w-full h-[1px] bg-neutral-200" />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              <div className="w-[30%]">
                <Button
                  size={"md"}
                  iconPosition={"off"}
                  variant={"solid"}
                  color={
                    companiesState.importCSVStatus === "successfully"
                      ? "green"
                      : "red"
                  }
                  fullWidth
                  label="OK"
                  onClick={() => {
                    dispatch(setImportCSVStatus("idle"));
                    dispatch(setImportCSVSResponse(undefined));
                  }}
                />
              </div>
            </>
        }
      </div>
    </div>
  );
}
