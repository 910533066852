import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatDate } from "date-fns";
import { emailRegex, phoneRegex } from "../../../lib/regex";
import { PromiseStatuses } from "../../../lib/types";
import { downloadCSVfromBase64 } from "../../../lib/utils";
import { ImportCSVResponseDTO } from "../dto";
import {
  AssociatePersonToCompany,
  EditPersonRequest,
  FindAllPeopleFilters,
  FindAllPeopleResponse,
  NewPersonRequest,
  PersonDTO,
} from "./dto";
import { NewPeopleService } from "./service";

interface PeopleState {
  findAllStatus: PromiseStatuses;
  findAllResponse?: FindAllPeopleResponse;
  filters: FindAllPeopleFilters;
  deletePersonStatus: PromiseStatuses;
  idToDelete?: string;
  findPersonStatus: PromiseStatuses;
  findPersonResponse?: PersonDTO;
  editPersonRequest: EditPersonRequest;
  editPersonStatus: PromiseStatuses;
  selectedPerson?: string;
  personToAddCompany?: PersonDTO;
  importCSVStatus: PromiseStatuses;
  importCSVResponse?: ImportCSVResponseDTO;
  getCSVStatus: PromiseStatuses;
  errors: {
    validateAssociate: {
      name: boolean;
      phone: boolean;
      vat: boolean;
      id: boolean;
      status: PromiseStatuses;
    };
    validateCreate: {
      name: boolean
      surname: boolean,
      email: boolean,
      phone: boolean
      status: PromiseStatuses
    }
  };
  associatePersonToCompanyStatus: PromiseStatuses;
  selectedIds: string[];
  showPopup: boolean;
  tabValueDetailsPerson: 0 | 1 | 2
  personCountStatus: PromiseStatuses
  personCountResponse: { toActivate: number }
  newPersonRequest: NewPersonRequest
  newPersonStatus: PromiseStatuses
  newPersonResponse?: {
    id: string,
    alreadyExists: boolean
  }
}

const initialState: PeopleState = {
  findAllStatus: "idle",
  filters: {
    order: true,
    sort: "name",
    itemsPerPage: 10,
    page: 0,
    name: [],
    project: [],
    atecos: [],
    sector: [],
    category: [],
    department: [],
    role: [],
    city: [],
    state: undefined,
    countryRegion: [],
    revenue: undefined,
    employees: undefined,
    smartphone: undefined,
    privacy: undefined,
    zipCode: [],
    targetList: [],
    outcome: [],
  },
  deletePersonStatus: "idle",
  findPersonStatus: "idle",
  editPersonRequest: {
    name: "",
    surname: "",
    role: "",
    department: "",
    privacy: false,
    phone: "",
    email: "",
    linkedinProfile: "",
    companyId: "",
  },
  editPersonStatus: "idle",
  importCSVStatus: "idle",
  errors: {
    validateAssociate: {
      name: false,
      phone: false,
      vat: false,
      id: false,
      status: "idle",
    },
    validateCreate: {
      name: false,
      surname: false,
      email: false,
      phone: false,
      status: 'idle'
    }
  },
  associatePersonToCompanyStatus: "idle",
  selectedIds: [],
  getCSVStatus: 'idle',
  showPopup: false,
  tabValueDetailsPerson: 0,
  personCountResponse: { toActivate: 0 },
  personCountStatus: 'idle',
  newPersonRequest: {
    name: "",
    surname: "",
    phone: "",
    email: "",
    linkedinProfile: "",
    privacy: false,
    companyName: "",
    companyVat: "",
    companyPhone: []
  },
  newPersonStatus: 'idle'
};

export const findAllPeople = createAsyncThunk(
  "people/findAllPeople",
  async (request: FindAllPeopleFilters): Promise<FindAllPeopleResponse> => {
    const PeopleService = NewPeopleService();

    return PeopleService.findAllPeople(request);
  }
);

export const personCount = createAsyncThunk(
  "people/personCount",
  async (): Promise<{ toActivate: number }> => {
    const PeopleService = NewPeopleService();

    return PeopleService.personCount();
  }
);

export const getCSVPeople = createAsyncThunk(
  "people/getCSVPeople",
  async (request: string[]): Promise<string> => {
    const PeopleService = NewPeopleService();

    return PeopleService.getCSV(request);
  }
);

export const createPerson = createAsyncThunk(
  "people/createPerson",
  async (request: NewPersonRequest): Promise<{
    id: string,
    alreadyExists: boolean
  }> => {
    const PeopleService = NewPeopleService();

    return PeopleService.createPerson(request);
  }
);

export const deletePerson = createAsyncThunk(
  "people/deletePerson",
  async (id: string[]): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.deletePerson(id);
  }
);

export const findPerson = createAsyncThunk(
  "people/findPerson",
  async (id: string): Promise<PersonDTO> => {
    const PeopleService = NewPeopleService();

    return PeopleService.findPersonById(id);
  }
);

export const editPerson = createAsyncThunk(
  "people/editPerson",
  async (request: { id: string; data: EditPersonRequest }): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.updatePerson(request.id, request.data);
  }
);

export const importCSV = createAsyncThunk(
  "people/importCSV",
  async (request: FormData): Promise<ImportCSVResponseDTO> => {
    const PeopleService = NewPeopleService();

    return PeopleService.importContact(request);
  }
);

export const associatePersonToCompany = createAsyncThunk(
  "people/associatePersonToCompany",
  async (request: {
    data: AssociatePersonToCompany;
    personId: string;
  }): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.associateCompany(request.data, request.personId);
  }
);

export const validateAssociatePersonToCompany = createAsyncThunk(
  "people/validateAssociatePersonToCompany",
  async (
    request: { body: AssociatePersonToCompany; notFound: boolean },
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    const body = request.body;

    thunkApi.dispatch(setErrorAssociateName(false));
    thunkApi.dispatch(setErrorAssociatePhone(false));
    thunkApi.dispatch(setErrorAssociateId(false));
    thunkApi.dispatch(setErrorAssociateVat(false));
    thunkApi.dispatch(setErrorAssociateStatus("idle"));

    if (
      !request.notFound &&
      (body.companyId === undefined ||
        body.companyId === "" ||
        !(body.companyId.length > 0))
    ) {
      thunkApi.dispatch(setErrorAssociateId(true));
      isValid = false;
    } else if (request.notFound) {
      if (body.companyName === "" || !body.companyName) {
        thunkApi.dispatch(setErrorAssociateName(true));
        isValid = false;
      }

      if (
        body.companyVat &&
        (body.companyVat.length < 11 || body.companyVat.length > 16)
      ) {
        thunkApi.dispatch(setErrorAssociateVat(true));
        isValid = false;
      }

      if (
        !(body.companyPhone.length > 0) ||
        body.companyPhone.some((phone) => !phoneRegex.test(phone))
      ) {
        thunkApi.dispatch(setErrorAssociatePhone(true));
        isValid = false;
      }
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

export const validateCreatePerson = createAsyncThunk(
  "people/validateCreatePerson",
  async (
    request: NewPersonRequest,
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateCreatePersonName(false));
    thunkApi.dispatch(setValidateCreatePersonSurname(false));
    thunkApi.dispatch(setValidateCreatePersonEmail(false));
    thunkApi.dispatch(setValidateCreatePersonPhone(false));
    thunkApi.dispatch(setValidateCreatePersonStatus("idle"));

    if (!request.name || request.name === '') {
      thunkApi.dispatch(setValidateCreatePersonName(true));
      isValid = false;
    }

    if (!request.surname || request.surname === '') {
      thunkApi.dispatch(setValidateCreatePersonSurname(true));
      isValid = false;
    }


    if (request.phone && !phoneRegex.test(request.phone)) {
      thunkApi.dispatch(setValidateCreatePersonPhone(true));
      isValid = false;
    }

    if (request.email && !emailRegex.test(request.email)) {
      thunkApi.dispatch(setValidateCreatePersonEmail(true));
      isValid = false;
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

const peopleSlice = createSlice({
  name: "people/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllPeople.pending, (state) => {
        state.findAllStatus = "loading";
      })
      .addCase(findAllPeople.fulfilled, (state, action) => {
        state.findAllStatus = "successfully";
        state.findAllResponse = action.payload;
      })
      .addCase(findAllPeople.rejected, (state) => {
        state.findAllStatus = "failed";
      })
      .addCase(personCount.pending, (state) => {
        state.personCountStatus = "loading";
      })
      .addCase(personCount.fulfilled, (state, action) => {
        state.personCountStatus = "successfully";
        state.personCountResponse = action.payload;
      })
      .addCase(personCount.rejected, (state) => {
        state.personCountStatus = "failed";
      })
      .addCase(deletePerson.pending, (state) => {
        state.deletePersonStatus = "loading";
      })
      .addCase(deletePerson.fulfilled, (state, action) => {
        state.deletePersonStatus = "successfully";
      })
      .addCase(deletePerson.rejected, (state) => {
        state.deletePersonStatus = "failed";
      })
      .addCase(findPerson.pending, (state) => {
        state.findPersonStatus = "loading";
      })
      .addCase(findPerson.fulfilled, (state, action) => {
        state.findPersonStatus = "successfully";
        state.findPersonResponse = action.payload;
      })
      .addCase(findPerson.rejected, (state) => {
        state.findPersonStatus = "failed";
      })
      .addCase(editPerson.pending, (state) => {
        state.editPersonStatus = "loading";
      })
      .addCase(editPerson.fulfilled, (state) => {
        state.editPersonStatus = "successfully";
      })
      .addCase(editPerson.rejected, (state) => {
        state.editPersonStatus = "failed";
      })
      .addCase(importCSV.pending, (state) => {
        state.importCSVStatus = "loading";
      })
      .addCase(importCSV.fulfilled, (state, action) => {
        state.importCSVStatus = "successfully";
        state.importCSVResponse = action.payload;
      })
      .addCase(importCSV.rejected, (state) => {
        state.importCSVStatus = "failed";
      })
      .addCase(validateAssociatePersonToCompany.pending, (state) => {
        state.errors.validateAssociate.status = "loading";
      })
      .addCase(validateAssociatePersonToCompany.fulfilled, (state) => {
        state.errors.validateAssociate.status = "successfully";
      })
      .addCase(validateAssociatePersonToCompany.rejected, (state) => {
        state.errors.validateAssociate.status = "failed";
      })
      .addCase(associatePersonToCompany.pending, (state) => {
        state.associatePersonToCompanyStatus = "loading";
      })
      .addCase(associatePersonToCompany.fulfilled, (state) => {
        state.associatePersonToCompanyStatus = "successfully";
      })
      .addCase(associatePersonToCompany.rejected, (state) => {
        state.associatePersonToCompanyStatus = "failed";
      })
      .addCase(getCSVPeople.pending, (state) => {
        state.getCSVStatus = "loading";
      })
      .addCase(getCSVPeople.fulfilled, (state, action) => {
        downloadCSVfromBase64(action.payload, "cd_people_download_" + formatDate(new Date(), "dd_MM_yyyy"))
        state.getCSVStatus = "successfully";
        state.selectedIds = []
      })
      .addCase(getCSVPeople.rejected, (state) => {
        state.getCSVStatus = "failed";
      })
      .addCase(validateCreatePerson.pending, (state) => {
        state.errors.validateCreate.status = "loading";
      })
      .addCase(validateCreatePerson.fulfilled, (state, action) => {
        state.errors.validateCreate.status = "successfully";
      })
      .addCase(validateCreatePerson.rejected, (state) => {
        state.errors.validateCreate.status = "failed";
      })
      .addCase(createPerson.pending, (state) => {
        state.newPersonStatus = "loading";
      })
      .addCase(createPerson.fulfilled, (state, action) => {
        state.newPersonStatus = "successfully";
        state.newPersonResponse = action.payload
      })
      .addCase(createPerson.rejected, (state) => {
        state.newPersonStatus = "failed";
      });
  },
  reducers: {
    setFindAllPeopleStatus: (state, action) => {
      state.findAllStatus = action.payload;
    },
    setFindAllPeopleFiltersOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setFindAllPeopleFiltersSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setFindAllPeopleFiltersItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setFindAllPeopleFiltersPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setDeletePersonStatus: (state, action) => {
      state.deletePersonStatus = action.payload;
    },
    setIdToDeletePerson: (state, action) => {
      state.idToDelete = action.payload;
    },
    setFindPersonResponse: (state, action) => {
      state.findPersonResponse = action.payload;
    },
    setFindPersonStatus: (state, action) => {
      state.findPersonStatus = action.payload;
    },
    setEditPersonName: (state, action) => {
      state.editPersonRequest.name = action.payload;
    },
    setEditPersonCompanyId: (state, action) => {
      state.editPersonRequest.companyId = action.payload;
    },
    setEditPersonSurname: (state, action) => {
      state.editPersonRequest.surname = action.payload;
    },
    setEditPersonRole: (state, action) => {
      state.editPersonRequest.role = action.payload;
    },
    setEditPersonDepartment: (state, action) => {
      state.editPersonRequest.department = action.payload;
    },
    setEditPersonPhone: (state, action) => {
      state.editPersonRequest.phone = action.payload;
    },
    setEditPersonEmail: (state, action) => {
      state.editPersonRequest.email = action.payload;
    },
    setEditPersonLinkedinProfile: (state, action) => {
      state.editPersonRequest.linkedinProfile = action.payload;
    },
    setEditPersonPrivacy: (state, action) => {
      state.editPersonRequest.privacy = action.payload;
    },
    setSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },
    setEditPersonStatus: (state, action) => {
      state.editPersonStatus = action.payload;
    },
    setFindAllPeopleFiltersName: (state, action) => {
      state.filters.name = action.payload;
    },
    setFindAllPeopleFiltersAtecos: (state, action) => {
      state.filters.atecos = action.payload;
    },
    setFindAllPeopleFiltersTargetList: (state, action) => {
      state.filters.targetList = action.payload;
    },
    setFindAllPeopleFiltersOutcome: (state, action) => {
      state.filters.outcome = action.payload;
    },
    setFindAllPeopleFiltersRevenue: (state, action) => {
      state.filters.revenue = action.payload;
    },
    setFindAllPeopleFiltersEmployees: (state, action) => {
      state.filters.employees = action.payload;
    },
    setFindAllPeopleFiltersSector: (state, action) => {
      state.filters.sector = action.payload;
    },
    setFindAllPeopleFiltersCategory: (state, action) => {
      state.filters.category = action.payload;
    },
    setFindAllPeopleFiltersDepartment: (state, action) => {
      state.filters.department = action.payload;
    },
    setFindAllPeopleFiltersRole: (state, action) => {
      state.filters.role = action.payload;
    },
    setFindAllPeopleFiltersSmartphone: (state, action) => {
      state.filters.smartphone = action.payload;
    },
    setFindAllPeopleFiltersPrivacy: (state, action) => {
      state.filters.privacy = action.payload;
    },
    setFindAllPeopleFiltersState: (state, action) => {
      state.filters.state = action.payload;
    },
    setFindAllPeopleFiltersCountryRegion: (state, action) => {
      state.filters.countryRegion = action.payload;
    },
    setFindAllPeopleFiltersCity: (state, action) => {
      state.filters.city = action.payload;
    },
    setFindAllPeopleFiltersZipCode: (state, action) => {
      state.filters.zipCode = action.payload;
    },
    setFindAllPeopleFiltersProject: (state, action) => {
      state.filters.project = action.payload;
    },
    setFindAllPeopleFiltersSearch: (state, action) => {
      state.filters.personName = action.payload;
    },
    setFindAllPeopleFiltersPersonEmail: (state, action) => {
      state.filters.personEmail = action.payload;
    },
    setFindAllPeopleFiltersHasCompany: (state, action) => {
      state.filters.hasCompany = action.payload;
    },
    setFindAllPeopleFiltersActive: (state, action) => {
      state.filters.active = action.payload;
    },
    setImportCSVStatus: (state, action) => {
      state.importCSVStatus = action.payload;
    },
    setImportCSVSResponse: (state, action) => {
      state.importCSVResponse = action.payload;
    },
    setPersonToAddCompany: (state, action) => {
      state.personToAddCompany = action.payload;
    },
    setErrorAssociateName: (state, action) => {
      state.errors.validateAssociate.name = action.payload;
    },
    setErrorAssociatePhone: (state, action) => {
      state.errors.validateAssociate.phone = action.payload;
    },
    setErrorAssociateVat: (state, action) => {
      state.errors.validateAssociate.vat = action.payload;
    },
    setErrorAssociateStatus: (state, action) => {
      state.errors.validateAssociate.status = action.payload;
    },
    setErrorAssociateId: (state, action) => {
      state.errors.validateAssociate.id = action.payload;
    },
    setAssociatePersonToCompanyStatus: (state, action) => {
      state.associatePersonToCompanyStatus = action.payload;
    },
    setSelectedIdsPeople: (state, action) => {
      state.selectedIds = action.payload
    },
    setShowPopupPeople: (state, action) => {
      state.showPopup = action.payload
    },
    setTabValueDetailsPerson: (state, action) => {
      state.tabValueDetailsPerson = action.payload
    },
    setPersonCountStatus: (state, action) => {
      state.personCountStatus = action.payload
    },
    setPersonCountResponse: (state, action) => {
      state.personCountResponse = action.payload
    },
    setValidateCreatePersonName: (state, action) => {
      state.errors.validateCreate.name = action.payload
    },
    setValidateCreatePersonSurname: (state, action) => {
      state.errors.validateCreate.surname = action.payload
    },
    setValidateCreatePersonEmail: (state, action) => {
      state.errors.validateCreate.email = action.payload
    },
    setValidateCreatePersonPhone: (state, action) => {
      state.errors.validateCreate.phone = action.payload
    },
    setValidateCreatePersonStatus: (state, action) => {
      state.errors.validateCreate.status = action.payload
    },
    setNewPersonStatus: (state, action) => {
      state.newPersonStatus = action.payload
    },
    setNewPersonRequestCompanyName: (state, action) => {
      state.newPersonRequest.companyName = action.payload
    },
    setNewPersonRequestCompanyPhone: (state, action) => {
      state.newPersonRequest.companyPhone = action.payload
    },
    setNewPersonRequestCompanyVat: (state, action) => {
      state.newPersonRequest.companyVat = action.payload
    },
    setNewPersonRequestEmail: (state, action) => {
      state.newPersonRequest.email = action.payload
    },
    setNewPersonRequestLinkedinProfile: (state, action) => {
      state.newPersonRequest.linkedinProfile = action.payload
    },
    setNewPersonRequestName: (state, action) => {
      state.newPersonRequest.name = action.payload
    },
    setNewPersonRequestPhone: (state, action) => {
      state.newPersonRequest.phone = action.payload
    },
    setNewPersonRequestPrivacy: (state, action) => {
      state.newPersonRequest.privacy = action.payload
    },
    setNewPersonRequestRole: (state, action) => {
      state.newPersonRequest.role = action.payload
    },
    setNewPersonRequestSurname: (state, action) => {
      state.newPersonRequest.surname = action.payload
    },
    setNewPersonResponse: (state, action) => {
      state.newPersonResponse = action.payload
    }
  },
});

export const {
  setFindAllPeopleStatus,
  setFindAllPeopleFiltersItemsPerPage,
  setFindAllPeopleFiltersOrder,
  setFindAllPeopleFiltersPage,
  setFindAllPeopleFiltersSort,
  setDeletePersonStatus,
  setIdToDeletePerson,
  setFindPersonResponse,
  setFindPersonStatus,
  setEditPersonDepartment,
  setEditPersonEmail,
  setEditPersonLinkedinProfile,
  setEditPersonName,
  setEditPersonPhone,
  setEditPersonRole,
  setEditPersonSurname,
  setEditPersonPrivacy,
  setSelectedPerson,
  setEditPersonStatus,
  setEditPersonCompanyId,
  setFindAllPeopleFiltersName,
  setFindAllPeopleFiltersRevenue,
  setFindAllPeopleFiltersEmployees,
  setFindAllPeopleFiltersAtecos,
  setFindAllPeopleFiltersSector,
  setFindAllPeopleFiltersActive,
  setFindAllPeopleFiltersCategory,
  setFindAllPeopleFiltersDepartment,
  setFindAllPeopleFiltersRole,
  setFindAllPeopleFiltersPrivacy,
  setFindAllPeopleFiltersSmartphone,
  setFindAllPeopleFiltersState,
  setFindAllPeopleFiltersCity,
  setFindAllPeopleFiltersCountryRegion,
  setFindAllPeopleFiltersZipCode,
  setImportCSVStatus,
  setImportCSVSResponse,
  setPersonToAddCompany,
  setErrorAssociateName,
  setErrorAssociatePhone,
  setErrorAssociateStatus,
  setErrorAssociateVat,
  setErrorAssociateId,
  setAssociatePersonToCompanyStatus,
  setFindAllPeopleFiltersSearch,
  setFindAllPeopleFiltersHasCompany,
  setFindAllPeopleFiltersPersonEmail,
  setFindAllPeopleFiltersTargetList,
  setFindAllPeopleFiltersOutcome,
  setFindAllPeopleFiltersProject,
  setSelectedIdsPeople,
  setTabValueDetailsPerson,
  setShowPopupPeople,
  setPersonCountResponse,
  setPersonCountStatus,
  setValidateCreatePersonName,
  setValidateCreatePersonStatus,
  setValidateCreatePersonSurname,
  setNewPersonRequestCompanyName,
  setNewPersonRequestCompanyPhone,
  setNewPersonRequestCompanyVat,
  setNewPersonRequestEmail,
  setNewPersonRequestLinkedinProfile,
  setNewPersonRequestName,
  setNewPersonRequestPhone,
  setNewPersonRequestPrivacy,
  setNewPersonRequestRole,
  setNewPersonRequestSurname,
  setNewPersonStatus,
  setValidateCreatePersonEmail,
  setValidateCreatePersonPhone,
  setNewPersonResponse
} = peopleSlice.actions;

export default peopleSlice.reducer;
