import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { PhoneCallIcon } from "../../../ui/icons/phoneCall"
import { XCircleIcon } from "../../../ui/icons/xCircle"
import Button from "../../../ui/molecules/button"
import Input from "../../../ui/molecules/input"
import { setEditCompanyPhone } from "./slice"

export function CompanyPhones() {
    const companiesState = useAppSelector(state => state.companies)
    const dispatch = useAppDispatch()

    return (
        <>
            {
                companiesState.editCompanyRequest.phone.map((phone, index) => (
                    <div className="flex items-end">
                        <Input
                            label="Telefono"
                            disabled={companiesState.findCompanyResponse?.active}
                            endIcon={<PhoneCallIcon color={""} size={0} />}
                            value={phone}
                            onChangeText={(text) => {
                                const phones = [...companiesState.editCompanyRequest.phone]
                                phones[index] = text
                                dispatch(setEditCompanyPhone([...phones]))
                            }}
                        />
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            icon={<XCircleIcon color={""} size={0} />}
                            variant={"ghost"}
                            color={"blue"}
                            onClick={() => {
                                const phones = [...companiesState.editCompanyRequest.phone]
                                const phoneRequest = [...phones.slice(0, index), ...phones.slice(index + 1)]
                                dispatch(setEditCompanyPhone([...phoneRequest]))
                            }}
                        />
                    </div>
                ))
            }
            <div className="flex items-end justify-end">
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"link"}
                    color={"blue"}
                    label="Aggiungi numero di telefono"
                    onClick={() => {
                        dispatch(setEditCompanyPhone([...companiesState.editCompanyRequest.phone, '']))
                    }}
                />
            </div>
        </>
    )
}