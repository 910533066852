import { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { targertListsStatePillMap, TargetListDTO } from "./dto";
import { setEditTargetListTabValue, setSelectedTargetList } from "./slice";

interface Props {
  column: string;
  target: TargetListDTO;
}
export function Cell(props: Props) {
  const { column, target } = props;
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch()

  return new Map<string, ReactElement>([
    [
      "Nome",
      <td
        onClick={() => {
          dispatch(setSelectedTargetList(target.id))
          dispatch(setEditTargetListTabValue(0))
        }
        }
        className="text-text-sm cursor-pointer crop-text max-w-[200px] font-semibold text-neutral-600">
        {target.name}
      </td>,
    ],
    [
      "Team Leader",
      <td className="text-text-sm text-neutral-600 flex items-center gap-2 h-14">
        <Avatar
          type="single"
          size="sm"
          shape="circle"
          imageUrl={
            usersState.findUsersAvatarsListResponse.find(
              (avatar) => avatar.userId === target.project?.teamLeader.id
            )?.objectId ?? ""
          }
          altTextInitials={
            target.project.teamLeader.name[0] +
            target.project.teamLeader.surname[0]
          }
        />
        <span>
          {(target.project.teamLeader.name ?? "-") +
            " " +
            (target.project.teamLeader.surname ?? "-")}
        </span>
      </td>,
    ],
    [
      "Commerciali",
      <td className="text-text-sm text-neutral-600">
        <Avatar
          type="group"
          size="sm"
          length={2}
          imageUrls={target.commercials.map((commercial) => ({
            altTextInitials: commercial.name[0] + commercial.surname[0],
            url:
              usersState.findUsersAvatarsListResponse.find(
                (avatar) => avatar.userId === commercial.id
              )?.objectId ?? "",
          }))}
        />
      </td>,
    ],
    [
      "Settore",
      <td className="max-w-[100px] text-text-sm text-neutral-600">
        {
          target.atecos &&
            target.atecos.map(ateco => ateco.sector) &&
            target.atecos.map(ateco => ateco.sector).length > 0 ?
            <Pills
              size={"xs"}
              label={
                target.atecos.map(atecos => atecos.sector)[0] +
                (target.atecos.map(atecos => atecos.sector).length > 1
                  ? " +" + (target.atecos.length - 1)
                  : "")
              }
              outline={false}
              emphasis={false}
              color={"blue"}
            /> :
            "-"
        }
      </td>,
    ],
    [
      "Contatti",
      <td className="text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.contacts?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Lead",
      <td className="text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.leads?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Da lavorare",
      <td className="text-text-sm text-neutral-600">
        <div className="flex items-center justify-center">
          <Pills
            size={"xs"}
            label={target.drafts?.toString() ?? "0"}
            outline={false}
            emphasis={false}
            color={"blue"}
          />
        </div>
      </td>,
    ],
    [
      "Stato",
      <td className="text-text-sm text-neutral-600">
        <Pills
          size={"xs"}
          label={targertListsStatePillMap.get(target.state)?.label!}
          outline={false}
          emphasis={false}
          color={targertListsStatePillMap.get(target.state)?.color!}
        />
      </td>,
    ],
  ]).get(column)!;
}
