import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { comuneCapMap } from "../../../lib/places/cap";
import { regions } from "../../../lib/places/italianRegions";
import { provinceComuniMap } from "../../../lib/places/listaComuni";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { OptionType } from "../../../lib/types";
import { groupBy } from "../../../lib/utils";
import { GlobeIcon } from "../../../ui/icons/globe";
import { MailIcon } from "../../../ui/icons/mail";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import Toggle from "../../../ui/molecules/toggle";
import { findTargetListById, setChangeContactStateStatus } from "../../targetList/slice";
import { PlaceFeatureDTO } from "../dto";
import { CompanyPhones } from "./companyPhones";
import {
  editCompany,
  setEditCompanyAddress,
  setEditCompanyAtecos,
  setEditCompanyCap,
  setEditCompanyCity,
  setEditCompanyEmail,
  setEditCompanyEmployeesNumber,
  setEditCompanyName,
  setEditCompanyPhone,
  setEditCompanyProvince,
  setEditCompanyRegion,
  setEditCompanyRevenue,
  setEditCompanyState,
  setEditCompanyVat,
  setEditCompanyWebSite,
  setSelectedCompany
} from "./slice";

export function EditCompanyForm() {
  const companiesState = useAppSelector((state) => state.companies);
  const preferencesState = useAppSelector((state) => state.preferences);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  const defaultAtecos = preferencesState.findAllAtecosResponse.map((sector) => ({ label: sector.atecoCode + ' - ' + sector.atecoDescription, value: sector.atecoCode }))

  const defaultSectors = groupBy(
    preferencesState.findAllAtecosResponse,
    "sector"
  ).map((sector) => ({ label: sector.key, value: sector.key }))

  const defaultCategories = groupBy(
    preferencesState.findAllAtecosResponse
    , "category"
  ).map((category) => ({ label: category.key, value: category.key }))

  const [options, setOptions] = useState<{ ateco: OptionType[], sector: OptionType[], category: OptionType[] }>({ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories })

  useEffect(() => {
    setOptions({ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories })
  }, [])

  function getFilteredOptions(category: string | null, sector: string | null) {
    const filteredAtecos = preferencesState.findAllAtecosResponse.filter((ateco) => {
      return (
        (!category || ateco.category === category) &&
        (!sector || ateco.sector === sector)
      );
    });

    const atecoOptions = filteredAtecos.map((ateco) => ({
      label: ateco.atecoCode + ' - ' + ateco.atecoDescription,
      value: ateco.atecoCode,
    }));

    const sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
      label: sector.key,
      value: sector.key,
    }));

    const categoryOptions = groupBy(filteredAtecos, "category").map((category) => ({
      label: category.key,
      value: category.key,
    }));

    return { atecoOptions, sectorOptions, categoryOptions };
  }

  function handleChangeAtecos(e: string | undefined) {
    const selectedAteco = preferencesState.findAllAtecosResponse.find(
      (ateco) => ateco.atecoCode === e
    );

    if (selectedAteco) {
      const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]

      atecos[0] = { atecoCode: selectedAteco.atecoCode, sector: selectedAteco.sector, category: selectedAteco.category }
      dispatch(setEditCompanyAtecos([...atecos]))
    } else {
      dispatch(setEditCompanyAtecos([{ atecoCode: null, sector: null, category: null, atecoDescription: null }]))
    }

    const { atecoOptions, sectorOptions, categoryOptions } = getFilteredOptions(
      selectedAteco?.category ?? null,
      selectedAteco?.sector ?? null
    );

    setOptions({ ateco: atecoOptions, sector: sectorOptions, category: categoryOptions });
  }

  function handleChangeSectors(e: string | undefined) {
    const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
    const selectedSector = e || null;

    atecos[0] = {
      ...atecos[0],
      sector: selectedSector,
      atecoCode: null,
    };

    const { atecoOptions, categoryOptions } = e
      ? getFilteredOptions(atecos[0].category, selectedSector)
      : { atecoOptions: defaultAtecos, categoryOptions: defaultCategories };


    setOptions({ ...options, ateco: atecoOptions, category: categoryOptions });

    dispatch(setEditCompanyAtecos([...atecos]));
  }

  function handleChangeCategories(e: string | undefined) {
    const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
    const selectedCategory = e || null;

    atecos[0] = {
      ...atecos[0],
      category: selectedCategory,
      atecoCode: null,
    };

    let atecoOptions, sectorOptions;

    if (selectedCategory === null) {
      atecoOptions = defaultAtecos;
      sectorOptions = defaultSectors;

      if (!defaultSectors.map((sector) => sector.value).includes(atecos[0].sector!)) {
        atecos[0].sector = null;
      }
    } else {
      const filteredAtecos = preferencesState.findAllAtecosResponse.filter(
        (ateco) => ateco.category === selectedCategory
      );
      atecoOptions = groupBy(filteredAtecos, "atecoCode").map((ateco) => ({
        label: ateco.key,
        value: ateco.key,
      }));
      sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
        label: sector.key,
        value: sector.key,
      }));

      if (!sectorOptions.map((sector) => sector.value).includes(atecos[0].sector!)) {
        atecos[0].sector = null;
      }
    }

    setOptions({
      ...options,
      ateco: atecoOptions,
      sector: sectorOptions,
    });

    dispatch(setEditCompanyAtecos([...atecos]));
  }

  const [isoCountries, setIsoCountries] = useState<
    { value: string; code: string }[]
  >([
    { value: "Italia", code: "IT" },
    { value: "Francia", code: "FR" },
    { value: "Germania", code: "DE" },
    { value: "Stati Uniti d'America", code: "US" },
    { value: "Regno Unito", code: "UK" },
  ]);

  const defaultOptions = [
    { label: "Italia", value: "Italia" },
    { label: "Francia", value: "Francia" },
    { label: "Germania", value: "Germania" },
    { label: "Stati Uniti d'America", value: "Stati Uniti" },
    { label: "Regno Unito", value: "Regno Unito" },
  ];

  const fetchCountries = async (
    inputValue: string
  ): Promise<{ value: string; label: string }[]> => {
    if (!inputValue) {
      return defaultOptions;
    }

    try {
      const response = await axios.get(
        "https://api.mapbox.com/search/geocode/v6/forward",
        {
          params: {
            q: inputValue,
            access_token:
              "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
            types: ["country"],
            language: "it",
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }
      );

      const countries = response.data.features.map(
        (feature: PlaceFeatureDTO) => ({
          label: feature.properties.name,
          value: feature.properties.name,
        })
      );

      setIsoCountries(
        response.data.features.map((feature: PlaceFeatureDTO) => ({
          code: feature.properties.context.country.country_code,
          value: feature.properties.name,
        }))
      );

      return countries;
    } catch (error) {
      console.error("Errore durante il fetch delle nazioni: ", error);
      return [];
    }
  };

  const fetchCities = async (
    inputValue: string
  ): Promise<{ value: string; label: string }[]> => {
    if (!inputValue) {
      return defaultOptions;
    }

    try {
      const response = await axios.get(
        "https://api.mapbox.com/search/geocode/v6/forward",
        {
          params: {
            q: inputValue,
            access_token:
              "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
            types: ["place"],
            language: "it",
            country: isoCountries.find(
              (country) =>
                country.value === companiesState.editCompanyRequest.state
            )?.code,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: false,
            });
          },
        }
      );

      const countries = response.data.features.map(
        (feature: PlaceFeatureDTO) => ({
          label: feature.properties.name,
          value: feature.properties.name,
        })
      );

      return countries;
    } catch (error) {
      console.error("Errore durante il fetch delle nazioni: ", error);
      return [];
    }
  };

  const provinceFilters = regions
    .filter(
      (region) =>
        companiesState.editCompanyRequest.countryRegion === region.name
    )
    .flatMap((regions) => regions.provinces);

  const comuni = () => {
    let options: { value: string; label: string }[] = [];
    provinceComuniMap
      .get(companiesState.editCompanyRequest.province ?? "")
      ?.forEach((comune) => {
        options.push({ label: comune, value: comune });
      });

    return options.sort((a, b) => a.value.localeCompare(b.value));
  };

  const cap = () => {
    let options: { value: string; label: string }[] = [];

    comuneCapMap
      .get(companiesState.editCompanyRequest.city ?? "")
      ?.forEach((comune) => {
        options.push({ label: comune, value: comune });
      });

    return options.sort((a, b) => a.value.localeCompare(b.value));
  };

  useEffect(() => {
    if (companiesState.findCompanyStatus === "successfully") {
      dispatch(setEditCompanyVat(companiesState.findCompanyResponse?.vat));
      dispatch(setEditCompanyName(companiesState.findCompanyResponse?.name));
      dispatch(setEditCompanyAtecos(companiesState.findCompanyResponse?.atecos))
      dispatch(
        setEditCompanyAddress(companiesState.findCompanyResponse?.address)
      );
      dispatch(
        setEditCompanyRegion(companiesState.findCompanyResponse?.countryRegion)
      );
      dispatch(setEditCompanyState(companiesState.findCompanyResponse?.state));
      dispatch(setEditCompanyCity(companiesState.findCompanyResponse?.city));
      dispatch(
        setEditCompanyProvince(companiesState.findCompanyResponse?.province)
      );
      dispatch(setEditCompanyCap(companiesState.findCompanyResponse?.zipCode));
      dispatch(
        setEditCompanyWebSite(companiesState.findCompanyResponse?.website)
      );
      dispatch(setEditCompanyEmail(companiesState.findCompanyResponse?.email));
      dispatch(
        setEditCompanyRevenue(companiesState.findCompanyResponse?.revenue)
      );
      dispatch(setEditCompanyPhone(companiesState.findCompanyResponse?.phone));
      dispatch(
        setEditCompanyEmployeesNumber(
          companiesState.findCompanyResponse?.employeesNumber
        )
      );
    }
  }, [companiesState.findCompanyStatus]);

  useEffect(() => {
    if (targetListState.changeContactStateStatus === "successfully") {
      dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      dispatch(setChangeContactStateStatus('idle'))
      dispatch(setSelectedCompany(undefined))
    }
  }, [targetListState.changeContactStateStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListState.changeContactStateStatus === "failed" ||
      targetListState.changeContactStateStatus === "successfully"
    ) {
      if (targetListState.changeContactStateStatus === "successfully") {
        dispatch(
          findTargetListById(targetListState.findTargetListResponse?.id!)
        );
        dispatch(setChangeContactStateStatus("idle"));
        dispatch(setSelectedCompany(companiesState.findCompanyResponse?.id));
      }
      timeoutId = setTimeout(() => {
        dispatch(setChangeContactStateStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.changeContactStateStatus]);

  return (
    <div key={0} className="px-6 flex flex-col gap-4 relative w-full">
      <Input
        label="P. IVA"
        defaultValue={companiesState.editCompanyRequest?.vat}
        value={companiesState.editCompanyRequest?.vat}
        disabled={companiesState.findCompanyResponse?.active}
        onChangeText={(text) => dispatch(setEditCompanyVat(text))}
      />
      <NumericFormat
        prefix="€ "
        value={companiesState.editCompanyRequest?.revenue}
        defaultValue={companiesState.editCompanyRequest?.revenue}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        label="Fatturato"
        disabled={companiesState.findCompanyResponse?.active}
        customInput={Input}
        id={"company-to-confirm-price"}
        key={"company-to-confirm-price"}
        placeholder={"Fatturato"}
        onValueChange={(e) => {
          dispatch(setEditCompanyRevenue(e.floatValue));
        }}
      />
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="cod. ateco"
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeAtecos(e)
          }}
          isClearable={true}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].atecoCode! : undefined}
          placeholder={"Cerca per ateco"}
          options={options.ateco}
          noOptionsMessage="Non sono presenti ateco."
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="settore"
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeSectors(e)
          }}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].sector! : undefined}
          placeholder={"Cerca per settore"}
          options={options.sector}
          noOptionsMessage="Non sono presenti settori."
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="categoria"
          menuPortalTargetIsNotBody
          disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeCategories(e)
          }}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].category! : undefined}
          placeholder={"Cerca per categoria"}
          options={options.category}
          noOptionsMessage="Non sono presenti categorie."
        />
      </div>
      <div>
        <Toggle
          checked={companiesState.editCompanyRequest.atecos && companiesState.editCompanyRequest.atecos[1] && companiesState.editCompanyRequest.atecos[1].atecoCode === '47.91.10'}
          onChange={function (value: boolean): void {
            const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
            const eCommerce = preferencesState.findAllAtecosResponse.find(ateco => ateco.atecoCode === '47.91.10')
            if (value) {
              atecos[1] = { atecoCode: eCommerce?.atecoCode!, sector: eCommerce?.sector!, category: eCommerce?.category! }
              dispatch(setEditCompanyAtecos([...atecos]))
            } else {
              dispatch(setEditCompanyAtecos([...atecos.filter((_, index) => index !== 1)]))
            }
          }}
          placeholder="Aggiungi categoria e-commerce"
        />
      </div>
      <Input
        label="Sede legale"
        value={companiesState.editCompanyRequest?.address}
        onChangeText={(text) => dispatch(setEditCompanyAddress(text))}
      />
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-[6px]">
          <SelectCustomAsync
            label="stato"
            loadOptions={fetchCountries}
            defaultOptions={defaultOptions}
            isClearable
            onChange={(e) => {
              //@ts-ignore
              dispatch(setEditCompanyState(e === null ? undefined : e.value));
              dispatch(setEditCompanyCity([]));
              dispatch(setEditCompanyRegion([]));
            }}
            noOptionsMessage="Stato non trovato"
            placeholder="Seleziona una nazione..."
            defaultValue={
              companiesState.editCompanyRequest.state
                ? {
                  label: companiesState.editCompanyRequest.state,
                  value: companiesState.editCompanyRequest.state,
                }
                : undefined
            }
          />
        </div>
        {companiesState.editCompanyRequest.state !== undefined &&
          companiesState.editCompanyRequest.state === "ITALIA" && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-[6px]">
                <SelectCustom
                  label="regione"
                  menuPortalTargetIsNotBody
                  onChange={(e) => {
                    if (e === undefined || typeof e === "string") {
                      dispatch(setEditCompanyRegion(e));
                    }
                  }}
                  defaultValue={companiesState.editCompanyRequest.countryRegion}
                  placeholder={"Seleziona una regione"}
                  options={regions.map((region) => ({
                    value: region.name,
                    label: region.name,
                  }))}
                />
              </div>
              {companiesState.editCompanyRequest.countryRegion && (
                <div className="flex flex-col gap-[6px]">
                  <SelectCustom
                    label="provincia"
                    menuPortalTargetIsNotBody
                    onChange={(e) => {
                      if (e === undefined || typeof e === "string") {
                        dispatch(setEditCompanyProvince(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.province}
                    placeholder={"Seleziona una provincia"}
                    options={provinceFilters.map((province) => ({
                      value: province.code,
                      label: province.name,
                    }))}
                  />
                </div>
              )}
              {companiesState.editCompanyRequest.province && (
                <div className="flex flex-col gap-[6px]">
                  <SelectCustom
                    menuPortalTargetIsNotBody
                    label="città"
                    onChange={(e) => {
                      if (e === undefined || typeof e === "string") {
                        dispatch(setEditCompanyCity(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.city}
                    placeholder={"Seleziona un comune"}
                    options={comuni()}
                  />
                </div>
              )}
              {companiesState.editCompanyRequest.city && (
                <div className="flex flex-col gap-[6px]">
                  <SelectCustom
                    menuPortalTargetIsNotBody
                    label="cap"
                    onChange={(e) => {
                      if (e === undefined || typeof e === "string") {
                        dispatch(setEditCompanyCap(e));
                      }
                    }}
                    defaultValue={companiesState.editCompanyRequest.zipCode?.toString()}
                    placeholder={"Seleziona un CAP"}
                    options={cap()}
                  />
                </div>
              )}
            </div>
          )}
        {companiesState.editCompanyRequest.state !== undefined &&
          companiesState.editCompanyRequest.state !== null &&
          companiesState.editCompanyRequest.state !== "Italia" && (
            <div className="flex flex-col gap-[6px]">
              <SelectCustomAsync
                loadOptions={fetchCities}
                defaultOptions={[]}
                isClearable
                label="città"
                defaultValue={
                  companiesState.editCompanyRequest.city
                    ? {
                      label: companiesState.editCompanyRequest.city,
                      value: companiesState.editCompanyRequest.city,
                    }
                    : undefined
                }
                onChange={(e) => {
                  //@ts-ignore
                  dispatch(setEditCompanyCity(e === null ? undefined : e.value)
                  );
                }}
                noOptionsMessage="Città non trovata"
                placeholder="Seleziona una città..."
              />
            </div>
          )}
      </div>
      <Input
        label="Sito web"
        endIcon={<GlobeIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.website}
        onChangeText={(text) => dispatch(setEditCompanyWebSite(text))}
      />
      <Input
        label="Email"
        endIcon={<MailIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.email}
        onChangeText={(text) => dispatch(setEditCompanyEmail(text))}
      />
      <CompanyPhones />
    </div>
  );
}
