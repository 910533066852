import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OptionType, PromiseStatuses } from "../../lib/types";
import { FindAllCompaniesFilters } from "../database/companies/dto";
import { ImportCSVResponseDTO } from "../database/dto";
import { FindAllPeopleFilters } from "../database/people/dto";
import {
  ContactStateEnum,
  EditTargetListRequest,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  NoteDTO,
  TargetListCounterDTO,
  TargetListDTO,
  TargetListPageEnum,
  TargetListStatusEnum,
} from "./dto";
import { NewTargetListsService } from "./service";

interface TargetListState {
  editTargetListAtecosOptions: { ateco: OptionType[], sector: OptionType[], category: OptionType[] }[]
  newTargetListRequest: NewTargetListRequest;
  newTargetListStatus: PromiseStatuses;
  selectedTargetList?: string;
  findTargetListStatus: PromiseStatuses;
  findTargetListResponse?: TargetListDTO;
  targetListToCopy?: string
  targetListPage: TargetListPageEnum;
  importPersonStatus: PromiseStatuses;
  importPersonResponse?: ImportCSVResponseDTO;
  importCompanyStatus: PromiseStatuses;
  importCompanyResponse?: ImportCSVResponseDTO;
  findAllTargetListsResponse?: FindAllTargetListsResponse;
  findAllTargetListsStatus: PromiseStatuses;
  filters: FindAllTargetListsFilters;
  deleteTargetListStatus: PromiseStatuses;
  removeContactsStatus: PromiseStatuses;
  addContactsStatus: PromiseStatuses;
  changeContactStateStatus: PromiseStatuses;
  changeStateStatus: PromiseStatuses;
  editTargetListRequest: EditTargetListRequest
  editTargetListStatus: PromiseStatuses
  option?: "columns";
  optionsActive: boolean;
  optionPeople?: "columns";
  optionsPeopleActive: boolean;
  optionCompanies?: "columns";
  optionsCompaniesActive: boolean;
  validateNewTargetList: {
    errors: {
      name: boolean;
      departments: boolean;
      roles: boolean;
      sectors: boolean;
      categories: boolean;
      commercials: boolean;
      revenueSign: boolean;
      revenueFirst: boolean;
      revenueSecond: boolean;
      employeesSign: boolean;
      employeesFirst: boolean;
      employeesSecond: boolean;
    };
    status: PromiseStatuses;
  };
  validateEditTargetList: {
    errors: {
      name: boolean;
      departments: boolean;
      roles: boolean;
      sectors: boolean;
      categories: boolean;
      commercials: boolean;
      revenueSign: boolean;
      revenueFirst: boolean;
      revenueSecond: boolean;
      employeesSign: boolean;
      employeesFirst: boolean;
      employeesSecond: boolean;
    };
    status: PromiseStatuses;
  };
  copyTargetListStatus: PromiseStatuses
  copiedTargetList: string
  getTargetListCounterStatus: PromiseStatuses
  getTargetListCounterResponse?: TargetListCounterDTO
  editTargetListTabValue: number
  addPeopleBulkStatus: PromiseStatuses
  addCompaniesBulkStatus: PromiseStatuses
  updateNotesStatus: PromiseStatuses
}

const initialState: TargetListState = {
  editTargetListAtecosOptions: [],
  addPeopleBulkStatus: 'idle',
  addCompaniesBulkStatus: 'idle',
  optionsActive: false,
  optionsCompaniesActive: false,
  optionsPeopleActive: false,
  editTargetListStatus: 'idle',
  newTargetListRequest: {
    projectId: "",
    name: "",
    commercialIds: [],
    departments: [],
    roles: [],
    revenue: "undefined:undefined:undefined",
    employeesNumber: "undefined:undefined:undefined",
    particularityId: null,
    atecos: [{
      atecoCode: null,
      atecoDescription: null,
      sector: null,
      category: null
    }]
  },
  newTargetListStatus: "idle",
  validateNewTargetList: {
    errors: {
      name: false,
      departments: false,
      roles: false,
      sectors: false,
      categories: false,
      commercials: false,
      revenueSign: false,
      revenueFirst: false,
      revenueSecond: false,
      employeesSign: false,
      employeesFirst: false,
      employeesSecond: false,
    },
    status: "idle",
  },
  validateEditTargetList: {
    errors: {
      name: false,
      departments: false,
      roles: false,
      sectors: false,
      categories: false,
      commercials: false,
      revenueSign: false,
      revenueFirst: false,
      revenueSecond: false,
      employeesSign: false,
      employeesFirst: false,
      employeesSecond: false,
    },
    status: "idle",
  },
  findTargetListStatus: "idle",
  targetListPage: TargetListPageEnum.LIST,
  importPersonStatus: "idle",
  importCompanyStatus: "idle",
  findAllTargetListsStatus: "idle",
  filters: {
    commercial: [],
    contacts: "",
    itemsPerPage: 15,
    name: "",
    order: true,
    page: 0,
    sort: "name",
    state: [],
    teamLeader: [],
    project: [],
    sector: [],
    category: []
  },
  deleteTargetListStatus: "idle",
  removeContactsStatus: "idle",
  addContactsStatus: "idle",
  changeContactStateStatus: "idle",
  changeStateStatus: "idle",
  editTargetListRequest: {
    name: "",
    commercialIds: [],
    revenue: "",
    employeesNumber: "",
    departments: [],
    roles: [],
    atecos: [
      {
        atecoCode: null,
        atecoDescription: null,
        sector: null,
        category: null
      }
    ]
  },
  copyTargetListStatus: 'idle',
  copiedTargetList: '',
  getTargetListCounterStatus: 'idle',
  editTargetListTabValue: 0,
  updateNotesStatus: 'idle'
};

export const newTargetList = createAsyncThunk(
  "targetLists/newTargetList",
  async (data: NewTargetListRequest, thunkApi): Promise<string> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.createTargetList(data);
  }
);

export const addPeopleBulk = createAsyncThunk(
  "targetLists/addPeopleBulk",
  async (request: { targetListId: string, filters: FindAllPeopleFilters }, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.addPeopleBulk(request.targetListId, request.filters);
  }
);

export const addCompaniesBulk = createAsyncThunk(
  "targetLists/addCompaniesBulk",
  async (request: { targetListId: string, filters: FindAllCompaniesFilters }, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.addCompaniesBulk(request.targetListId, request.filters);
  }
);

export const getCounters = createAsyncThunk(
  "targetLists/getCounters",
  async (targetListId: string, thunkApi): Promise<TargetListCounterDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.getCounters(targetListId);
  }
);

export const copyTargetList = createAsyncThunk(
  "targetLists/copyTargetList",
  async (request: { data: NewTargetListRequest, people: string[], companies: string[] }, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    TargetListsService.createTargetList(request.data)
      .then(
        response => {
          let promises: Promise<void | string>[] = [];
          promises.push(TargetListsService.addContacts(response, "persons", request.people))
          promises.push(TargetListsService.addContacts(response, "companies", request.companies))
          Promise.all(promises).then(() => thunkApi.dispatch(setCopiedTargetList(response)))
        },
        (error) => {
          Promise.reject(error)
        }
      );
  }
);

export const changeContactState = createAsyncThunk(
  "targetLists/changeContactState",
  async (
    request: {
      targetListId: string;
      type: "persons" | "companies";
      contactState?: ContactStateEnum;
      contactId: string;
      stateInfo: null | string
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.changeContactStatus(
      request.targetListId,
      request.type,
      request.contactId,
      request.contactState,
      request.stateInfo
    );
  }
);

export const updateNotes = createAsyncThunk(
  "targetLists/updateNotes",
  async (
    request: {
      targetListId: string;
      type: "persons" | "companies";
      contactId: string;
      notes: NoteDTO[]
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.updateNotes(
      request.targetListId,
      request.type,
      request.contactId,
      request.notes
    );
  }
);

export const removeContacts = createAsyncThunk(
  "targetLists/removeContacts",
  async (
    data: {
      targetListId: string;
      type: "persons" | "companies";
      contactsId: string[];
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.removeContacts(
      data.targetListId,
      data.type,
      data.contactsId
    );
  }
);

export const addContacts = createAsyncThunk(
  "targetLists/addContacts",
  async (
    data: {
      targetListId: string;
      type: "persons" | "companies";
      contactsId: string[];
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.addContacts(
      data.targetListId,
      data.type,
      data.contactsId
    );
  }
);

export const findAllTArgetLists = createAsyncThunk(
  "targetLists/findAllTArgetLists",
  async (
    filters: FindAllTargetListsFilters,
    thunkApi
  ): Promise<FindAllTargetListsResponse> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.findAllTargetLists(filters);
  }
);

export const changeStatus = createAsyncThunk(
  "targetLists/changeStatus",
  async (
    request: {
      state:
      | TargetListStatusEnum.CONCLUDED
      | TargetListStatusEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.changeStatus(request);
  }
);

export const importContactPersonCSV = createAsyncThunk(
  "targetLists/importContactPersonCSV",
  async (
    request: { data: FormData; id: string },
    thunkApi
  ): Promise<ImportCSVResponseDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.importContactCSV(
      request.data,
      "persons",
      request.id
    );
  }
);

export const importContactCompanyCSV = createAsyncThunk(
  "targetLists/importContactCompanyCSV",
  async (
    request: { data: FormData; id: string },
    thunkApi
  ): Promise<ImportCSVResponseDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.importContactCSV(
      request.data,
      "companies",
      request.id
    );
  }
);

export const findTargetListById = createAsyncThunk(
  "targetLists/findTargetListById",
  async (id: string, thunkApi): Promise<TargetListDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.findTargetListById(id);
  }
);

export const deleteTargetList = createAsyncThunk(
  "targetLists/deleteTargetList",
  async (id: string, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.deleteTargetList(id);
  }
);

export const editTargetList = createAsyncThunk(
  "targetLists/editTargetList",
  async (request: { id: string, data: EditTargetListRequest }, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.editTargetList(request.id, request.data);
  }
);

export const validateNewTargetList = createAsyncThunk(
  "targetLists/validateNewTargetList",
  async (targetList: NewTargetListRequest, thunkApi): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateNewTargetListCategories(false));
    thunkApi.dispatch(setValidateNewTargetListCommercials(false));
    thunkApi.dispatch(setValidateNewTargetListDepartments(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesFirst(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesSecond(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesSign(false));
    thunkApi.dispatch(setValidateNewTargetListName(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueFirst(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueSecond(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueSign(false));
    thunkApi.dispatch(setValidateNewTargetListRoles(false));
    thunkApi.dispatch(setValidateNewTargetListSectors(false));
    thunkApi.dispatch(setValidateNewTargetListStatus("idle"));

    if (targetList.commercialIds.length === 0) {
      thunkApi.dispatch(setValidateNewTargetListCommercials(true));
      isValid = false;
    }

    // if (targetList.departments.length === 0) {
    //   thunkApi.dispatch(setValidateNewTargetListDepartments(true));
    //   isValid = false;
    // }

    // if (targetList.employeesNumber.split(":")[0] === "undefined") {
    //   thunkApi.dispatch(setValidateNewTargetListEmployeesSign(true));
    //   isValid = false;
    // }

    if (
      targetList.employeesNumber.split(":")[0] !== "undefined" &&
      targetList.employeesNumber.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListEmployeesFirst(true));
      isValid = false;
    }

    if (
      targetList.employeesNumber.split(":")[0] === "><" &&
      targetList.employeesNumber.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListEmployeesSecond(true));
      isValid = false;
    }

    if (targetList.name === "" || !targetList.name) {
      thunkApi.dispatch(setValidateNewTargetListName(true));
      isValid = false;
    }

    // if (targetList.revenue.split(":")[0] === "undefined") {
    //   thunkApi.dispatch(setValidateNewTargetListRevenueSign(true));
    //   isValid = false;
    // }

    if (
      targetList.revenue.split(":")[0] !== "undefined" &&
      targetList.revenue.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListRevenueFirst(true));
      isValid = false;
    }

    if (
      targetList.revenue.split(":")[0] === "><" &&
      targetList.revenue.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListRevenueSecond(true));
      isValid = false;
    }

    // if (targetList.roles.length === 0 && targetList.departments.length > 0) {
    //   thunkApi.dispatch(setValidateNewTargetListRoles(true));
    //   isValid = false;
    // }

    if (targetList.atecos.length > 1) {
      let atecos = [...targetList.atecos]
      atecos = [...atecos.filter(ateco => !(ateco.atecoCode === null && ateco.sector === null && ateco.category === null))]
      if (atecos.length === 0) {
        thunkApi.dispatch(setNewTargetListAtecos([{ atecoCode: null, atecoDescription: null, sector: null, category: null }]))
      } else
        thunkApi.dispatch(setNewTargetListAtecos([...atecos]))
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

export const validateEditTargetList = createAsyncThunk(
  "targetLists/validateEditTargetList",
  async (targetList: EditTargetListRequest, thunkApi): Promise<void> => {
    let isValid = true;
    thunkApi.dispatch(setValidateEditTargetListCategories(false));
    thunkApi.dispatch(setValidateEditTargetListCommercials(false));
    thunkApi.dispatch(setValidateEditTargetListDepartments(false));
    thunkApi.dispatch(setValidateEditTargetListEmployeesFirst(false));
    thunkApi.dispatch(setValidateEditTargetListEmployeesSecond(false));
    thunkApi.dispatch(setValidateEditTargetListEmployeesSign(false));
    thunkApi.dispatch(setValidateEditTargetListName(false));
    thunkApi.dispatch(setValidateEditTargetListRevenueFirst(false));
    thunkApi.dispatch(setValidateEditTargetListRevenueSecond(false));
    thunkApi.dispatch(setValidateEditTargetListRevenueSign(false));
    thunkApi.dispatch(setValidateEditTargetListRoles(false));
    thunkApi.dispatch(setValidateEditTargetListSectors(false));
    thunkApi.dispatch(setValidateEditTargetListStatus("idle"));

    // if (targetList.categories.length === 0 && targetList.sectors.length > 0) {
    //   thunkApi.dispatch(setValidateEditTargetListCategories(true));
    //   isValid = false;
    // }

    if (targetList.commercialIds.length === 0) {
      thunkApi.dispatch(setValidateEditTargetListCommercials(true));
      isValid = false;
    }

    // if (targetList.departments.length === 0) {
    //   thunkApi.dispatch(setValidateEditTargetListDepartments(true));
    //   isValid = false;
    // }

    // if (targetList.employeesNumber.split(":")[0] === "undefined") {
    //   thunkApi.dispatch(setValidateEditTargetListEmployeesSign(true));
    //   isValid = false;
    // }

    if (
      targetList.employeesNumber.split(":")[0] !== "undefined" &&
      targetList.employeesNumber.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateEditTargetListEmployeesFirst(true));
      isValid = false;
    }

    if (
      targetList.employeesNumber.split(":")[0] === "><" &&
      targetList.employeesNumber.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateEditTargetListEmployeesSecond(true));
      isValid = false;
    }

    if (targetList.name === "" || !targetList.name) {
      thunkApi.dispatch(setValidateEditTargetListName(true));
      isValid = false;
    }

    // if (targetList.revenue.split(":")[0] === "undefined") {
    //   thunkApi.dispatch(setValidateEditTargetListRevenueSign(true));
    //   isValid = false;
    // }

    if (
      targetList.revenue.split(":")[0] !== "undefined" &&
      targetList.revenue.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateEditTargetListRevenueFirst(true));
      isValid = false;
    }

    if (
      targetList.revenue.split(":")[0] === "><" &&
      targetList.revenue.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateEditTargetListRevenueSecond(true));
      isValid = false;
    }

    // if (targetList.roles.length === 0 && targetList.departments.length > 0) {
    //   thunkApi.dispatch(setValidateEditTargetListRoles(true));
    //   isValid = false;
    // }

    // if (targetList.sectors.length === 0) {
    //   thunkApi.dispatch(setValidateEditTargetListSectors(true));
    //   isValid = false;
    // }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

const targetListSlice = createSlice({
  name: "targetList/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(updateNotes.pending, (state) => {
        state.updateNotesStatus = "loading";
      })
      .addCase(updateNotes.fulfilled, (state) => {
        state.updateNotesStatus = "successfully";
      })
      .addCase(updateNotes.rejected, (state) => {
        state.updateNotesStatus = "failed";
      })
      .addCase(validateNewTargetList.pending, (state) => {
        state.validateNewTargetList.status = "loading";
      })
      .addCase(validateNewTargetList.fulfilled, (state) => {
        state.validateNewTargetList.status = "successfully";
      })
      .addCase(validateNewTargetList.rejected, (state) => {
        state.validateNewTargetList.status = "failed";
      })
      .addCase(addPeopleBulk.pending, (state) => {
        state.addPeopleBulkStatus = "loading";
      })
      .addCase(addPeopleBulk.fulfilled, (state) => {
        state.addPeopleBulkStatus = "successfully";
      })
      .addCase(addPeopleBulk.rejected, (state) => {
        state.addPeopleBulkStatus = "failed";
      })
      .addCase(addCompaniesBulk.pending, (state) => {
        state.addCompaniesBulkStatus = "loading";
      })
      .addCase(addCompaniesBulk.fulfilled, (state) => {
        state.addCompaniesBulkStatus = "successfully";
      })
      .addCase(addCompaniesBulk.rejected, (state) => {
        state.addCompaniesBulkStatus = "failed";
      })
      .addCase(getCounters.pending, (state) => {
        state.getTargetListCounterStatus = "loading";
      })
      .addCase(getCounters.fulfilled, (state, action) => {
        state.getTargetListCounterStatus = "successfully";
        state.getTargetListCounterResponse = action.payload
      })
      .addCase(getCounters.rejected, (state) => {
        state.getTargetListCounterStatus = "failed";
      })
      .addCase(validateEditTargetList.pending, (state) => {
        state.validateEditTargetList.status = "loading";
      })
      .addCase(validateEditTargetList.fulfilled, (state) => {
        state.validateEditTargetList.status = "successfully";
      })
      .addCase(validateEditTargetList.rejected, (state) => {
        state.validateEditTargetList.status = "failed";
      })
      .addCase(newTargetList.pending, (state) => {
        state.newTargetListStatus = "loading";
      })
      .addCase(newTargetList.fulfilled, (state, action) => {
        state.newTargetListStatus = "successfully";
        state.selectedTargetList = action.payload;
      })
      .addCase(newTargetList.rejected, (state) => {
        state.newTargetListStatus = "failed";
      })
      .addCase(findTargetListById.pending, (state) => {
        state.findTargetListStatus = "loading";
      })
      .addCase(findTargetListById.fulfilled, (state, action) => {
        state.findTargetListStatus = "successfully";
        state.findTargetListResponse = action.payload;
      })
      .addCase(findTargetListById.rejected, (state) => {
        state.findTargetListStatus = "failed";
      })
      .addCase(importContactPersonCSV.pending, (state) => {
        state.importPersonStatus = "loading";
      })
      .addCase(importContactPersonCSV.fulfilled, (state, action) => {
        state.importPersonStatus = "successfully";
        state.importPersonResponse = action.payload;
      })
      .addCase(importContactPersonCSV.rejected, (state) => {
        state.importPersonStatus = "failed";
      })
      .addCase(importContactCompanyCSV.pending, (state) => {
        state.importCompanyStatus = "loading";
      })
      .addCase(importContactCompanyCSV.fulfilled, (state, action) => {
        state.importCompanyStatus = "successfully";
        state.importCompanyResponse = action.payload;
      })
      .addCase(importContactCompanyCSV.rejected, (state) => {
        state.importCompanyStatus = "failed";
      })
      .addCase(findAllTArgetLists.pending, (state) => {
        state.findAllTargetListsStatus = "loading";
      })
      .addCase(findAllTArgetLists.fulfilled, (state, action) => {
        state.findAllTargetListsStatus = "successfully";
        state.findAllTargetListsResponse = action.payload;
      })
      .addCase(findAllTArgetLists.rejected, (state) => {
        state.findAllTargetListsStatus = "failed";
      })
      .addCase(deleteTargetList.pending, (state) => {
        state.deleteTargetListStatus = "loading";
      })
      .addCase(deleteTargetList.fulfilled, (state, action) => {
        state.deleteTargetListStatus = "successfully";
      })
      .addCase(deleteTargetList.rejected, (state) => {
        state.deleteTargetListStatus = "failed";
      })
      .addCase(removeContacts.pending, (state) => {
        state.removeContactsStatus = "loading";
      })
      .addCase(removeContacts.fulfilled, (state, action) => {
        state.removeContactsStatus = "successfully";
      })
      .addCase(removeContacts.rejected, (state) => {
        state.removeContactsStatus = "failed";
      })
      .addCase(addContacts.pending, (state) => {
        state.addContactsStatus = "loading";
      })
      .addCase(addContacts.fulfilled, (state, action) => {
        state.addContactsStatus = "successfully";
      })
      .addCase(addContacts.rejected, (state) => {
        state.addContactsStatus = "failed";
      })
      .addCase(changeContactState.pending, (state) => {
        state.changeContactStateStatus = "loading";
      })
      .addCase(changeContactState.fulfilled, (state, action) => {
        state.changeContactStateStatus = "successfully";
      })
      .addCase(changeContactState.rejected, (state) => {
        state.changeContactStateStatus = "failed";
      })
      .addCase(changeStatus.pending, (state) => {
        state.changeStateStatus = "loading";
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.changeStateStatus = "successfully";
      })
      .addCase(changeStatus.rejected, (state) => {
        state.changeStateStatus = "failed";
      })
      .addCase(editTargetList.pending, (state) => {
        state.editTargetListStatus = "loading";
      })
      .addCase(editTargetList.fulfilled, (state, action) => {
        state.editTargetListStatus = "successfully";
      })
      .addCase(editTargetList.rejected, (state) => {
        state.editTargetListStatus = "failed";
      })
      .addCase(copyTargetList.pending, (state) => {
        state.copyTargetListStatus = "loading";
      })
      .addCase(copyTargetList.fulfilled, (state, action) => {
        state.copyTargetListStatus = "successfully";
      })
      .addCase(copyTargetList.rejected, (state) => {
        state.copyTargetListStatus = "failed";
      });
  },
  reducers: {
    setAddPeopleBulkStatus: (state, action) => {
      state.addPeopleBulkStatus = action.payload
    },
    setUpdateNotesStatus: (state, action) => {
      state.updateNotesStatus = action.payload
    },
    setAddCompaniesBulkStatus: (state, action) => {
      state.addCompaniesBulkStatus = action.payload
    },
    setNewTargetListProjectId: (state, action) => {
      state.newTargetListRequest.projectId = action.payload;
    },
    setNewTargetListCommercialIds: (state, action) => {
      state.newTargetListRequest.commercialIds = action.payload;
    },
    setNewTargetListDepartments: (state, action) => {
      state.newTargetListRequest.departments = action.payload;
    },
    setNewTargetListEmployeesNumber: (state, action) => {
      state.newTargetListRequest.employeesNumber = action.payload;
    },
    setNewTargetListName: (state, action) => {
      state.newTargetListRequest.name = action.payload;
    },
    setNewTargetListRevenue: (state, action) => {
      state.newTargetListRequest.revenue = action.payload;
    },
    setNewTargetListParticularity: (state, action) => {
      state.newTargetListRequest.particularityId = action.payload;
    },
    setNewTargetListRoles: (state, action) => {
      state.newTargetListRequest.roles = action.payload;
    },
    setNewTargetListAtecos: (state, action) => {
      state.newTargetListRequest.atecos = action.payload;
    },
    setNewTargetListStatus: (state, action) => {
      state.newTargetListStatus = action.payload;
    },
    setValidateNewTargetListName: (state, action) => {
      state.validateNewTargetList.errors.name = action.payload;
    },
    setValidateNewTargetListCategories: (state, action) => {
      state.validateNewTargetList.errors.categories = action.payload;
    },
    setValidateNewTargetListCommercials: (state, action) => {
      state.validateNewTargetList.errors.commercials = action.payload;
    },
    setValidateNewTargetListDepartments: (state, action) => {
      state.validateNewTargetList.errors.departments = action.payload;
    },
    setValidateNewTargetListEmployeesFirst: (state, action) => {
      state.validateNewTargetList.errors.employeesFirst = action.payload;
    },
    setValidateNewTargetListEmployeesSecond: (state, action) => {
      state.validateNewTargetList.errors.employeesSecond = action.payload;
    },
    setValidateNewTargetListEmployeesSign: (state, action) => {
      state.validateNewTargetList.errors.employeesSign = action.payload;
    },
    setValidateNewTargetListRevenueFirst: (state, action) => {
      state.validateNewTargetList.errors.revenueFirst = action.payload;
    },
    setValidateNewTargetListRevenueSecond: (state, action) => {
      state.validateNewTargetList.errors.revenueSecond = action.payload;
    },
    setValidateNewTargetListRevenueSign: (state, action) => {
      state.validateNewTargetList.errors.revenueSign = action.payload;
    },
    setValidateNewTargetListRoles: (state, action) => {
      state.validateNewTargetList.errors.roles = action.payload;
    },
    setValidateNewTargetListSectors: (state, action) => {
      state.validateNewTargetList.errors.sectors = action.payload;
    },
    setValidateNewTargetListStatus: (state, action) => {
      state.validateNewTargetList.status = action.payload;
    },
    setSelectedTargetList: (state, action) => {
      state.selectedTargetList = action.payload;
    },
    setFindTargetListStatus: (state, action) => {
      state.findTargetListStatus = action.payload;
    },
    setTargetListPage: (state, action) => {
      state.targetListPage = action.payload;
    },
    setImportPersonCSVStatus: (state, action) => {
      state.importPersonStatus = action.payload;
    },
    setImportCompanyCSVStatus: (state, action) => {
      state.importCompanyStatus = action.payload;
    },
    setImportPersonCSVResponse: (state, action) => {
      state.importPersonResponse = action.payload;
    },
    setImportCompanyCSVResponse: (state, action) => {
      state.importCompanyResponse = action.payload;
    },
    setFindAllTargetListsStatus: (state, action) => {
      state.findAllTargetListsStatus = action.payload;
    },
    setTargetListsFilterPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setTargetListsFilterSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setTargetListsFilterCategory: (state, action) => {
      state.filters.category = action.payload;
    },
    setTargetListsFilterItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setTargetListsFilterOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setDeleteTargetListStatus: (state, action) => {
      state.deleteTargetListStatus = action.payload;
    },
    setTargetListFilterName: (state, action) => {
      state.filters.name = action.payload;
    },
    setTargetListFilterProject: (state, action) => {
      state.filters.project = action.payload;
    },
    setTargetListFilterTeamLeader: (state, action) => {
      state.filters.teamLeader = action.payload;
    },
    setTargetListFilterCommercial: (state, action) => {
      state.filters.commercial = action.payload;
    },
    setTargetListFilterSector: (state, action) => {
      state.filters.sector = action.payload;
    },
    setTargetListFilterState: (state, action) => {
      state.filters.state = action.payload;
    },
    setRemoveContactsStatus: (state, action) => {
      state.removeContactsStatus = action.payload;
    },
    setAddContactsStatus: (state, action) => {
      state.addContactsStatus = action.payload;
    },
    setChangeContactStateStatus: (state, action) => {
      state.changeContactStateStatus = action.payload;
    },
    setOption: (state, action) => {
      state.option = action.payload;
    },
    setOptionsActive: (state, action) => {
      state.optionsActive = action.payload;
    },
    setOptionPeople: (state, action) => {
      state.optionPeople = action.payload;
    },
    setOptionsPeopleActive: (state, action) => {
      state.optionsPeopleActive = action.payload;
    },
    setOptionCompanies: (state, action) => {
      state.optionCompanies = action.payload;
    },
    setOptionsCompaniesActive: (state, action) => {
      state.optionsCompaniesActive = action.payload;
    },
    setTargetListChangeStateStatus: (state, action) => {
      state.changeStateStatus = action.payload;
    },
    setEditTargetListRequestName: (state, action) => {
      state.editTargetListRequest.name = action.payload
    },
    setEditTargetListRequestAtecos: (state, action) => {
      state.editTargetListRequest.atecos = action.payload
    },
    setEditTargetListRequestCommercials: (state, action) => {
      state.editTargetListRequest.commercialIds = action.payload
    },
    setEditTargetListRequestDepartments: (state, action) => {
      state.editTargetListRequest.departments = action.payload
    },
    setEditTargetListRequestEmployeesNumber: (state, action) => {
      state.editTargetListRequest.employeesNumber = action.payload
    },
    setEditTargetListRequestParticularity: (state, action) => {
      state.editTargetListRequest.particularityId = action.payload
    },
    setEditTargetListRequestRevenue: (state, action) => {
      state.editTargetListRequest.revenue = action.payload
    },
    setEditTargetListRequestRoles: (state, action) => {
      state.editTargetListRequest.roles = action.payload
    },
    setValidateEditTargetListName: (state, action) => {
      state.validateEditTargetList.errors.name = action.payload;
    },
    setValidateEditTargetListCategories: (state, action) => {
      state.validateEditTargetList.errors.categories = action.payload;
    },
    setValidateEditTargetListCommercials: (state, action) => {
      state.validateEditTargetList.errors.commercials = action.payload;
    },
    setValidateEditTargetListDepartments: (state, action) => {
      state.validateEditTargetList.errors.departments = action.payload;
    },
    setValidateEditTargetListEmployeesFirst: (state, action) => {
      state.validateEditTargetList.errors.employeesFirst = action.payload;
    },
    setValidateEditTargetListEmployeesSecond: (state, action) => {
      state.validateEditTargetList.errors.employeesSecond = action.payload;
    },
    setValidateEditTargetListEmployeesSign: (state, action) => {
      state.validateEditTargetList.errors.employeesSign = action.payload;
    },
    setValidateEditTargetListRevenueFirst: (state, action) => {
      state.validateEditTargetList.errors.revenueFirst = action.payload;
    },
    setValidateEditTargetListRevenueSecond: (state, action) => {
      state.validateEditTargetList.errors.revenueSecond = action.payload;
    },
    setValidateEditTargetListRevenueSign: (state, action) => {
      state.validateEditTargetList.errors.revenueSign = action.payload;
    },
    setValidateEditTargetListRoles: (state, action) => {
      state.validateEditTargetList.errors.roles = action.payload;
    },
    setValidateEditTargetListSectors: (state, action) => {
      state.validateEditTargetList.errors.sectors = action.payload;
    },
    setValidateEditTargetListStatus: (state, action) => {
      state.validateEditTargetList.status = action.payload;
    },
    setEditTargetListStatus: (state, action) => {
      state.editTargetListStatus = action.payload
    },
    setTargetListToCopy: (state, action) => {
      state.targetListToCopy = action.payload
    },
    setCopyTargetListStatus: (state, action) => {
      state.copyTargetListStatus = action.payload
    },
    setCopiedTargetList: (state, action) => {
      state.copiedTargetList = action.payload
    },
    setGetTargetListCounterResponse: (state, action) => {
      state.getTargetListCounterResponse = action.payload
    },
    setGetTargetListCounterStatus: (state, action) => {
      state.getTargetListCounterStatus = action.payload
    },
    setEditTargetListTabValue: (state, action) => {
      state.editTargetListTabValue = action.payload
    },
    setEditTargetListAtecosOptions: (state, action) => {
      state.editTargetListAtecosOptions = action.payload
    }
  },
});

export const {
  setNewTargetListCommercialIds,
  setNewTargetListDepartments,
  setNewTargetListEmployeesNumber,
  setNewTargetListName,
  setNewTargetListProjectId,
  setNewTargetListRevenue,
  setNewTargetListRoles,
  setNewTargetListAtecos,
  setNewTargetListStatus,
  setValidateNewTargetListCategories,
  setValidateNewTargetListCommercials,
  setValidateNewTargetListDepartments,
  setValidateNewTargetListEmployeesFirst,
  setValidateNewTargetListEmployeesSecond,
  setValidateNewTargetListEmployeesSign,
  setValidateNewTargetListName,
  setValidateNewTargetListRevenueFirst,
  setValidateNewTargetListRevenueSecond,
  setValidateNewTargetListRevenueSign,
  setValidateNewTargetListRoles,
  setValidateNewTargetListSectors,
  setValidateNewTargetListStatus,
  setSelectedTargetList,
  setFindTargetListStatus,
  setTargetListPage,
  setImportPersonCSVResponse,
  setImportPersonCSVStatus,
  setFindAllTargetListsStatus,
  setTargetListsFilterItemsPerPage,
  setTargetListsFilterOrder,
  setTargetListsFilterPage,
  setTargetListsFilterCategory,
  setTargetListsFilterSort,
  setDeleteTargetListStatus,
  setTargetListFilterName,
  setTargetListFilterTeamLeader,
  setTargetListFilterCommercial,
  setTargetListFilterSector,
  setTargetListFilterState,
  setRemoveContactsStatus,
  setAddContactsStatus,
  setImportCompanyCSVStatus,
  setImportCompanyCSVResponse,
  setChangeContactStateStatus,
  setOption,
  setOptionsActive,
  setOptionCompanies,
  setOptionPeople,
  setOptionsCompaniesActive,
  setOptionsPeopleActive,
  setTargetListChangeStateStatus,
  setTargetListFilterProject,
  setNewTargetListParticularity,
  setEditTargetListRequestCommercials,
  setEditTargetListRequestDepartments,
  setEditTargetListRequestEmployeesNumber,
  setEditTargetListRequestName,
  setEditTargetListRequestParticularity,
  setEditTargetListRequestRevenue,
  setEditTargetListRequestRoles,
  setValidateEditTargetListCategories,
  setValidateEditTargetListCommercials,
  setValidateEditTargetListDepartments,
  setValidateEditTargetListEmployeesFirst,
  setValidateEditTargetListEmployeesSecond,
  setValidateEditTargetListEmployeesSign,
  setValidateEditTargetListName,
  setValidateEditTargetListRevenueFirst,
  setValidateEditTargetListRevenueSecond,
  setValidateEditTargetListRevenueSign,
  setValidateEditTargetListRoles,
  setValidateEditTargetListSectors,
  setValidateEditTargetListStatus,
  setEditTargetListStatus,
  setTargetListToCopy,
  setCopyTargetListStatus,
  setCopiedTargetList,
  setGetTargetListCounterResponse,
  setGetTargetListCounterStatus,
  setEditTargetListTabValue,
  setAddPeopleBulkStatus,
  setAddCompaniesBulkStatus,
  setEditTargetListRequestAtecos,
  setEditTargetListAtecosOptions,
  setUpdateNotesStatus
} = targetListSlice.actions;

export default targetListSlice.reducer;
