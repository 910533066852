import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { colors } from "../../../ui/colors";
import { PlusIcon } from "../../../ui/icons/plus";
import Avatar from "../../../ui/molecules/avatar";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { ContactStateEnum, generateDefaultValue, generateOutcomeOptions } from "../../targetList/dto";
import { CompanyEmployees } from "./companyEmployees";
import { EditCompanyForm } from "./editCompanyForm";
import { editCompany, findCompany, setSelectedCompany, setTabValueDetailsCompany } from "./slice";
import { CreatePerson } from "./createPerson";
import { setNewPersonRequestCompanyName, setNewPersonRequestCompanyPhone, setNewPersonRequestCompanyVat, setNewPersonRequestEmail, setNewPersonRequestLinkedinProfile, setNewPersonRequestName, setNewPersonRequestPhone, setNewPersonRequestPrivacy, setNewPersonRequestRole, setNewPersonRequestSurname, setNewPersonResponse, setNewPersonStatus, setValidateCreatePersonEmail, setValidateCreatePersonName, setValidateCreatePersonPhone, setValidateCreatePersonStatus, setValidateCreatePersonSurname } from "../people/slice";
import { MailIcon } from "../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../ui/icons/phoneCall";
import { GlobeIcon } from "../../../ui/icons/globe";
import { EditIcon } from "../../../ui/icons/edit";
import { CheckCircleIcon } from "../../../ui/icons/checkCircle";
import { GroupSelectCustom } from "../../../ui/molecules/groupSelect";
import { changeContactState } from "../../targetList/slice";
import { EditPersonNotes } from "../people/notes";
import { findUsersAvatarsList, getUsersByUserIds, setFindUsersByUserIdStatus } from "../../users/slice";
import { EditCompaniesNotes } from "./notes";

export function EditCompany() {
  const companiesState = useAppSelector((state) => state.companies);
  const usersState = useAppSelector((state) => state.users);
  const targetListsState = useAppSelector((state) => state.targetList);

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [personPage, setPersonPage] = useState<"list" | "new">("list")

  const personPageMap = new Map<"list" | "new", ReactElement>([
    ['list', <CompanyEmployees goToForm={() => setPersonPage("new")} />],
    ['new', <CreatePerson goToList={() => setPersonPage('list')} />],
  ])

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companiesState.selectedCompany !== undefined) {
      dispatch(findCompany(companiesState.selectedCompany));
      setPersonPage('list')
      dispatch(setNewPersonRequestCompanyName(''))
      dispatch(setNewPersonRequestCompanyPhone([]))
      dispatch(setNewPersonRequestCompanyVat(''))
    }
  }, [companiesState.selectedCompany]);

  const notes = targetListsState.findTargetListResponse?.companyContacts &&
    targetListsState.findTargetListResponse?.companyContacts[
    companiesState.findCompanyResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.companyContacts[
      companiesState.findCompanyResponse?.id!
    ].notes : null

  useEffect(() => {
    if (companiesState.findCompanyStatus === 'successfully') {
      if (window.location.pathname.includes('target')) {
        dispatch(getUsersByUserIds(notes?.map(note => note.authorUserId) ?? []))
      }
    }
  }, [companiesState.findCompanyStatus])

  useEffect(() => {
    if (usersState.findUsersByUserIdStatus === 'successfully') {
      dispatch(findUsersAvatarsList(usersState.findUsersByUserIdResponse.map(user => ({ userId: user.userId, objectId: user.avatarObjectId }))))
      dispatch(setFindUsersByUserIdStatus('idle'))
    }
  }, [usersState.findUsersByUserIdStatus])

  useEffect(() => {
    dispatch(setNewPersonRequestEmail(''))
    dispatch(setNewPersonRequestLinkedinProfile(''))
    dispatch(setNewPersonRequestName(''))
    dispatch(setNewPersonRequestPhone(''))
    dispatch(setNewPersonRequestRole(''))
    dispatch(setNewPersonRequestPrivacy(false))
    dispatch(setNewPersonRequestSurname(''))
    dispatch(setNewPersonStatus('idle'))
    dispatch(setNewPersonResponse(undefined))

    dispatch(setValidateCreatePersonEmail(false))
    dispatch(setValidateCreatePersonName(false))
    dispatch(setValidateCreatePersonPhone(false))
    dispatch(setValidateCreatePersonSurname(false))
    dispatch(setValidateCreatePersonStatus('idle'))
  }, [personPage])

  const contactState = targetListsState.findTargetListResponse?.companyContacts &&
    targetListsState.findTargetListResponse?.companyContacts[
    companiesState.findCompanyResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.companyContacts[
      companiesState.findCompanyResponse?.id!
    ].contactState : ContactStateEnum.DRAFT

  const stateInfo = targetListsState.findTargetListResponse?.companyContacts &&
    targetListsState.findTargetListResponse?.companyContacts[
    companiesState.findCompanyResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.companyContacts[
      companiesState.findCompanyResponse?.id!
    ].stateInfo : null

  return (
    <div className="h-full overflow-auto">
      {companiesState.findCompanyStatus === "loading" ||
        targetListsState.findTargetListStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          className={clsx("h-full", {
            "border-t-green-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.LEADTAKEN,
            "border-t-orange-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.TORECALL,
            "border-t-red-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.KO,
            "border-t-neutral-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.INCORRECT,
            "border-t-brandPrimary-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.DRAFT,
          })}
        >
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={companiesState.findCompanyResponse?.name!}
              size="md"
              altTextInitials={
                companiesState.findCompanyResponse?.name
                  .slice(0, 1)
                  .toUpperCase()!
              }
            />
            <div className="flex items-center gap-4">
              <div
                className="cursor-pointer rotate-45"
                onClick={() => dispatch(setSelectedCompany(undefined))}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          {
            window.location.pathname.includes("target-list") &&
            <div className="w-full px-6 py-3 flex items-center border-y border-y-brandPrimary-100 justify-between mt-1 bg-brandPrimary-50">
              <div className="gap-2 flex">
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = 'mailto:' + companiesState.findCompanyResponse?.email;
                    a.click();
                  }}
                  color={"blue"}
                  icon={<MailIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = 'tel:' + companiesState.findCompanyResponse?.phone[0];
                    a.click();
                  }}
                  icon={<PhoneCallIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = companiesState.findCompanyResponse?.website!;
                    a.click();
                  }}
                  icon={<GlobeIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => dispatch(setTabValueDetailsCompany(2))}
                  icon={<EditIcon color={""} size={0} />}
                />
              </div>
              <Button
                size={"sm"}
                iconPosition={contactState === ContactStateEnum.LEADTAKEN ? "left" : 'off'}
                variant={"outline"}
                label="Lead preso"
                color={contactState === ContactStateEnum.LEADTAKEN ? "green" : 'gray'}
                icon={<CheckCircleIcon color={""} size={0} />}
                onClick={() => {
                  if (contactState !== ContactStateEnum.LEADTAKEN) {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "companies",
                        contactState: ContactStateEnum.LEADTAKEN,
                        contactId: companiesState.findCompanyResponse?.id!,
                        stateInfo: null
                      })
                    )
                  }
                }}
              />
            </div>
          }
          {
            window.location.pathname.includes("target-list") &&
            <div className="w-full px-6 py-3 flex items-center border-b border-b-brandPrimary-100 justify-between mt-1">
              <GroupSelectCustom
                disabled={companiesState.findCompanyResponse && companiesState.findCompanyResponse?.contactCount !== null && companiesState.findCompanyResponse?.contactCount !== undefined && companiesState.findCompanyResponse?.contactCount >= 3}
                menuPortalTargetIsNotBody
                placeholder={"Selezion un esito"}
                label="Esito"
                onChange={(e) => {
                  if (e === ContactStateEnum.DRAFT || e === ContactStateEnum.LEADTAKEN) {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "companies",
                        contactState: e,
                        contactId: companiesState.findCompanyResponse?.id!,
                        stateInfo: null
                      })
                    );
                  } else {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "companies",
                        contactState: e && e !== undefined && typeof e === 'string' ? e.split('::')[0] as ContactStateEnum : ContactStateEnum.DRAFT,
                        contactId: companiesState.findCompanyResponse?.id!,
                        stateInfo: e && e !== undefined && typeof e === 'string' ? e.split('::')[1] : null
                      })
                    );
                  }
                }}
                defaultValue={generateDefaultValue(contactState, stateInfo)}
                options={generateOutcomeOptions(companiesState.findCompanyResponse?.contactCount!, contactState)}
              />
            </div>}
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => dispatch(setTabValueDetailsCompany(e))}
            value={companiesState.tabValueDetailsCompany}
            items={[
              {
                icon: <></>,
                label: "Azienda",
              },
              {
                icon: <></>,
                label: "Dipendenti",
              },
              {
                icon: <></>,
                label: "Note",
                hide: !window.location.pathname.includes('target')
              }
            ]}
          />
          <TabPages
            pages={[
              <div className="py-4">
                <EditCompanyForm />
                <div className="flex justify-end gap-2 py-4 px-6">
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"blue"}
                    label="Annulla"
                    onClick={() =>
                      dispatch(setSelectedCompany(undefined))
                    }
                  />
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"solid"}
                    color={"blue"}
                    label="Salva"
                    onClick={() =>
                      dispatch(
                        editCompany({
                          id: companiesState.findCompanyResponse?.id!,
                          data: companiesState.editCompanyRequest,
                        })
                      )
                    }
                  />
                </div>
              </div>,
              personPageMap.get(personPage)!,
              <EditCompaniesNotes />
            ]}
            direction={direction}
            setDirection={(value) => setDirection(value)}
            value={companiesState.tabValueDetailsCompany}
            setValue={(e) => dispatch(setTabValueDetailsCompany(e))}
          />
        </div>
      )}
    </div>
  );
}
