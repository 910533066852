import clsx from "clsx"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { PlusIcon } from "../../../ui/icons/plus"
import { TrashIcon } from "../../../ui/icons/trash"
import Button from "../../../ui/molecules/button"
import Input from "../../../ui/molecules/input/Input"
import { SelectCustom } from "../../../ui/molecules/select"
import { Spinner } from "../../../ui/molecules/spinner"
import Toggle from "../../../ui/molecules/toggle"
import { associatePersonToCompany, setErrorAssociateId, setErrorAssociateName, setErrorAssociatePhone, setErrorAssociateVat, setPersonToAddCompany, validateAssociatePersonToCompany } from "./slice"

export function AssociateCompanyModal() {
    const peopleState = useAppSelector(state => state.people)
    const databaseState = useAppSelector(state => state.database)

    const [companyId, setCompanyId] = useState<string | undefined>(undefined)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [companyName, setCompanyName] = useState<string | undefined>(undefined)
    const [companyVat, setCompanyVat] = useState<string | undefined>(undefined)
    const [companyPhones, setCompanyPhones] = useState<string[]>([""])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!peopleState.personToAddCompany) {
            setCompanyId(undefined)
            setNotFound(false)
            setCompanyName(undefined)
            setCompanyVat(undefined)
            setCompanyPhones([""])
            dispatch(setErrorAssociateId(false))
            dispatch(setErrorAssociateName(false))
            dispatch(setErrorAssociatePhone(false))
            dispatch(setErrorAssociateVat(false))
        }
    }, [peopleState.personToAddCompany])

    useEffect(() => {
        if (peopleState.errors.validateAssociate.status === 'successfully') {
            dispatch(associatePersonToCompany({
                data: {
                    companyPhone: companyPhones,
                    companyId: companyId,
                    companyName: companyName,
                    companyVat: companyVat
                },
                personId: peopleState.personToAddCompany?.id || ''
            }))
        }
    }, [peopleState.errors.validateAssociate.status])

    return (
        <div
            onClick={() => {
                dispatch(setPersonToAddCompany(undefined))
            }}
            className={clsx("transition-all absolute bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]", {
                "block": peopleState.personToAddCompany,
                "hidden": !peopleState.personToAddCompany
            })} >
            <div
                onClick={e => {
                    e.stopPropagation()
                }}
                className="transition-all flex flex-col w-[460px] items-center gap-5 p-[40px] bg-white rounded-[30px] opacity-100 shadow-sm min-w-[400px]">
                <span className="text-text-2xl font-semibold text-neutral-800">
                    Associa un'azienda a {peopleState.personToAddCompany?.name + ' ' + peopleState.personToAddCompany?.surname}
                </span>
                <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full mt-3">
                    Seleziona un'azienda esistente o crea una nuova
                </span>
                <SelectCustom
                    error={peopleState.errors.validateAssociate.id}
                    errorLabel="Seleziona un'azienda."
                    disabled={notFound}
                    onChange={e => {
                        if (e === undefined || typeof e === 'string') {
                            setCompanyId(e)
                        }
                    }}
                    isClearable={false}
                    menuPortalTargetIsNotBody
                    defaultValue={companyId}
                    placeholder={"Cerca per ragione sociale"}
                    options={databaseState.companiesForFiltersResponse ? databaseState.companiesForFiltersResponse.data.map(company => ({ label: company.name, value: company.id })) : []}
                    noOptionsMessage="Non sono presenti aziende."
                />
                <Toggle
                    disabled={false}
                    placeholder="L'azienda che cerco non è presente in elenco"
                    checked={notFound}
                    onChange={function (value: boolean): void {
                        setNotFound(value)
                        setCompanyId(undefined)
                    }}
                />
                {
                    notFound &&
                    <>
                        <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full mt-6">
                            Inserisci i dettagli della nuova azienda
                        </span>
                        <Input
                            label="Nome azienda *"
                            placeholder="Inserisci il nome dell'azienda"
                            defaultValue={companyName}
                            error={peopleState.errors.validateAssociate.name}
                            supportingText={peopleState.errors.validateAssociate.name ? "Inserire il nome dell'azienda" : ""}
                            onChangeText={text => setCompanyName(text)}
                        />
                        <div className="w-full flex flex-col gap-2">
                            <span className="text-label-sm font-medium text-left w-full flex justify-between items-center" style={{ color: 'rgba(78, 94, 126, 1)' }}>
                                Numero di telefono dell'azienda *

                            </span>
                            <div className="max-h-48 overflow-auto flex flex-col gap-3">
                                {
                                    companyPhones.map((phone, index) =>
                                        <div key={index + '-div'} className="flex w-full gap-3 items-center">
                                            <Input
                                                key={index + '-input'}
                                                placeholder="Inserisci il numero di telefono dell'azienda"
                                                value={phone}
                                                onChangeText={text => {
                                                    const phones = [...companyPhones]
                                                    phones[index] = text
                                                    setCompanyPhones([...phones])
                                                }}
                                            />
                                            <Button
                                                size={"sm"}
                                                iconPosition={"only"}
                                                variant={"link"}
                                                color={"blue"}
                                                disabled={index === 0}
                                                onClick={() => {
                                                    setCompanyPhones(companyPhones.filter((_, _index) => _index !== index));
                                                }}
                                                icon={<TrashIcon color={''} size={0} />}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            {
                                peopleState.errors.validateAssociate.phone &&
                                <span className="text-text-sm text-red-500">
                                    Inserire correttamente i numeri di telefono.
                                </span>
                            }
                            <div>
                                <Button
                                    size={"sm"}
                                    iconPosition={"left"}
                                    label="Aggiungi telefono"
                                    variant={"outline"}
                                    color={"blue"}
                                    onClick={() => setCompanyPhones([...companyPhones, ""])}
                                    icon={<PlusIcon color={colors.brandPrimary[600]} size={0} />}
                                />
                            </div>
                        </div>
                        <Input
                            label="P. IVA"
                            placeholder="Inserisci la P. IVA dell'azienda"
                            defaultValue={companyVat}
                            error={peopleState.errors.validateAssociate.vat}
                            supportingText={peopleState.errors.validateAssociate.vat ? "Inserire una P. IVA valida" : ""}
                            onChangeText={text => setCompanyVat(text)}
                        />
                    </>
                }
                {
                    peopleState.errors.validateAssociate.status === 'loading' || peopleState.associatePersonToCompanyStatus === 'loading' ?
                        <Spinner /> :
                        <div className="flex gap-4 w-full mt-6">
                            <Button size={"sm"} iconPosition={"off"} variant={"outline"} color={"gray"} label="Annulla" fullWidth onClick={() => dispatch(setPersonToAddCompany(undefined))} />
                            <Button size={"sm"} iconPosition={"off"} variant={"solid"} color={"blue"} label="Associa azienda" fullWidth
                                onClick={() => {
                                    dispatch(validateAssociatePersonToCompany({
                                        body: {
                                            companyPhone: companyPhones,
                                            companyId: companyId,
                                            companyName: companyName,
                                            companyVat: companyVat
                                        },
                                        notFound
                                    }))
                                }}
                            />
                        </div>
                }
            </div>
        </div>
    )
}