import { useDebounce } from "../../lib/hooks/debounce";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { SearchIcon } from "../../ui/icons/search";
import Input from "../../ui/molecules/input";
import { SelectCustom } from "../../ui/molecules/select";
import { UserRole, UserRoleMap } from "./dto";
import {
  setFindAllUsersFiltersRole,
  setFindAllUsersFiltersSearch,
} from "./slice";

const options = [
  { value: UserRole.admin, label: UserRoleMap.get(UserRole.admin)! },
  {
    value: UserRole.collaborator,
    label: UserRoleMap.get(UserRole.collaborator)!,
  },
  { value: UserRole.commercial, label: UserRoleMap.get(UserRole.commercial)! },
  { value: UserRole.customer, label: UserRoleMap.get(UserRole.customer)! },
  {
    value: UserRole.team_leader,
    label: UserRoleMap.get(UserRole.team_leader)!,
  },
];

export function UsersFilters() {
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const debounce = useDebounce(
    (value) => dispatch(setFindAllUsersFiltersSearch(value)),
    1000
  );

  return (
    <div
      className="px-4 bg-white border-t border-t-brandPrimary-100"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: "100%",
        flexShrink: 0,
        height: "64px",
      }}
    >
      <div style={{ width: "640px" }}>
        <Input
          startIcon={<SearchIcon color={""} size={0} />}
          placeholder="Cerca utente"
          onChangeText={(value) => debounce(value)}
          defaultValue={usersState.filters.search}
        />
      </div>
      <div style={{ width: "320px" }}>
        <SelectCustom
          isClearable
          placeholder="Tutti i ruoli"
          options={options}
          onChange={(value) => dispatch(setFindAllUsersFiltersRole(value))}
          defaultValue={usersState.filters.role}
        />
      </div>
    </div>
  );
}
