import { useDebounce } from "../../../../lib/hooks/debounce";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { MailIcon } from "../../../../ui/icons/mail";
import { SearchIcon } from "../../../../ui/icons/search";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import {
  setFindAllPeopleFiltersHasCompany,
  setFindAllPeopleFiltersPersonEmail,
  setFindAllPeopleFiltersSearch,
} from "../slice";

const options = [
  { value: "true", label: "Con azienda associata" },
  { value: "false", label: "Senza azienda associata" },
];

export function PeopleHorizontalFilters() {
  const peopleState = useAppSelector((state) => state.people);
  const dispatch = useAppDispatch();
  const debounce = useDebounce(
    (value) => dispatch(setFindAllPeopleFiltersSearch(value)),
    1000
  );
  const debounceEmail = useDebounce(
    (value) => dispatch(setFindAllPeopleFiltersPersonEmail(value)),
    1000
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: '100%',
        height: "64px",
        padding: "0 16px",
        backgroundColor: "white",
        borderBottom: "1px solid " + colors.neutral[200],
      }}
    >
      <Input
        startIcon={<SearchIcon color={""} size={0} />}
        placeholder="Cerca persona"
        onChangeText={(value) => debounce(value)}
        defaultValue={peopleState.filters.personName}
      />
      <Input
        startIcon={<MailIcon color={""} size={0} />}
        placeholder="Cerca per email"
        onChangeText={(value) => debounceEmail(value)}
        defaultValue={peopleState.filters.personEmail}
      />
      <SelectCustom
        isClearable
        menuPortalTargetIsNotBody
        placeholder="Azienda associata"
        options={options}
        onChange={(value) =>
          dispatch(setFindAllPeopleFiltersHasCompany(value))
        }
        defaultValue={peopleState.filters.hasCompany}
      />
    </div>
  );
}
