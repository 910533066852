import clsx from "clsx"
import { ReactElement } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { SortIcon } from "../../../ui/icons/sort"
import { setFindAllCompaniesFiltersOrder, setFindAllCompaniesFiltersSort } from "./slice"

interface Props {
    column: string
    index: number
}
export function Head(props: Props) {
    const { column, index } = props

    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    const cellMap = new Map<string, ReactElement>([
        ['Azienda', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('name')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Cod. ATECO', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('atecoCode')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Fatturato', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('revenue')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Telefono', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('phone')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Email', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('email')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Sede azienda', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('address')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['P. IVA', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('vat')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Settore', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('sector')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Categoria', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('category')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Dipendenti', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('employeesNumber')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Stato', <div className={clsx("flex gap-[4px]", {
            ' px-[4px]': index !== 0,
        })}>
            <div
                onClick={() => { dispatch(setFindAllCompaniesFiltersSort('state')); dispatch(setFindAllCompaniesFiltersOrder(!companiesState.filters.order)) }}
                className="cursor-pointer hover: opacity-70"
            >
                <SortIcon />
            </div>
            {column}
        </div>],
        ['Sito web', <div className={clsx("flex gap-[4px] justify-center text-center", {
            ' px-[4px]': index !== 0,
        })}>
            {column}
        </div>],
    ])

    return (
        <th className={clsx("text-left text-nowrap text-crop", {
        })}>
            {cellMap.get(column)}
        </th>
    )
}