import { formatDate } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { downloadCSVfromBase64 } from "../../../../../lib/utils";
import { colors } from "../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../ui/icons/checkCircle";
import { DownloadIcon } from "../../../../../ui/icons/download";
import Button from "../../../../../ui/molecules/button";
import { setImportCompanyCSVResponse, setImportCompanyCSVStatus, setImportPersonCSVResponse, setImportPersonCSVStatus } from "../../../slice";

export function ContactMissed() {
    const targetListState = useAppSelector(state => state.targetList)
    const dispatch = useAppDispatch()

    return (
        <>
            <CheckCircleIcon color={colors.green[500]} size={64} />
            <span className="text-heading-xs text-neutral-800 font-bold">Importazione completata</span>
            <div className="flex items-center justify-center gap-3">
                <span className="text-text-sm text-neutral-600">Contatti importati: <span className="text-text-sm text-neutral-800 font-semibold">{targetListState.importPersonResponse?.ids.length}</span></span>
                <span className="text-text-sm text-neutral-600">Non importati: <span className="text-text-sm text-red-500 font-semibold">{targetListState.importPersonResponse?.missed.length}</span></span>
            </div>
            <Button
                size={"md"}
                iconPosition={"off"}
                variant={"solid"}
                color={
                    targetListState.importPersonStatus === "successfully" ? "green" : "red"
                }
                label="Chiudi modale"
                onClick={() => {
                    dispatch(setImportPersonCSVStatus("idle"));
                    dispatch(setImportPersonCSVResponse(undefined));
                }}
            />
            <Button
                size={"md"}
                iconPosition={"left"}
                variant={"link"}
                color={"blue"}
                label="Scarica lista contatti non importati"
                icon={<DownloadIcon color={""} size={0} />}
                onClick={() => {
                    downloadCSVfromBase64(targetListState.importPersonResponse?.base64CsvMissed!, "cd_missed_target_people_download_" + formatDate(new Date(), "dd_MM_yyyy"))
                }}
            />
        </>
    )
}