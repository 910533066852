import clsx from "clsx";
import { cloneElement } from "react";
import { colors } from "../../colors";
import { TabMenuProps } from "./dto";

export function TabMenu(props: TabMenuProps) {
    const handleClick = (index: number) => {
        const newDirection = index > props.value ? 'left' : 'right';
        props.setDirection(newDirection);
        props.setValue(index);
    };

    return (
        <div className={"flex gap-5 px-6 mb-[-1px]"}>
            {props.items.filter(tab => !tab.hide).map((item, index) => (
                <div
                    key={'menu-tab-' + index}
                    onClick={() => !item.disabled && handleClick(index)}
                    className={clsx(
                        "h-[64px] flex-shrink-0 flex gap-[8px] border-b-2 items-center cursor-pointer transition-all",
                        {
                            'border-transparent': index !== props.value,
                            "border-brandPrimary-500": index === props.value,
                            "opacity-80 cursor-default": item.disabled
                        }
                    )}
                >
                    {cloneElement(item.icon, { size: 16, color: index !== props.value ? colors.neutral[500] : colors.brandPrimary[500] })}
                    <span className={clsx(
                        "text-label-sm",
                        {
                            "text-brandPrimary-500 font-semibold": index === props.value,
                            "text-neutral-600": index !== props.value,
                        }
                    )}
                    >
                        {item.label}
                    </span>
                </div>
            ))}
        </div>
    );
}
