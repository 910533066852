import clsx from "clsx";
import { useEffect, useState } from "react";
import { roleDepartment } from "../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import { colors } from "../../../ui/colors";
import { CheckCircleIcon } from "../../../ui/icons/checkCircle";
import { EditIcon } from "../../../ui/icons/edit";
import { LinkedinIcon } from "../../../ui/icons/linkedin";
import { MailIcon } from "../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../ui/icons/phoneCall";
import { PlusIcon } from "../../../ui/icons/plus";
import Avatar from "../../../ui/molecules/avatar";
import Button from "../../../ui/molecules/button";
import { GroupSelectCustom } from "../../../ui/molecules/groupSelect";
import Input from "../../../ui/molecules/input/Input";
import { SelectCustom } from "../../../ui/molecules/select";
import { Spinner } from "../../../ui/molecules/spinner";
import Toggle from "../../../ui/molecules/toggle";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { ContactStateEnum, generateDefaultValue, generateOutcomeOptions } from "../../targetList/dto";
import {
  changeContactState,
  findTargetListById,
  setChangeContactStateStatus,
} from "../../targetList/slice";
import { EditCompanyForm } from "../companies/editCompanyForm";
import {
  editCompany,
  findCompany,
  setEditCompanyAddress,
  setEditCompanyAtecos,
  setEditCompanyCap,
  setEditCompanyCity,
  setEditCompanyEmail,
  setEditCompanyEmployeesNumber,
  setEditCompanyName,
  setEditCompanyPhone,
  setEditCompanyProvince,
  setEditCompanyRegion,
  setEditCompanyRevenue,
  setEditCompanyState,
  setEditCompanyVat,
  setEditCompanyWebSite
} from "../companies/slice";
import {
  editPerson,
  findPerson,
  setEditPersonCompanyId,
  setEditPersonDepartment,
  setEditPersonEmail,
  setEditPersonLinkedinProfile,
  setEditPersonName,
  setEditPersonPhone,
  setEditPersonPrivacy,
  setEditPersonRole,
  setEditPersonSurname,
  setSelectedPerson,
  setTabValueDetailsPerson
} from "./slice";
import { EditPersonNotes } from "./notes";
import { findUsersAvatarsList, getUsersByUserIds, setFindUsersByUserIdStatus } from "../../users/slice";

export function EditPerson() {
  const peopleState = useAppSelector((state) => state.people);
  const usersState = useAppSelector((state) => state.users);
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);
  const [direction, setDirection] = useState<"left" | "right">("left");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (peopleState.selectedPerson !== undefined) {
      dispatch(findPerson(peopleState.selectedPerson));
    }
  }, [peopleState.selectedPerson]);

  const notes = targetListsState.findTargetListResponse?.personContacts &&
    targetListsState.findTargetListResponse?.personContacts[
    peopleState.findPersonResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.personContacts[
      peopleState.findPersonResponse?.id!
    ].notes : null

  useEffect(() => {
    if (peopleState.findPersonStatus === "successfully") {
      dispatch(setEditPersonName(peopleState.findPersonResponse?.name));
      dispatch(setEditPersonSurname(peopleState.findPersonResponse?.surname));
      dispatch(setEditPersonRole(peopleState.findPersonResponse?.role));
      dispatch(
        setEditPersonDepartment(peopleState.findPersonResponse?.department)
      );
      dispatch(setEditPersonPrivacy(peopleState.findPersonResponse?.privacy));
      dispatch(setEditPersonPhone(peopleState.findPersonResponse?.phone));
      dispatch(setEditPersonEmail(peopleState.findPersonResponse?.email));
      dispatch(
        setEditPersonLinkedinProfile(
          peopleState.findPersonResponse?.linkedinProfile
        )
      );
      dispatch(
        setEditPersonCompanyId(peopleState.findPersonResponse?.companyId)
      );
      dispatch(findCompany(peopleState.findPersonResponse?.companyId!));
      if (window.location.pathname.includes('target')) {
        dispatch(getUsersByUserIds(notes?.map(note => note.authorUserId) ?? []))
      }
    }
  }, [peopleState.findPersonStatus]);

  useEffect(() => {
    if (usersState.findUsersByUserIdStatus === 'successfully') {
      dispatch(findUsersAvatarsList(usersState.findUsersByUserIdResponse.map(user => ({ userId: user.userId, objectId: user.avatarObjectId }))))
      dispatch(setFindUsersByUserIdStatus('idle'))
    }
  }, [usersState.findUsersByUserIdStatus])

  useEffect(() => {
    if (companiesState.findCompanyStatus === "successfully") {
      dispatch(setEditCompanyVat(companiesState.findCompanyResponse?.vat));
      dispatch(setEditCompanyName(companiesState.findCompanyResponse?.name));
      dispatch(
        setEditCompanyAtecos(companiesState.findCompanyResponse?.atecos)
      );
      dispatch(
        setEditCompanyAddress(companiesState.findCompanyResponse?.address)
      );
      dispatch(
        setEditCompanyRegion(companiesState.findCompanyResponse?.countryRegion)
      );
      dispatch(setEditCompanyState(companiesState.findCompanyResponse?.state));
      dispatch(setEditCompanyCity(companiesState.findCompanyResponse?.city));
      dispatch(
        setEditCompanyProvince(companiesState.findCompanyResponse?.province)
      );
      dispatch(setEditCompanyCap(companiesState.findCompanyResponse?.zipCode));
      dispatch(
        setEditCompanyWebSite(companiesState.findCompanyResponse?.website)
      );
      dispatch(setEditCompanyEmail(companiesState.findCompanyResponse?.email));
      dispatch(
        setEditCompanyRevenue(companiesState.findCompanyResponse?.revenue)
      );
      dispatch(setEditCompanyPhone(companiesState.findCompanyResponse?.phone));
      dispatch(
        setEditCompanyEmployeesNumber(
          companiesState.findCompanyResponse?.employeesNumber
        )
      );
    }
  }, [companiesState.findCompanyStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListsState.changeContactStateStatus === "failed" ||
      targetListsState.changeContactStateStatus === "successfully"
    ) {
      if (targetListsState.changeContactStateStatus === "successfully") {
        dispatch(
          findTargetListById(targetListsState.findTargetListResponse?.id!)
        );
        dispatch(setChangeContactStateStatus("idle"));
        dispatch(setSelectedPerson(peopleState.findPersonResponse?.id));
      }
      timeoutId = setTimeout(() => {
        dispatch(setChangeContactStateStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListsState.changeContactStateStatus]);

  const contactState = targetListsState.findTargetListResponse?.personContacts &&
    targetListsState.findTargetListResponse?.personContacts[
    peopleState.findPersonResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.personContacts[
      peopleState.findPersonResponse?.id!
    ].contactState : ContactStateEnum.DRAFT

  const stateInfo = targetListsState.findTargetListResponse?.personContacts &&
    targetListsState.findTargetListResponse?.personContacts[
    peopleState.findPersonResponse?.id!
    ] ?
    targetListsState.findTargetListResponse?.personContacts[
      peopleState.findPersonResponse?.id!
    ].stateInfo : null

  return (
    <div className="h-full overflow-auto">
      {peopleState.findPersonStatus === "loading" ||
        peopleState.editPersonStatus === "loading" ||
        companiesState.editCompanyStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          className={clsx("h-full", {
            "border-t-green-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.LEADTAKEN,
            "border-t-orange-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.TORECALL,
            "border-t-red-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.KO,
            "border-t-neutral-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.INCORRECT,
            "border-t-brandPrimary-500 border-t-4":
              window.location.pathname.includes("target-list") &&
              contactState === ContactStateEnum.DRAFT,
          })}
        >
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={
                (peopleState.findPersonResponse?.name ?? "-") +
                " " +
                (peopleState.findPersonResponse?.surname ?? "-")
              }
              secondRow={peopleState.findPersonResponse?.role}
              // thirdRow={peopleState.findPersonResponse?.companyName}
              size="md"
              altTextInitials={
                (peopleState.findPersonResponse?.name[0] && peopleState.findPersonResponse?.name[0]?.toUpperCase()) ??
                "" +
                (peopleState.findPersonResponse?.surname[0] && peopleState.findPersonResponse?.surname[0]?.toUpperCase()) ??
                ""
              }
            />
            <div className="flex items-start gap-4">
              <div
                className="cursor-pointer rotate-45"
                onClick={() => dispatch(setSelectedPerson(undefined))}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          {
            window.location.pathname.includes("target-list") &&
            <div className="w-full px-6 py-3 flex items-center border-y border-y-brandPrimary-100 justify-between mt-1 bg-brandPrimary-50">
              <div className="gap-2 flex">
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = 'mailto:' + peopleState.findPersonResponse?.email;
                    a.click();
                  }}
                  color={"blue"}
                  icon={<MailIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = 'tel:' + peopleState.findPersonResponse?.phone;
                    a.click();
                  }}
                  icon={<PhoneCallIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  color={"blue"}
                  onClick={() => {
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.rel = "noreferrer"
                    a.target = "_blank"
                    a.href = peopleState.findPersonResponse?.linkedinProfile!;
                    a.click();
                  }}
                  icon={<LinkedinIcon color={""} size={0} />}
                />
                <Button
                  size={"sm"}
                  iconPosition={"only"}
                  variant={"outline"}
                  onClick={() => dispatch(setTabValueDetailsPerson(2))}
                  color={"blue"}
                  icon={<EditIcon color={""} size={0} />}
                />
              </div>
              <Button
                size={"sm"}
                iconPosition={contactState === ContactStateEnum.LEADTAKEN ? "left" : 'off'}
                variant={"outline"}
                label="Lead preso"
                color={contactState === ContactStateEnum.LEADTAKEN ? "green" : 'gray'}
                icon={<CheckCircleIcon color={""} size={0} />}
                onClick={() => {
                  if (contactState !== ContactStateEnum.LEADTAKEN) {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "persons",
                        contactState: ContactStateEnum.LEADTAKEN,
                        contactId: peopleState.findPersonResponse?.id!,
                        stateInfo: null
                      })
                    )
                  }
                }}
              />
            </div>
          }
          {
            window.location.pathname.includes("target-list") &&
            <div className="w-full px-6 py-3 flex items-center border-b border-b-brandPrimary-100 justify-between mt-1">
              <GroupSelectCustom
                disabled={peopleState.findPersonResponse && peopleState.findPersonResponse?.contactCount >= 3}
                menuPortalTargetIsNotBody
                placeholder={"Selezion un esito"}
                label="Esito"
                onChange={(e) => {
                  console.log(e)
                  if (e === ContactStateEnum.DRAFT || e === ContactStateEnum.LEADTAKEN) {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "persons",
                        contactState: e,
                        contactId: peopleState.findPersonResponse?.id!,
                        stateInfo: null
                      })
                    );
                  } else {
                    dispatch(
                      changeContactState({
                        targetListId:
                          targetListsState.findTargetListResponse?.id!,
                        type: "persons",
                        contactState: e && e !== undefined && typeof e === 'string' ? e.split('::')[0] as ContactStateEnum : ContactStateEnum.DRAFT,
                        contactId: peopleState.findPersonResponse?.id!,
                        stateInfo: e && e !== undefined && typeof e === 'string' ? e.split('::')[1] : null
                      })
                    );
                  }
                }}
                defaultValue={generateDefaultValue(contactState, stateInfo)}
                options={generateOutcomeOptions(peopleState.findPersonResponse?.contactCount!, contactState)}
              />
            </div>
          }
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => dispatch(setTabValueDetailsPerson(e))}
            value={peopleState.tabValueDetailsPerson}
            items={[
              {
                icon: <></>,
                label: "Anagrafica",
              },
              {
                icon: <></>,
                label: "Azienda",
              },
              {
                icon: <></>,
                label: "Note",
                hide: !window.location.pathname.includes('target')
              }
            ]}
          />
          <div>
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <div key={0} className="px-6 py-4 flex flex-col gap-4">
                  <div className="flex gap-2">
                    <Input
                      label="Nome"
                      disabled={peopleState.findPersonResponse?.active}
                      value={peopleState.editPersonRequest?.name ?? ""}
                      onChangeText={(text) => dispatch(setEditPersonName(text))}
                    />
                    <Input
                      label="Cognome"
                      disabled={peopleState.findPersonResponse?.active}
                      value={peopleState.editPersonRequest?.surname ?? ""}
                      onChangeText={(text) =>
                        dispatch(setEditPersonSurname(text))
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-[6px]">
                    <SelectCustom
                      isClearable
                      label="Ruolo"
                      menuPortalTargetIsNotBody
                      disabled={peopleState.findPersonResponse?.active}
                      onChange={(e) => {
                        if (e === undefined || typeof e === "string") {
                          dispatch(setEditPersonRole(e));
                        }
                      }}
                      defaultValue={
                        peopleState.editPersonRequest?.role ?? undefined
                      }
                      placeholder={"Cerca per ruolo"}
                      options={groupBy(roleDepartment, "role").map((sector) => ({
                        value: sector.key,
                        label: sector.key,
                      }))}
                      noOptionsMessage="Non sono presenti ruoli."
                    />
                  </div>
                  <Input
                    label="Reparto"
                    disabled
                    value={
                      roleDepartment.find(
                        (role) => role.role === peopleState.editPersonRequest.role
                      )?.department || ""
                    }
                    onChangeText={(text) =>
                      dispatch(setEditPersonDepartment(text))
                    }
                  />
                  <Toggle
                    disabled={peopleState.findPersonResponse?.active}
                    placeholder="Permessi privacy"
                    checked={peopleState.editPersonRequest?.privacy ?? false}
                    onChange={function (value: boolean): void {
                      dispatch(setEditPersonPrivacy(value));
                    }}
                  />
                  <Input
                    label="Cellulare"
                    endIcon={<PhoneCallIcon color={""} size={0} />}
                    value={peopleState.editPersonRequest?.phone ?? ""}
                    onChangeText={(text) => dispatch(setEditPersonPhone(text))}
                  />
                  <Input
                    label="Mail"
                    endIcon={<MailIcon color={""} size={0} />}
                    value={peopleState.editPersonRequest?.email ?? ""}
                    onChangeText={(text) => dispatch(setEditPersonEmail(text))}
                  />
                  <Input
                    label="LinkedIn"
                    endIcon={<LinkedinIcon color={""} size={0} />}
                    value={peopleState.editPersonRequest?.linkedinProfile ?? ""}
                    onChangeText={(text) =>
                      dispatch(setEditPersonLinkedinProfile(text))
                    }
                  />
                </div>,
                <div key={1} className="">
                  <div
                    className={clsx("flex items-center justify-center h-[50%]", {
                      hidden: companiesState.findCompanyStatus !== "loading",
                    })}
                  >
                    <Spinner />
                  </div>
                  <div
                    className={clsx("flex items-center justify-center pt-8", {
                      hidden: companiesState.findCompanyStatus !== "failed",
                    })}
                  >
                    <span className="text-text-sm text-neutral-800">
                      Non è stata trovata nessuna azienda associata al contatto.
                    </span>
                  </div>
                  <div
                    className={clsx("flex items-center justify-center pb-4", {
                      hidden: companiesState.findCompanyStatus !== "successfully",
                    })}
                  >
                    <EditCompanyForm />
                  </div>
                </div>,
                <EditPersonNotes key={2} />
              ]}
              value={peopleState.tabValueDetailsPerson}
              setValue={(e) => dispatch(setTabValueDetailsPerson(e))}
            />
          </div>
          <div className={clsx("p-6 justify-end items-center gap-2", { 'hidden': peopleState.tabValueDetailsPerson === 2, 'flex': peopleState.tabValueDetailsPerson !== 2 })}>
            <Button
              size={"sm"}
              iconPosition={"off"}
              variant={"outline"}
              color={"blue"}
              label="Annulla"
              onClick={() => dispatch(setSelectedPerson(undefined))}
            />
            <Button
              size={"sm"}
              iconPosition={"off"}
              variant={"solid"}
              color={"blue"}
              label="Salva"
              onClick={() => {
                dispatch(
                  editCompany({
                    data: companiesState.editCompanyRequest,
                    id: companiesState.findCompanyResponse?.id!,
                  })
                );
                dispatch(
                  editPerson({
                    data: peopleState.editPersonRequest,
                    id: peopleState.findPersonResponse?.id!,
                  })
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
