export const peopleColumns = [
  {
    column: "Contatto",
    visible: true,
    hideable: false,
  },
  {
    column: "Telefono",
    visible: true,
    hideable: false,
  },
  {
    column: "Email",
    visible: true,
    hideable: false,
  },
  {
    column: "Linkedin",
    visible: true,
    hideable: false,
  },
  {
    column: "Ruolo",
    visible: true,
    hideable: false,
  },
  {
    column: "Azienda",
    visible: true,
    hideable: false,
  },
  {
    column: "Reparto",
    visible: true,
    hideable: false,
  },
  {
    column: "Stato",
    visible: true,
    hideable: false,
  },
  {
    column: "Privacy",
    visible: true,
    hideable: false,
  },
];

export const companyColumns = [
  { column: "Azienda", visible: true, hideable: false },
  { column: "Fatturato", visible: true, hideable: false },
  { column: "Telefono", visible: true, hideable: false },
  { column: "Stato", visible: true, hideable: false },
  { column: "Email", visible: true, hideable: true },
  { column: "Sede azienda", visible: true, hideable: true },
  { column: "P. IVA", visible: true, hideable: true },
  { column: "Cod. ATECO", visible: true, hideable: false },
  { column: "Settore", visible: true, hideable: false },
  { column: "Categoria", visible: true, hideable: true },
  { column: "Dipendenti", visible: true, hideable: true },
  { column: "Sito web", visible: true, hideable: true },
];

export const targetListColumns = [
  { column: "Nome", visible: true, hideable: false },
  { column: "Commerciali", visible: true, hideable: false },
  { column: "Team Leader", visible: true, hideable: false },
  { column: "Settore", visible: true, hideable: false },
  { column: "Contatti", visible: true, hideable: false },
  { column: "Lead", visible: true, hideable: false },
  { column: "Da lavorare", visible: true, hideable: false },
  { column: "Stato", visible: true, hideable: false },
];

export const targetListPersonColumns = [
  { column: "Nome referente", visible: true, hideable: false },
  { column: "Cognome referente", visible: true, hideable: false },
  { column: "Ruolo", visible: true, hideable: true },
  { column: "Data di contatto", visible: true, hideable: false },
  { column: "Esito", visible: true, hideable: false },
  { column: "Note", visible: true, hideable: true },
  { column: "Smartphone", visible: true, hideable: true },
  { column: "Azienda", visible: true, hideable: false },
  { column: "Reparto", visible: true, hideable: true },
  { column: "Privacy", visible: true, hideable: true },
  { column: "Email", visible: true, hideable: false },
  { column: "Linkedin", visible: true, hideable: true },
];

export const targetListCompanyColumns = [
  { column: "Nome azienda", visible: true, hideable: false },
  { column: "Data di contatto", visible: true, hideable: false },
  { column: "Fatturato", visible: true, hideable: false },
  { column: "Telefono", visible: true, hideable: false },
  { column: "Esito", visible: true, hideable: false },
  { column: "Note", visible: true, hideable: false },
  { column: "Email", visible: true, hideable: false },
  { column: "Dipendenti", visible: true, hideable: false },
  { column: "P. IVA", visible: true, hideable: false },
  { column: "Sede azienda", visible: true, hideable: false },
  { column: "Sito web", visible: true, hideable: false },
  { column: "Cod. ATECO", visible: true, hideable: false },
  { column: "Settore", visible: true, hideable: false },
  { column: "Categoria", visible: true, hideable: false },
];

export const signOptions = [
  { label: "Uguale a", value: "=" },
  { label: "Maggiore di", value: ">" },
  { label: "Minore di", value: "<" },
  { label: "Compreso tra", value: "><" },
];

export function checkPreferences(actual: { column: string, visible: boolean, hideable: boolean }[] | null, ordinary: { column: string, visible: boolean, hideable: boolean }[]): boolean {
  if (actual === null) {
    return false
  }
  if (actual.length !== ordinary.length) {
    return false
  } else {
    const sortedActual = [...actual].map(actual => actual.column).sort()
    const sortedOrdinary = [...ordinary].map(ordinary => ordinary.column).sort()
    return sortedActual.every((value, index) => value === sortedOrdinary[index]);
  }
}

export interface ImportCSVResponseDTO {
  base64CsvMissed: string
  ids: number[];
  missed: number[];
}

export interface SearchPlacesDTO {
  type: string;
  features: PlaceFeatureDTO[];
  attribution: string;
}

export interface PlaceFeatureDTO {
  type: "Feature";
  id: string;
  geometry: {
    type: "Point";
    coordinates: number[];
  };
  properties: {
    mapbox_id: string;
    feature_type: string;
    full_address: string;
    name: string;
    name_preferred: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
    bbox: number[];
    context: {
      country: {
        mapbox_id: string;
        name: string;
        country_code: string;
        country_code_alpha_3: string;
        wikidata_id: string;
        translations: {
          it: {
            language: string;
            name: string;
          };
        };
      };
    };
  };
}
