import clsx from "clsx";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { Banner } from "../../../../../ui/organisms/banner";
import { Filters } from "../../../../database/people/filters";
import { PeopleHorizontalFilters } from "../../../../database/people/filters/horizontalFilters";
import {
  setEditPersonStatus,
  setFindPersonStatus,
  setSelectedPerson,
} from "../../../../database/people/slice";
import { findAllTArgetLists, findTargetListById } from "../../../../targetList/slice";
import { TargetPeopleList } from "./list";
import { setEditCompanyStatus } from "../../../../database/companies/slice";

export function TargetListPeopleList() {
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const targetListState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeoutIdIddd: NodeJS.Timeout;
    if (
      peopleState.editPersonStatus === "failed" ||
      peopleState.editPersonStatus === "successfully"
    ) {
      if (peopleState.editPersonStatus === "successfully") {
        // dispatch(setSelectedPerson(undefined));
        dispatch(setFindPersonStatus('idle'))
        dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      }
      dispatch(setEditPersonStatus("idle"));
      timeoutIdIddd = setTimeout(() => {
        console.log('timeout')
        dispatch(setEditPersonStatus("idle"));
        dispatch(setEditCompanyStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutIdIddd) {
        clearTimeout(timeoutIdIddd);
      }
    };
  }, [peopleState.editPersonStatus]);

  // useEffect(() => {
  //   let timeoutId: NodeJS.Timeout;
  //   if (
  //     companiesState.editCompanyStatus === "failed" ||
  //     companiesState.editCompanyStatus === "successfully"
  //   ) {
  //     if (companiesState.editCompanyStatus === "successfully") {
  //       dispatch(findAllCompanies(companiesState.filters));
  //     }
  //     timeoutId = setTimeout(() => {
  //       dispatch(setEditCompanyStatus("idle"));
  //     }, 5000);
  //   }
  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [companiesState.editCompanyStatus]);

  return (
    <div className="max-h-full w-full flex h-full">
      <div className="fixed flex flex-col gap-4 right-6 top-6">
        <Banner
          label={"Contatto aggiornato."}
          visible={peopleState.editPersonStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setEditPersonStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={"Si è verificato un errore durante la modifica del contatto."}
          visible={peopleState.editPersonStatus === "failed"}
          closeAction={function (): void {
            dispatch(setEditPersonStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="flex w-full">
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {peopleState.findAllStatus === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full">
              <PeopleHorizontalFilters />
              <TargetPeopleList />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
