import clsx from "clsx";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import Button from "../../../ui/molecules/button";
import Checkbox from "../../../ui/molecules/checkbox";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { Pagination } from "../../../ui/organisms/pagination/index";
import { Cell } from "./cell";
import { Head } from "./head";
import {
  findEmployees,
  setFindAllCompaniesFiltersActive,
  setFindAllCompaniesFiltersPage,
  setIdToDeleteCompany,
  setSelectedCompany,
  setSelectedCompanyNameForEmployees,
  setSelectedIdsCompanies,
  setShowPopupCompanies,
  setTabValueDetailsCompany,
} from "./slice";
import { NotFound } from "../../../ui/organisms/notFound";

export function CompaniesList() {
  const companiesState = useAppSelector((state) => state.companies);
  const preferencesState = useAppSelector((state) => state.preferences);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companiesState.deleteCompanyStatus === "successfully") {
      dispatch(setIdToDeleteCompany(undefined));
    }
  }, [companiesState.deleteCompanyStatus]);

  return (
    <div style={{ height: 'calc(100% - 63.8px)' }}>
      <div className="flex items-center gap-10 px-4">
        <span className="text-heading-xs font-semibold text-neutral-800">
          Aziende
        </span>
        <div className="flex justify-end w-full items-center gap-2 h-16">
          <div
            onClick={() => {
              if (companiesState.filters.active === "false") {
                dispatch(setFindAllCompaniesFiltersActive(undefined))
              } else {
                dispatch(setFindAllCompaniesFiltersActive("false"))
              }
            }}
            className={clsx("cursor-pointer flex items-center px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-400", {
              'border-[2px] border-brandPrimary-600': companiesState.filters.active === "false"
            })}
          >
            <span className="text-brandPrimary-800 text-label-sm font-medium">
              Inattive
            </span>
            <div className="bg-brandPrimary-100 flex items-center justify-center text-brandPrimary-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
              {
                companiesState.companiesCountResponse.toActivate
              }
            </div>
          </div>
        </div>
      </div>
      {companiesState.findAllResponse?.data.length === 0 ||
        companiesState.findAllResponse?.data === null ? (
        <div style={{ height: 'calc(100% - 128px)' }}>
          <NotFound />
        </div>
      ) : (
        <div className="overflow-auto" style={{ height: 'calc(100% - 48px - 68px)' }}>
          <table className="w-full">
            <thead className="h-[48px] font-semibold text-neutral-500 uppercase text-text-xs sticky z-30 top-0">
              <tr className="bg-neutral-50">
                <th className="text-left w-10">
                  <Checkbox
                    shape={"square"}
                    checked={
                      companiesState.findAllResponse?.data.every((company) => companiesState.selectedIds.includes(company.id))!
                    }
                    onCheck={function (value: boolean): void {
                      if (
                        companiesState.findAllResponse?.data
                          .every((company) => companiesState.selectedIds.includes(company.id))
                      ) {
                        let newSelected = [...companiesState.selectedIds];
                        companiesState.findAllResponse?.data.forEach((company) => {
                          newSelected = newSelected.filter(
                            (id) => company.id !== id
                          );
                        });
                        dispatch(setSelectedIdsCompanies([...newSelected]));
                      } else {
                        dispatch(setSelectedIdsCompanies([
                          ...companiesState.selectedIds,
                          ...companiesState.findAllResponse?.data
                            .filter(
                              (company) =>
                                !companiesState.selectedIds.includes(company.id)
                            )
                            .map((company) => company.id)!,
                        ]));
                      }
                    }}
                  />
                </th>
                {preferencesState.findAllResponse?.data[0].companyPreferences
                  .filter((column) => column.visible)
                  .map((column, index) => (
                    <Head
                      key={column.column}
                      column={column.column}
                      index={index}
                    />
                  ))}
                <th className={clsx("text-left")}></th>
                <th className="text-left">
                </th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {companiesState.findAllResponse?.data.map((company, index) => (
                <tr
                  className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                  key={company.email + index}
                >
                  <td className="text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      checked={companiesState.selectedIds.includes(company.id)}
                      onCheck={function (value: boolean): void {
                        if (companiesState.selectedIds.includes(company.id)) {
                          dispatch(setSelectedIdsCompanies([
                            ...companiesState.selectedIds.filter((id) => id !== company.id),
                          ]));
                        } else {
                          dispatch(setSelectedIdsCompanies([...companiesState.selectedIds, company.id]));
                        }
                      }}
                    />
                  </td>
                  {preferencesState.findAllResponse?.data[0].companyPreferences
                    .filter((column) => column.visible)
                    .map((column, index) => (
                      <Cell
                        key={company.id + "-" + column.column}
                        column={column.column}
                        index={index}
                        person={company}
                      />
                    ))}
                  <td className="">
                    <Button
                      size={"sm"}
                      iconPosition={"off"}
                      disabled={!company.employeesId}
                      variant={"outline"}
                      color={"blue"}
                      label="Mostra dipendenti"
                      onClick={() => {
                        dispatch(setSelectedCompany(company.id))
                        dispatch(findEmployees(company.employeesId));
                        dispatch(
                          setSelectedCompanyNameForEmployees(company.name)
                        );
                        dispatch(setTabValueDetailsCompany(1))
                      }}
                    />
                  </td>
                  <td className="w-[20px] cursor-pointer">
                    <Dropdown
                      items={[
                        {
                          label: "Modifica",
                          icon: <EditIcon color={""} size={0} />,
                          onClick: () => {
                            dispatch(setTabValueDetailsCompany(0))
                            dispatch(setSelectedCompany(company.id))
                            dispatch(findEmployees(company.employeesId));
                            dispatch(
                              setSelectedCompanyNameForEmployees(company.name)
                            );
                          },
                        },
                        {
                          label: "Cancella",
                          icon: <TrashIcon color={""} size={0} />,
                          onClick: () => {
                            dispatch(setSelectedIdsCompanies([company.id]))
                            dispatch(setShowPopupCompanies(false))
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        found={companiesState.findAllResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={companiesState.findAllResponse?.totalPage || 0}
        currentPage={companiesState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllCompaniesFiltersPage(page));
        }}
      />
    </div>
  );
}
