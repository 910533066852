import React, { cloneElement } from "react";
import "./Pills.css";
import { PillsProps, colorKey, pillLabelSizeMap, pillSizeMap, pillVariantKey } from "./Pills.types";

const Pills: React.FC<PillsProps> = ({
    ...props
}) => {
    return (
        <div
            style={{
                ...pillSizeMap.get(props.size),
                ...colorKey(props.color).pillContainerVariant.get(pillVariantKey(props.emphasis, props.outline)),
                ...{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: 4, borderWidth: '1px', borderStyle: 'solid', maxWidth: 'min-content', whiteSpace: 'nowrap' },
            }}
        >
            {
                props.startIcon && cloneElement(props.startIcon, { color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) })
            }
            <span
                className="crop-text max-w-min"
                style={{
                    ...pillLabelSizeMap.get(props.size),
                    ...{ color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) }
                }}
            >
                {props.label}
            </span>
            {
                props.endIcon && cloneElement(props.endIcon, { color: colorKey(props.color).contentColor.get(pillVariantKey(props.emphasis, props.outline)) })
            }
        </div>
    )
};

export default Pills;