import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import TextArea from "../../../ui/molecules/textArea";
import { Banner } from "../../../ui/organisms/banner";
import { ProjectStateEnum } from "../dto";
import { setNewProjectCommercialIds, setNewProjectCustomerId, setNewProjectDescription, setNewProjectName, setNewProjectStatus, setNewProjectTeamLeaderId } from "../slice";
import Button from "../../../ui/molecules/button";
import { PlusCircleIcon } from "../../../ui/icons/plusCircle";
import { setCreateFromProject, setCreateUserRole, setCreateUserStatus, setValidateNewUserStatus } from "../../users/slice";
import { UserRole } from "../../users/dto";
import { useNavigate } from "react-router-dom";

export function Form() {
    const preferencesState = useAppSelector(state => state.preferences)
    const projectsState = useAppSelector(state => state.projects)
    const usersState = useAppSelector(state => state.users)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const customer = usersState.findClientsResponse?.data.find(client => client.id === projectsState.newProjectRequest.customerId)

    return (
        <div className="gap-[24px] h-full max-h-full flex flex-col w-[100%] p-[24px]">
            <Banner
                type="error"
                visible={projectsState.newProjectStatus === 'failed'}
                label={"Si è verificato un errore durante la creazione del progetto"}
                closeAction={function (): void {
                    dispatch(setNewProjectStatus('idle'))
                }}
            />
            <div className="p-[24px] gap-[24px] h-full max-h-full overflow-auto flex flex-col bg-white shadow-sm rounded-[12px]">
                <div className="flex gap-6 items-flex-start">
                    <Input
                        label="Nome del progetto"
                        defaultValue={projectsState.newProjectRequest.name}
                        onChangeText={e => dispatch(setNewProjectName(e))}
                        error={projectsState.validateNewProject.errors.name}
                        supportingText={projectsState.validateNewProject.errors.name ? "Inserire il nome del progetto." : ''}
                    />
                    <div className="flex w-full items-end">
                        <SelectCustom
                            isMulti
                            noOptionsMessage="Non è presente nessun commerciale."
                            defaultValue={projectsState.newProjectRequest.commercialIds}
                            placeholder={"Seleziona i commericali"}
                            onChange={(e) => dispatch(setNewProjectCommercialIds(e))}
                            options={usersState.findCommercialsResponse?.data.map(commercial => ({ label: commercial.name + ' ' + commercial.surname, value: commercial.id }))!}
                            label="Commerciali"
                            error={projectsState.validateNewProject.errors.commercialIds}
                            errorLabel={projectsState.validateNewProject.errors.commercialIds ? "Selezionare almeno un commerciale." : ''}
                        />
                        <Button
                            onClick={() => {
                                dispatch(setCreateUserStatus('idle'))
                                dispatch(setCreateFromProject(UserRole.commercial))
                                navigate('/new-user')
                            }}
                            size={"md"}
                            iconPosition={"only"}
                            variant={"ghost"}
                            color={"blue"}
                            icon={<PlusCircleIcon color={""} size={0} />}
                        />
                    </div>
                    <div className="flex w-full items-end">
                        <SelectCustom
                            noOptionsMessage="Non è presente nessun team leader."
                            placeholder={"Seleziona il team leader"}
                            options={usersState.findTeamLeadersResponse?.data.map(teamLeader => ({ label: teamLeader.name + ' ' + teamLeader.surname, value: teamLeader.id }))!}
                            defaultValue={projectsState.newProjectRequest.teamLeaderId}
                            onChange={e => dispatch(setNewProjectTeamLeaderId(e))}
                            label="Team Leader"
                            error={projectsState.validateNewProject.errors.teamLeaderId}
                            errorLabel={projectsState.validateNewProject.errors.teamLeaderId ? "Selezionare il team leader." : ''}
                        />
                        <Button
                            onClick={() => {
                                dispatch(setCreateUserStatus('idle'))
                                dispatch(setCreateFromProject(UserRole.team_leader))
                                navigate('/new-user')
                            }}
                            size={"md"}
                            iconPosition={"only"}
                            variant={"ghost"}
                            color={"blue"}
                            icon={<PlusCircleIcon color={""} size={0} />}
                        />
                    </div>
                </div>
                <div className="h-[1px] w-full bg-neutral-200" />
                <div className="flex gap-6 items-start">
                    <div className="flex w-full items-end">
                        <SelectCustom
                            noOptionsMessage="Non è presente nessun cliente."
                            placeholder={"Seleziona il cliente"}
                            defaultValue={projectsState.newProjectRequest.customerId}
                            onChange={(e) => dispatch(setNewProjectCustomerId(e))}
                            options={usersState.findClientsResponse?.data.map(client => ({ label: client.companyName ?? '', value: client.id }))!}
                            label="Ragione Sociale"
                            error={projectsState.validateNewProject.errors.customerId}
                            errorLabel={projectsState.validateNewProject.errors.customerId ? "Selezionare il cliente." : ''}
                        />
                        <Button
                            onClick={() => {
                                dispatch(setCreateUserStatus('idle'))
                                dispatch(setCreateFromProject(UserRole.customer))
                                navigate('/new-user')
                            }}
                            size={"md"}
                            iconPosition={"only"}
                            variant={"ghost"}
                            color={"blue"}
                            icon={<PlusCircleIcon color={""} size={0} />}
                        />
                    </div>
                    <Input label="P. IVA" disabled value={customer?.companyVat ?? ''} />
                    <Input label="Referente" disabled value={customer ? (customer?.name + ' ' + customer?.surname) : ''} />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom placeholder={""} disabled defaultValue={customer?.companyAtecoCode} options={preferencesState.findAllAtecosResponse.map(ateco => ({ value: ateco.atecoCode, label: ateco.atecoCode + ' - ' + ateco.atecoDescription }))} label="Cod. ATECO" />
                    <SelectCustom placeholder={""} disabled defaultValue={customer?.companySector} options={groupBy(preferencesState.findAllAtecosResponse, 'sector').map(sector => ({ label: sector.key, value: sector.key }))} label="Settore" />
                    <NumericFormat
                        prefix="€ "
                        value={customer?.companyRevenue ?? 0}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        customInput={Input}
                        id={"new-user-request-company-revenue"}
                        key={"new-user-request-company-revenue"}
                        placeholder={"Fatturato"}
                        label="Fatturato"
                        disabled
                    />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom placeholder={""} defaultValue={ProjectStateEnum.TOSTART} options={[{ label: "Da iniziare", value: ProjectStateEnum.TOSTART }]} disabled label="Stato" />
                    <Input disabled label="Data inizio" value={format(new Date(), 'dd/MM/yyyy')} />
                    <Input disabled label="Data fine" placeholder="Data fine" />
                </div>
                <TextArea label="Descrizione" defaultValue={projectsState.newProjectRequest.description} onChangeText={e => dispatch(setNewProjectDescription(e))} />
            </div>
        </div>
    )
}