import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { GlobeIcon } from "../../../../../ui/icons/globe";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { setFindAllCompaniesFiltersPage } from "../../../../database/companies/slice";
import { buildAddress } from "../../../../database/companies/cell";

interface Props {
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds } = props;
  const companiesState = useAppSelector((state) => state.companies);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  const isAlreadyIn = (id: string): boolean => {
    return targetListState.findTargetListResponse?.companyContacts &&
      targetListState.findTargetListResponse?.companyContacts[id] !== undefined
      ? true
      : false;
  };

  return (
    <div className="w-full" style={{ height: 'calc(100% - 176px)' }}>
      <div className="overflow-auto h-full">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase sticky top-0 z-10 text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    companiesState.findAllResponse?.data.every((company) =>
                      isAlreadyIn(company.id)
                    )!
                  }
                  checked={
                    companiesState.findAllResponse?.data
                      .filter((company) => !isAlreadyIn(company.id))
                      .every((company) => selectedIds.includes(company.id))! ||
                    companiesState.findAllResponse?.data.every((company) =>
                      isAlreadyIn(company.id)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      companiesState.findAllResponse?.data
                        .filter((company) => !isAlreadyIn(company.id))
                        .every((company) => selectedIds.includes(company.id))
                    ) {
                      let newSelected = [...selectedIds];
                      companiesState.findAllResponse?.data.forEach(
                        (company) => {
                          newSelected = newSelected.filter(
                            (id) => company.id !== id
                          );
                        }
                      );
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...companiesState.findAllResponse?.data
                          .filter(
                            (company) =>
                              !selectedIds.includes(company.id) &&
                              !isAlreadyIn(company.id)
                          )
                          .map((company) => company.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">telefono</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">sede azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">fatturato</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">P. IVA</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">ATECO</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">settore</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">categoria</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  <GlobeIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">dipendenti</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {companiesState.findAllResponse?.data.map((company) => (
              <tr
                className={clsx(
                  "h-[56px] border-b-[1px] border-b-neutral-100",
                  {
                    "bg-brandPrimary-100": selectedIds.includes(company.id),
                    "bg-white":
                      !selectedIds.includes(company.id) &&
                      !isAlreadyIn(company.id),
                    "bg-neutral-200 opacity-70":
                      !selectedIds.includes(company.id) &&
                      isAlreadyIn(company.id),
                  }
                )}
                key={company.id}
              >
                <td>
                  <Checkbox
                    shape={"square"}
                    disabled={isAlreadyIn(company.id)}
                    checked={
                      selectedIds.includes(company.id) ||
                      isAlreadyIn(company.id)
                    }
                    onCheck={function (value: boolean): void {
                      if (selectedIds.includes(company.id)) {
                        setSelectedIds([
                          ...selectedIds.filter((id) => id !== company.id),
                        ]);
                      } else {
                        setSelectedIds([...selectedIds, company.id]);
                      }
                    }}
                  />
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-brandPrimary-600">
                  {company.name ?? "-"}
                </td>
                <td>
                  {company.email ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"mailto:" + company.email}
                    >
                      <div className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                        {company.email}
                      </div>
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {company.phone ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"tel:" + company.phone[0]}
                    >
                      <div className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                        {company.phone[0] + (company.phone.length > 1 ? (', ..., + ' + (company.phone.length - 1)) : '')}
                      </div>
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {buildAddress(company.address, company.city, company.province, company.countryRegion, company.state, company.zipCode)}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.revenue ?? "-"}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.vat ?? "-"}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.atecos ? company.atecos[0]?.atecoCode : "-"}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.atecos ? company.atecos[0]?.sector : "-"}
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.atecos ? company.atecos[0]?.category : "-"}
                </td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={company.website ?? undefined}
                  >
                    <GlobeIcon
                      color={
                        company.website
                          ? colors.neutral[400]
                          : colors.neutral[200]
                      }
                      size={20}
                    />
                  </a>
                </td>
                <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                  {company.employeesNumber ?? "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        found={companiesState.findAllResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={companiesState.findAllResponse?.totalPage || 0}
        currentPage={companiesState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllCompaniesFiltersPage(page));
        }}
      />
    </div>
  );
}
