import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { ArrowRightIcon } from "../../../../ui/icons/arrowRight";
import { UploadIcon } from "../../../../ui/icons/upload";
import Button from "../../../../ui/molecules/button";
import { SelectCustom } from "../../../../ui/molecules/select";
import { Spinner } from "../../../../ui/molecules/spinner";
import { PeopleHeader } from "../dto";
import { importCSV } from "../slice";

interface Props {
  step: number;
  setStep: (value: number) => void;
  name: string;
  delimiter: string;
  fields: string[];
  file: File | null;
  rows: Record<string, any>[];
}

export function Step2(props: Props) {
  const dispatch = useAppDispatch();
  const peopleState = useAppSelector((state) => state.people);

  const [headers, setHeaders] = useState<PeopleHeader>({
    name: [],
    surname: [],
    role: [],
    department: [],
    phone: [],
    email: [],
    linkedinProfile: [],
    privacy: [],
    companyName: [],
    companyVat: [],
    companyPhone: [],
  });

  useEffect(() => {
    const newHeaders = { ...headers };

    const name = props.fields.find((value) => value.toLowerCase() === "nome");
    if (name) {
      newHeaders.name = [name];
    }
    const surname = props.fields.find(
      (value) => value.toLowerCase() === "cognome"
    );
    if (surname) {
      newHeaders.surname = [surname];
    }
    const role = props.fields.find((value) => value.toLowerCase() === "ruolo");
    if (role) {
      newHeaders.role = [role];
    }
    const department = props.fields.find(
      (value) => value.toLowerCase() === "settore"
    );
    if (department) {
      newHeaders.department = [department];
    }
    const phone = props.fields.find(
      (value) => value.toLowerCase() === "smartphone"
    );
    if (phone) {
      newHeaders.phone = [phone];
    }
    const email = props.fields.find((value) => value.toLowerCase() === "email");
    if (email) {
      newHeaders.email = [email];
    }
    const linkedinProfile = props.fields.find(
      (value) => value.toLowerCase() === "profilo linkedin"
    );
    if (linkedinProfile) {
      newHeaders.linkedinProfile = [linkedinProfile];
    }
    const privacy = props.fields.find(
      (value) => value.toLowerCase() === "privacy"
    );
    if (privacy) {
      newHeaders.privacy = [privacy];
    }
    const companyName = props.fields.find(
      (value) => value.toLowerCase() === "nome azienda"
    );
    if (companyName) {
      newHeaders.companyName = [companyName];
    }
    const companyVat = props.fields.find(
      (value) => value.toLowerCase() === "p.iva azienda"
    );
    if (companyVat) {
      newHeaders.companyVat = [companyVat];
    }
    const companyPhone = props.fields.find(
      (value) => value.toLowerCase() === "telefono azienda"
    );
    if (companyPhone) {
      newHeaders.companyPhone = [companyPhone];
    }

    setHeaders(newHeaders);
  }, [props.fields]);

  const selectedFields = Object.values(headers)
    .flat()
    .filter((field) => field);

  const getFilteredOptions = (currentHeaderKey: keyof PeopleHeader) => {
    const selectedHeaderFields = headers[currentHeaderKey];

    return props.fields
      .filter(
        (field) =>
          !selectedFields.includes(field) ||
          selectedHeaderFields.includes(field)
      )
      .map((field) => ({
        label: field,
        value: field,
      }));
  };

  const formData = new FormData();

  return (
    <div className="h-full max-h-full overflow-auto">
      <div className="p-4 border-y h-16 border-y-neutral-100 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            onClick={() => props.setStep(0)}
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
          >
            Aggiungi da file CSV
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span className="text-text-md font-bold text-neutral-800">
            Mappatura campi
          </span>
        </div>
        {peopleState.importCSVStatus === "loading" ? (
          <Spinner />
        ) : (
          <Button
            onClick={() => {
              const filteredHeaders = Object.fromEntries(
                Object.entries(headers).filter(
                  ([, value]) => Array.isArray(value) && value.length > 0
                )
              );
              formData.delete("file");
              formData.delete("headers");
              formData.delete("delimiter");
              if (props.file !== null) formData.append("file", props.file);
              formData.append("headers", JSON.stringify(filteredHeaders));
              formData.append("delimiter", props.delimiter);
              dispatch(importCSV(formData));
            }}
            disabled={!props.file}
            size={"sm"}
            iconPosition={"right"}
            variant={"outline"}
            color={"blue"}
            label="Importa contatti"
            icon={<UploadIcon color={""} size={0} />}
          />
        )}
      </div>
      <div className="p-4">
        <span className="text-text-md font-bold text-neutral-800">
          Anteprima file CSV
        </span>
      </div>
      <div style={{ height: 'calc(100% - 64px)' }}>
        <div className="sticky top-0 z-10">
          <table className="w-full bg-white" align="left">
            <thead>
              <tr className="bg-neutral-100">
                {props.fields.map((field) => (
                  <th
                    key={field}
                    align="left"
                    className="first:pl-4 last:pr-4 h-10 text-label-sm font-semibold text-neutral-800"
                  >
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.rows.slice(0, 3).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {props.fields.map((field) => (
                    <td
                      key={field}
                      className="first:pl-4 last:pr-4 h-10 text-label-sm text-neutral-700"
                    >
                      {row[field] || "-"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-brandPrimary-100 rounded-b-xl p-4 gap-6 flex flex-col items-start overflow-auto">
          <span className="text-text-md font-bold text-neutral-800">
            Mappa campi CRM
          </span>
          <div className="flex w-full gap-4">
            <SelectCustom
              label="Nome"
              defaultValue={headers.name[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.name = [];
                } else if (typeof e === "string") {
                  newHeaders.name = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il nome"}
              options={getFilteredOptions("name")}
            />
            <SelectCustom
              label="Cognome"
              defaultValue={headers.surname[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.surname = [];
                } else if (typeof e === "string") {
                  newHeaders.surname = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il cognome"}
              options={getFilteredOptions("surname")}
            />
            <SelectCustom
              label="Ruolo"
              defaultValue={headers.role[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.role = [];
                } else if (typeof e === "string") {
                  newHeaders.role = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il ruolo"}
              options={getFilteredOptions("role")}
            />
          </div>
          <div className="flex w-full gap-4">
            <SelectCustom
              label="Settore"
              defaultValue={headers.department[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.department = [];
                } else if (typeof e === "string") {
                  newHeaders.department = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il settore"}
              options={getFilteredOptions("department")}
            />
            <SelectCustom
              label="Smartphone"
              defaultValue={headers.phone[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.phone = [];
                } else if (typeof e === "string") {
                  newHeaders.phone = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il cellulare"}
              options={getFilteredOptions("phone")}
            />
            <SelectCustom
              label="Email"
              defaultValue={headers.email[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.email = [];
                } else if (typeof e === "string") {
                  newHeaders.email = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per l'email"}
              options={getFilteredOptions("email")}
            />
          </div>
          <div className="flex w-full gap-4">
            <SelectCustom
              label="Profilo linkedin"
              defaultValue={headers.linkedinProfile[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.linkedinProfile = [];
                } else if (typeof e === "string") {
                  newHeaders.linkedinProfile = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il profilo linkedin"}
              options={getFilteredOptions("linkedinProfile")}
            />
            <SelectCustom
              label="Privacy"
              defaultValue={headers.privacy[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.privacy = [];
                } else if (typeof e === "string") {
                  newHeaders.privacy = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per la privacy"}
              options={getFilteredOptions("privacy")}
            />
          </div>
          <div className="flex w-full gap-4">
            <SelectCustom
              label="Nome azienda"
              defaultValue={headers.companyName[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.companyName = [];
                } else if (typeof e === "string") {
                  newHeaders.companyName = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il nome dell'azienda"}
              options={getFilteredOptions("companyName")}
            />
            <SelectCustom
              label="P.IVA azienda"
              defaultValue={headers.companyVat[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.companyVat = [];
                } else if (typeof e === "string") {
                  newHeaders.companyVat = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per la partita IVA"}
              options={getFilteredOptions("companyVat")}
            />
            <SelectCustom
              label="Telefono azienda"
              defaultValue={headers.companyPhone[0]}
              isClearable
              onChange={(e) => {
                const newHeaders = { ...headers };
                if (e === undefined) {
                  newHeaders.companyPhone = [];
                } else if (typeof e === "string") {
                  newHeaders.companyPhone = [e];
                }
                setHeaders(newHeaders);
              }}
              placeholder={"Seleziona campo per il telefono aziendale"}
              options={getFilteredOptions("companyPhone")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
