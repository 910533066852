import { CSSProperties, ReactElement } from "react";
import { colors } from "../../colors";

export interface ButtonProps {
    size: ButtonSize;
    label?: string;
    disabled?: boolean;
    icon?: ReactElement
    iconPosition: ButtonIconPosition
    variant: ButtonVariant
    color: ButtonColor
    onClick?: () => void
    fullWidth?: boolean
}

export type ButtonSize = 'sm' | 'md' | 'lg'
export type ButtonIconPosition = 'off' | 'only' | 'right' | 'left'
export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link'
export type ButtonColor = 'blue' | 'gray' | 'green' | 'red'

export const buttonColorMap = new Map<ButtonColor, { color: string, darker: string, lighter?: string }>([
    ['blue', { color: colors.brandPrimary[500], darker: colors.brandPrimary[600] }],
    ['gray', { color: colors.neutral[500], darker: colors.neutral[600], lighter: colors.neutral[300] }],
    ['green', { color: colors.green[500], darker: colors.green[600] }],
    ['red', { color: colors.red[500], darker: colors.red[600] }],
])

export const buttonSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', { height: '32px', minWidth: '32px' }],
    ['md', { height: '40px', minWidth: '40px' }],
    ['lg', { height: '48px', minWidth: '48px' }],
])

export const buttonLabelSizeMap = new Map<ButtonSize, CSSProperties>([
    ['sm', {
        fontSize: '14px',
        fontWeight: 600,
        height: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }],
    ['md', {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '14px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }],
    ['lg', {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }],
])

export const buttonIconPositionMap = new Map<ButtonIconPosition, CSSProperties>([
    ['off', { padding: '0px 16px 0px 16px', }],
    ['only', { padding: '0px', }],
    ['left', { padding: '0px 16px 0px 16px', flexDirection: 'row' }],
    ['right', { padding: '0px 16px 0px 16px', flexDirection: 'row-reverse' }],
])


export const buttonVariantKey = (variant: string, darker: boolean): string => {
    return `${variant}-${darker}`;
};

export const buttonIconSizeMap = new Map<ButtonSize, number>([
    ['sm', 16],
    ['md', 20],
    ['lg', 24]
])


export const colorKey = (color: ButtonColor, variantKey: string, border: boolean): { buttonIconVariant: string, buttonVariant: CSSProperties } => {
    const COLOR = (color === 'gray' && variantKey === buttonVariantKey('outline', false) && border ? buttonColorMap.get(color)?.lighter : buttonColorMap.get(color)?.color) || ''
    const DARKERCOLOR = (color === 'gray' && variantKey === buttonVariantKey('outline', true) && border ? buttonColorMap.get(color)?.lighter : buttonColorMap.get(color)?.darker) || ''

    const buttonIconVariantMap = new Map<string, string>([
        [buttonVariantKey('solid', true), 'white'],
        [buttonVariantKey('solid', false), 'white'],
        [buttonVariantKey('outline', true), DARKERCOLOR],
        [buttonVariantKey('outline', false), COLOR],
        [buttonVariantKey('ghost', true), DARKERCOLOR],
        [buttonVariantKey('ghost', false), COLOR],
        [buttonVariantKey('link', true), DARKERCOLOR],
        [buttonVariantKey('link', false), COLOR],
    ])

    const buttonVariantMap = new Map<string, CSSProperties>([
        [buttonVariantKey('solid', true), {
            backgroundColor: DARKERCOLOR,
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('solid', false), {
            backgroundColor: COLOR,
            borderColor: COLOR,
        }],
        [buttonVariantKey('outline', true), {
            backgroundColor: 'white',
            borderColor: DARKERCOLOR,
        }],
        [buttonVariantKey('outline', false), {
            backgroundColor: 'white',
            borderColor: COLOR,
        }],
        [buttonVariantKey('ghost', true), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('ghost', false), {
            backgroundColor: 'white',
            borderColor: 'transparent',
        }],
        [buttonVariantKey('link', true), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
        [buttonVariantKey('link', false), {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }],
    ])

    return { buttonIconVariant: buttonIconVariantMap.get(variantKey) || '', buttonVariant: buttonVariantMap.get(variantKey) || {} }
};